import React from 'react';
import { useServicesContext } from '../../../context';
import { Rate } from '../markets/rate/Rate';
import { RateUK } from '../markets/rate/RateUK';
import { RateES } from '../markets/rate/RateES';
export const RateTemplate = ({ vehicleBasic, trackLayerLinkClick, trackFinInfoLayerClose, configureFinanceComponents, }) => {
    var _a;
    const { getAdditionalService } = useServicesContext();
    const localeService = getAdditionalService('gfa:locale-service');
    const countryCode = (_a = localeService.countryCode) === null || _a === void 0 ? void 0 : _a.toLowerCase();
    switch (countryCode) {
        case 'gb':
            return (React.createElement(RateUK, { vehicleId: vehicleBasic.id, financing: vehicleBasic.financing, trackLayerLinkClick: trackLayerLinkClick, trackFinInfoLayerClose: trackFinInfoLayerClose, isUsedCar: vehicleBasic.type === 'U', configureFinanceComponents: configureFinanceComponents }));
        case 'es':
            return vehicleBasic.type === 'U' ? (React.createElement(RateES, { vehicleId: vehicleBasic.id, financing: vehicleBasic.financing, trackLayerLinkClick: trackLayerLinkClick, trackFinInfoLayerClose: trackFinInfoLayerClose, configureFinanceComponents: configureFinanceComponents })) : (React.createElement(Rate, { vehicleId: vehicleBasic.id, financing: vehicleBasic.financing, trackLayerLinkClick: trackLayerLinkClick, trackFinInfoLayerClose: trackFinInfoLayerClose, isUsedCar: vehicleBasic.type === 'U', configureFinanceComponents: configureFinanceComponents }));
        default:
            return (React.createElement(Rate, { vehicleId: vehicleBasic.id, financing: vehicleBasic.financing, trackLayerLinkClick: trackLayerLinkClick, trackFinInfoLayerClose: trackFinInfoLayerClose, isUsedCar: vehicleBasic.type === 'U', configureFinanceComponents: configureFinanceComponents }));
    }
};

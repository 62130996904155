import React from 'react';
import styled from 'styled-components';
import { Text, Button } from '@audi/audi-ui-react-v2';
import {
  TileElement,
  Campaign,
  useServicesContext,
  Icon,
  getRelatedProduct,
  VehicleBasic,
  iconSystemInfoSmall,
} from '@oneaudi/vtp-shared';
import { FocusLayerSizeV2, LayerManagerV27 } from '@volkswagen-onehub/layer-manager';
import { LayerContentHTML } from '@oneaudi/fa-one-layer';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { trackLayerClose, trackLayerLinkClick, trackLayerOpen } from '../../tracking';

const TileCampaignElement: React.FC<{ campaign: Campaign; vehicle: VehicleBasic }> = ({
  campaign,
  vehicle,
}) => {
  const layerManager = useServicesContext().getAdditionalService(
    'gfa:layer-manager'
  ) as LayerManagerV27;
  const trackingService = useServicesContext().getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;
  const relatedProduct = getRelatedProduct(vehicle);
  const openLayer = () => {
    layerManager?.openFocusLayer(
      () => <LayerContentHTML url={campaign.micrositeUrl.replace(/\.html$/, '.headless.html')} />,
      {},
      {
        size: FocusLayerSizeV2.A,
        userCloseable: true,
        onClose: () => {
          if (trackingService) {
            trackLayerClose(trackingService, 'vtp campaign layer');
          }
        },
      }
    );
    trackLayerLinkClick(trackingService, campaign.filterText, 'icon', relatedProduct);
    trackLayerOpen(
      trackingService,
      'vtp results - campaign layer',
      'vtp campaign layer',
      campaign.campaignId
    );
  };
  return (
    <TileElement data-testid="campaignItem">
      <ItemLabel>
        <Text
          variant="copy2"
          spaceInlineEnd="s"
          spaceStackEnd="xs"
          data-testid="TabCampaignElementText"
        >
          {campaign.filterText}
        </Text>
        {campaign.micrositeUrl && (
          <Button
            variant="text"
            data-testid="campaignInfoIcon"
            onClick={openLayer}
            icon={<Icon icon={iconSystemInfoSmall} />}
          />
        )}
      </ItemLabel>
    </TileElement>
  );
};

const ItemLabel = styled.div`
  display: inline-flex;
  margin-left: var(${(props): string => props.theme.responsive.spacing.s});
`;

export default TileCampaignElement;

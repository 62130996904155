import { useMemo } from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { messages } from '../../i18n/messages';
export const useFuelTypesLookup = () => {
    const BIOMETHAN = useI18n(messages.fuelType.biomethanLabel);
    const CNG = useI18n(messages.fuelType.cngLabel);
    const COMPRESSED_AIR = useI18n(messages.fuelType.compressedAirLabel);
    const DIESEL = useI18n(messages.fuelType.dieselLabel);
    const ELECTRICAL = useI18n(messages.fuelType.electricalLabel);
    const ETHANOL = useI18n(messages.fuelType.ethanolLabel);
    const H2NG = useI18n(messages.fuelType.h2ngLabel);
    const HE15 = useI18n(messages.fuelType.he15Label);
    const HYDROGEN = useI18n(messages.fuelType.hydrogenLabel);
    const LNG = useI18n(messages.fuelType.lngLabel);
    const LPG = useI18n(messages.fuelType.lpgLabel);
    const MIXTURE = useI18n(messages.fuelType.mixtureLabel);
    const OTHER = useI18n(messages.fuelType.otherLabel);
    const PETROL = useI18n(messages.fuelType.petrolLabel);
    return useMemo(() => ({
        BIOMETHAN,
        CNG,
        COMPRESSED_AIR,
        DIESEL,
        ELECTRICAL,
        ETHANOL,
        H2NG,
        HE15,
        HYDROGEN,
        LNG,
        LPG,
        MIXTURE,
        OTHER,
        PETROL,
    }), [
        BIOMETHAN,
        CNG,
        COMPRESSED_AIR,
        DIESEL,
        ELECTRICAL,
        ETHANOL,
        H2NG,
        HE15,
        HYDROGEN,
        LNG,
        LPG,
        MIXTURE,
        OTHER,
        PETROL,
    ]);
};

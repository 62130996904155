import { useState, useEffect } from 'react';
export const useDesktopOrMobileView = (screenBreakpoint) => {
    const [isDesktop, setIsDesktop] = useState(false);
    const setDeviceType = () => {
        setIsDesktop(window.matchMedia(`(min-width: ${screenBreakpoint})`).matches);
    };
    useEffect(() => {
        setDeviceType();
        window.addEventListener('resize', setDeviceType);
        return () => {
            window.removeEventListener('resize', setDeviceType);
        };
    }, []);
    return isDesktop ? 'desktop' : 'mobile';
};

import * as React from 'react';
import styled from 'styled-components';
import { PriceInformation } from './prices/PriceInformation';
import { LeasingInformation } from './leasing/LeasingInformation';
import { TaxationInformation } from './TaxationInformation';
import { PriceInformationJapan } from './markets/PriceInformationJapan';
import { PriceInformationSpain } from './markets/PriceInformationSpain';
import { useForceUpdate } from '../../hooks/ssr';
import { useServicesContext } from '../../context';
const PriceSectionWrap = styled.div `
  margin-bottom: var(
    ${(props) => props.compact ? props.theme.responsive.spacing.m : props.theme.responsive.spacing.xxl}
  );
`;
export const Finance = ({ vehicle, fullVehicle, loanTypeLink, trackLayerLinkClick, trackFinInfoLayerClose, configureFinanceComponents, }) => {
    const forceUpdate = useForceUpdate();
    const localeService = useServicesContext().getAdditionalService('gfa:locale-service');
    const country = localeService.countryCode;
    const useSpainTemplate = (country === null || country === void 0 ? void 0 : country.toLowerCase()) === 'es' && vehicle.type === 'U';
    const useJapanTemplate = (country === null || country === void 0 ? void 0 : country.toLowerCase()) === 'jp' && vehicle.type === 'U';
    React.useEffect(() => {
        if (forceUpdate.value < 1) {
            forceUpdate.setValue();
        }
    }, [forceUpdate.value]);
    const priceTemplate = () => {
        if (useJapanTemplate) {
            return (React.createElement(PriceInformationJapan, { vehicle: vehicle, fullVehicle: fullVehicle, loanTypeLink: loanTypeLink, trackLayerLinkClick: trackLayerLinkClick, trackFinInfoLayerClose: trackFinInfoLayerClose, configureFinanceComponents: configureFinanceComponents }));
        }
        if (useSpainTemplate) {
            return (React.createElement(PriceInformationSpain, { vehicle: vehicle, fullVehicle: fullVehicle, trackLayerLinkClick: trackLayerLinkClick, trackFinInfoLayerClose: trackFinInfoLayerClose, configureFinanceComponents: configureFinanceComponents }));
        }
        return (React.createElement(PriceInformation, { vehicle: vehicle, fullVehicle: fullVehicle, loanTypeLink: loanTypeLink, trackLayerLinkClick: trackLayerLinkClick, trackFinInfoLayerClose: trackFinInfoLayerClose, configureFinanceComponents: configureFinanceComponents }));
    };
    return (React.createElement(PriceSectionWrap, { compact: configureFinanceComponents.compact || false, "data-testid": "financing" },
        vehicle.leasingCar ? (React.createElement(LeasingInformation, { vehicle: vehicle, fullVehicle: fullVehicle, configureFinanceComponents: configureFinanceComponents })) : (priceTemplate()),
        React.createElement(TaxationInformation, { vehicle: vehicle })));
};

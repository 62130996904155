import React, { createContext, useEffect, useMemo, useState } from 'react';
const contextSymbol = typeof Symbol === 'function' && Symbol.for
    ? Symbol.for('__VTP_SERVICES_CONTEXT__')
    : '__VTP_SERVICES_CONTEXT__';
const resetContext = () => {
    Object.defineProperty(React, contextSymbol, {
        value: createContext(undefined),
        enumerable: false,
        configurable: true,
        writable: false,
    });
};
const getServicesContext = () => {
    if (!React[contextSymbol]) {
        resetContext();
    }
    return React[contextSymbol];
};
const ServicesContextProvider = ({ audiMarketContextService, audiStockcarsStoreService, audiFetchConfigService, contextId, additionalServices, featureAppConfig, envConfig: envConfigParam, children, }) => {
    const [appContextId, setAppContextId] = useState(contextId);
    const [envConfig, setEnvConfig] = useState(envConfigParam);
    useEffect(() => {
        if (additionalServices && 'audi:envConfigService' in additionalServices && !envConfig) {
            const envConfigService = additionalServices['audi:envConfigService'];
            envConfigService.getConfiguration('vtp').then((conf) => {
                setEnvConfig(conf);
            });
        }
    }, [envConfig]);
    const contextValue = useMemo(() => {
        const getConfigurationEntry = (key) => {
            return audiFetchConfigService === null || audiFetchConfigService === void 0 ? void 0 : audiFetchConfigService.getConfigurationEntryById(key, appContextId);
        };
        const getAdditionalService = (key) => {
            if (!additionalServices || !(key in additionalServices)) {
                return null;
            }
            return additionalServices[key];
        };
        const getConfiguration = () => {
            if (!additionalServices || !('vtp-configuration-service' in additionalServices)) {
                return null;
            }
            const configurationService = additionalServices['vtp-configuration-service'];
            return configurationService === null || configurationService === void 0 ? void 0 : configurationService.getConfiguration();
        };
        const getEnvironmentConfig = () => {
            return envConfig;
        };
        return {
            audiMarketContextService,
            audiStockcarsStoreService,
            audiFetchConfigService,
            getConfiguration,
            getEnvironmentConfig,
            getConfigurationEntry,
            setAppContextId,
            appContextId,
            getAdditionalService,
            featureAppConfig,
        };
    }, [
        audiMarketContextService,
        audiStockcarsStoreService,
        audiFetchConfigService,
        appContextId,
        additionalServices,
        featureAppConfig,
        envConfig,
    ]);
    let renderChildren = true;
    if (additionalServices && 'audi:envConfigService' in additionalServices) {
        renderChildren = Boolean(envConfig);
    }
    return (React.createElement(ServicesContext.Provider, { value: contextValue }, renderChildren && children));
};
const ServicesContext = getServicesContext();
export { ServicesContext, ServicesContextProvider };

import { useI18n } from '@oneaudi/i18n-context';
import { isNationWideSellingVehicle } from '../../utils';
export const useNationWideSellingLabels = (vehicle) => {
    var _a;
    const businessModelCode = ((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.businessModel) === null || _a === void 0 ? void 0 : _a.code) || '';
    const isNws = isNationWideSellingVehicle(vehicle);
    if (!businessModelCode && isNws)
        return {
            nwsLabel: useI18n({ id: 'nemo.ui.sc.nationwideselling.label', defaultMessage: '' }),
            nwsHeadline: useI18n({ id: 'nemo.ui.sc.nationwideselling.headline', defaultMessage: '' }),
            nwsSubLabel: useI18n({ id: 'nemo.ui.sc.nationwideselling.sublabel', defaultMessage: '' }),
        };
    const nwsLabel = isNws
        ? useI18n({
            id: `nemo.ui.sc.${businessModelCode}.label`,
            defaultMessage: '',
        })
        : '';
    const nwsSubLabel = isNws
        ? useI18n({
            id: `nemo.ui.sc.${businessModelCode}.sublabel`,
            defaultMessage: '',
        })
        : '';
    const nwsHeadline = isNws
        ? useI18n({
            id: `nemo.ui.sc.${businessModelCode}.headline`,
            defaultMessage: '',
        })
        : '';
    return {
        nwsLabel,
        nwsSubLabel,
        nwsHeadline,
    };
};

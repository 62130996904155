import { deepCopy } from '../../../../utils/object';
import { getPriceFormatted } from '../../services/formatServices';
export const prepareDefaultDataForProduct = (rawResponse_ = {}) => {
    const response = prepareProductDataResponse(rawResponse_);
    return prepareDataForProductResponse(response);
};
const prepareProductDataResponse = (responseData) => {
    const returnValue = {};
    if (responseData === null || responseData === void 0 ? void 0 : responseData.Parameters) {
        returnValue.Parameters = responseData.Parameters;
        if (responseData.Errors) {
            returnValue.Error = responseData.Errors.Error;
        }
    }
    return returnValue;
};
const prepareDataForProductResponse = (data) => {
    var _a;
    let productResponseData;
    if (!!data && !!data.Parameters) {
        if (!!data.Parameters.Description && !!data.Parameters.Description['#text']) {
            productResponseData = Object.assign(Object.assign({}, data), { Parameters: Object.assign(Object.assign({}, data.Parameters), { Description: data.Parameters.Description }) });
        }
        if (!!((_a = data === null || data === void 0 ? void 0 : data.Parameters) === null || _a === void 0 ? void 0 : _a.Group) && !(data.Parameters.Group instanceof Array)) {
            if (!productResponseData) {
                productResponseData = deepCopy(data);
            }
            productResponseData.Parameters.Group = [data.Parameters.Group];
        }
    }
    else {
        throw data.Error ? data.Error : {};
    }
    return data;
};
export const generateFormData = (formData_, currency, currencyPattern) => {
    const formData = {};
    if (formData_) {
        formData.description =
            (formData_.Description ? getValueString(formData_.Description) : '') ||
                (formData_.Group && formData_.Group.length ? getValueString(formData_.Group[0].Label) : '');
        formData.disclaimer = {
            globalDisclaimer: getDisclaimerFromFormData('GlobalDisclaimer', formData_),
            groupDisclaimer: formData_.Group && formData_.Group.Disclaimer
                ? getValueString(formData_.Group.Disclaimer['#cdata-section'])
                : '',
            productDisclaimer: getDisclaimerFromFormData('ProductDisclaimer', formData_),
            calculationDisclaimer: getDisclaimerFromFormData('CalculationDisclaimer', formData_),
            rateDetails: formData_.RateDetails
                ? getValueString(formData_.RateDetails['#cdata-section'])
                : '',
        };
        if (formData_.Group) {
            formData.data = [];
            const groups = formData_.Group instanceof Array ? formData_.Group : [formData_.Group];
            groups.forEach((group) => {
                const groupData = {
                    name: group['@Name'] ? group['@Name'] : '',
                    label: group.Label ? getValueString(group.Label) : '',
                    parameter: group.Parameter
                        ? getFormParameter(group.Parameter instanceof Array ? group.Parameter : [group.Parameter], currency, currencyPattern)
                        : [],
                };
                formData.data.push(groupData);
            });
        }
    }
    return formData;
};
function getDisclaimerFromFormData(disclaimerName, formData) {
    var _a, _b, _c;
    if (((_a = formData.Disclaimers) === null || _a === void 0 ? void 0 : _a[disclaimerName]) &&
        typeof ((_b = formData.Disclaimers) === null || _b === void 0 ? void 0 : _b[disclaimerName]) === 'string') {
        return (_c = formData.Disclaimers) === null || _c === void 0 ? void 0 : _c[disclaimerName];
    }
    if (formData[disclaimerName] &&
        formData[disclaimerName]['#cdata-section'] &&
        typeof formData[disclaimerName]['#cdata-section'] === 'string') {
        return formData[disclaimerName]['#cdata-section'];
    }
    if (formData[disclaimerName] && typeof formData[disclaimerName] === 'string') {
        return formData[disclaimerName];
    }
    return '';
}
const getFormParameter = (parameter_ = [], currency, currencyPattern) => {
    const parameter = [];
    parameter_.forEach((param_) => {
        const param = {
            id: param_['@ID'] ? param_['@ID'] : '',
            relevant: param_['@Relevant'] ? !!param_['@Relevant'] : false,
            label: getDisplayLabel(param_.Label, param_.Note),
            help: param_.Help ? getValueString(param_.Help) : '',
            hasHelp: !!param_.Help,
            default: param_.Default ? param_.Default : '',
            unit: {
                symbol: param_.Units && param_.Units['@Symbol'] ? param_.Units && param_.Units['@Symbol'] : '',
                text: param_.Units && param_.Units['#text'] ? param_.Units && param_.Units['#text'] : '',
            },
            displayUnit: getDisplayUnit(param_.Units),
            data: param_.Data
                ? getParamData(param_.Data && Array.isArray(param_.Data) ? param_.Data : [param_.Data], param_.Units, currency, currencyPattern)
                : [],
            hasData: !!param_.Data,
            control: {
                type: param_.Control && param_.Control['@Type'] ? param_.Control['@Type'] : '',
                style: param_.Control && param_.Control['@Style'] ? param_.Control['@Style'] : '',
                dataType: param_.Control && param_.Control['@DataType'] ? param_.Control['@DataType'] : '',
                maxInputLength: param_.Control && param_.Control['@Length'] ? param_.Control['@Length'] : '',
                autoPostBack: param_.Control && param_.Control['@AutoPostBack']
                    ? !!param_.Control['@AutoPostBack']
                    : '',
            },
        };
        parameter.push(param);
    });
    return parameter;
};
const getDisplayLabel = (label_ = '', note_ = '') => {
    let label = '';
    if (label_) {
        label = note_ ? `${label_} ${note_}` : label_;
    }
    return label;
};
const getParamData = (paramData_ = [], units_ = '', currency, currencyPattern) => {
    const paramData = [];
    paramData_.forEach((param) => {
        const paramValue = param['@Value'] ? param['@Value'] : '';
        const paramText = param['#text'] ? param['#text'] : '';
        const unitSymbol = units_['@Symbol'] ? units_['@Symbol'] : '';
        const unitText = units_['#text'] ? units_['#text'] : '';
        const displayUnit = unitSymbol !== '' ? unitSymbol : unitText !== '' ? unitText : '';
        const isCurrency = unitSymbol === currency || unitText === currency;
        const formattedValue = isCurrency
            ? getPriceFormatted(paramText, unitSymbol, unitText, currencyPattern)
            : `${paramText || ''} ${displayUnit}`;
        const data = {
            type: (unitSymbol || unitText) === '%' ? 'percentage' : 'price',
            value: paramValue,
            text: paramText,
            formattedValue,
        };
        paramData.push(data);
    });
    return paramData;
};
const getDisplayUnit = (units_) => {
    let unit = '';
    if (!units_) {
        return unit;
    }
    if (typeof units_ === 'string') {
        unit = units_;
    }
    else if (units_['@Symbol']) {
        unit = units_['@Symbol'];
    }
    else if (units_['#text']) {
        unit = units_['#text'];
    }
    else {
        unit = '';
    }
    return unit;
};
const getValueString = (value_ = '') => {
    return typeof value_ === 'string' ? value_ : value_[Object.keys(value_)[0]];
};
export const escapeRegExp = (string) => {
    if (!string) {
        return '';
    }
    if (/^[a-zA-Z_-]+$/.test(string)) {
        return string;
    }
    if (string.at(-3) === ',' || string.at(-3) === '.') {
        return Number(string.replace(/[^0-9-]+/g, '')) / 100;
    }
    if (/^[a-zA-Z0-9_ -]+$/.test(string)) {
        return string;
    }
    return string.replace(/[^0-9-]+/g, '');
};

import React from 'react';
import { LayerContentHTML } from '@oneaudi/fa-one-layer';
import styled from 'styled-components';
import { Iframe } from './Iframe';
import { PostFormV2 } from '../CTA';
export const FocusLayerV2 = ({ link, postData, iframeForms }) => {
    return (React.createElement(LayerContainer, null,
        link.method === 'GET' && !iframeForms && (React.createElement(LayerContentHTML, { "data-testid": "layerContent", url: link.url })),
        link.method === 'GET' && iframeForms && React.createElement(Iframe, { src: link.url }),
        link.method === 'POST' && (React.createElement(React.Fragment, null,
            React.createElement(PostFormV2, { link: link, postData: postData, "data-testid": "postForm" })))));
};
export const openFocusLayerV2 = (link, postData, layerManager, iframeForms) => {
    layerManager === null || layerManager === void 0 ? void 0 : layerManager.openFocusLayer(() => React.createElement(FocusLayerV2, { link: link, postData: postData, iframeForms: iframeForms || false }), {}, {
        size: "A",
        userCloseable: true,
    });
};
const LayerContainer = styled.div `
  .nm-layer-title {
    display: none;
  }
`;

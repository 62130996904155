import * as React from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { Text } from '@audi/audi-ui-react-v2';
import { messages } from '../../i18n/messages';
import { FootnoteReference } from '../footnotes/components/FootnoteReference';
export const TaxationInformation = ({ vehicle }) => {
    const taxationLabel = useI18n(messages.prices.taxationInformation);
    if (!vehicle.taxation && vehicle.langCode.toLowerCase() !== 'nl') {
        return null;
    }
    return (React.createElement(Text, { variant: "copy2", weight: "bold", spaceStackEnd: "xs" },
        `${taxationLabel}: ${vehicle.taxation}`,
        React.createElement(FootnoteReference, { refId: "#fn_tax" })));
};

import { useMemo } from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { useServicesContext } from '../../context';
export const usePriceLabel = (price, hideActionPriceLabel = false, resolveBevLabel = false, stockType, countryCode) => {
    var _a;
    const { getConfiguration } = useServicesContext();
    const scopesFromConfig = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes;
    const isNewcar = (stockType === null || stockType === void 0 ? void 0 : stockType.toUpperCase()) === 'N';
    const country = countryCode === null || countryCode === void 0 ? void 0 : countryCode.toLocaleLowerCase();
    const fallBackLabel = useMemo(() => {
        let fallBackKey;
        const priceType = price === null || price === void 0 ? void 0 : price.type;
        if (priceType === 'regular') {
            fallBackKey = 'nemo.ui.sc.result.regularprice';
        }
        else if (priceType === 'retail' && !hideActionPriceLabel) {
            if (country === 'gb') {
                fallBackKey = isNewcar
                    ? 'nemo.ui.sc.result.actionprice.newcar'
                    : 'nemo.ui.sc.result.actionprice.usedcar';
            }
            else {
                fallBackKey = 'nemo.ui.sc.result.actionprice';
            }
        }
        return fallBackKey;
    }, [price, hideActionPriceLabel]);
    const label = useMemo(() => {
        var _a;
        let labelKey;
        const nameText = (_a = price === null || price === void 0 ? void 0 : price.nameText) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        const useDynamicLabel = scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.useDynamicPriceLabels;
        if (nameText && useDynamicLabel) {
            labelKey = `nemo.ui.sc.result.price--${nameText}${resolveBevLabel ? '--bev' : ''}`;
        }
        return labelKey;
    }, [fallBackLabel, price, scopesFromConfig, useI18n]);
    return useI18n({ id: label !== null && label !== void 0 ? label : fallBackLabel, defaultMessage: '' });
};

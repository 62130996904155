const resolvResult = (response) => {
    if (response === null || response === void 0 ? void 0 : response.Result) {
        return Array.isArray(response.Result) ? response.Result[0] : response.Result;
    }
    return undefined;
};
const resolveDetailGroup = (summary) => {
    const detailGroup = summary === null || summary === void 0 ? void 0 : summary.DetailGroups.DetailGroup;
    if (detailGroup) {
        return Array.isArray(detailGroup) ? detailGroup[0] : detailGroup;
    }
    return undefined;
};
const resolveParameter = (detailItem) => {
    let unit;
    let unitSymbol;
    if (detailItem.Units) {
        if (typeof detailItem.Units === 'string') {
            unit = detailItem.Units;
        }
        else {
            unit = detailItem.Units['#text'];
            unitSymbol = detailItem.Units['@Symbol'];
        }
    }
    return {
        formattedValue: detailItem.Value,
        id: detailItem['@ID'],
        label: detailItem.Label,
        unit,
        unitSymbol,
        value: detailItem.Value,
    };
};
export const resolveParameters = (response) => {
    var _a, _b, _c, _d, _e;
    const result = resolvResult(response);
    if ((_a = result === null || result === void 0 ? void 0 : result.Summaries) === null || _a === void 0 ? void 0 : _a.Summary) {
        const fullSummary = (_c = (_b = result === null || result === void 0 ? void 0 : result.Summaries) === null || _b === void 0 ? void 0 : _b.Summary) === null || _c === void 0 ? void 0 : _c.find((summary) => summary['@Type'] === 'Full');
        const detailGroup = resolveDetailGroup(fullSummary);
        return (_e = (_d = detailGroup === null || detailGroup === void 0 ? void 0 : detailGroup.Details) === null || _d === void 0 ? void 0 : _d.Detail) === null || _e === void 0 ? void 0 : _e.map(resolveParameter);
    }
    return undefined;
};
export const resolveDisclaimer = (response, disclaimerName, financingDisclaimersTextStyle) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    let disclaimer = '';
    const result = resolvResult(response);
    if (((_b = (_a = response === null || response === void 0 ? void 0 : response.Parameters) === null || _a === void 0 ? void 0 : _a[disclaimerName]) === null || _b === void 0 ? void 0 : _b['#cdata-section']) &&
        typeof ((_d = (_c = response === null || response === void 0 ? void 0 : response.Parameters) === null || _c === void 0 ? void 0 : _c[disclaimerName]) === null || _d === void 0 ? void 0 : _d['#cdata-section']) === 'string') {
        disclaimer = (_f = (_e = response === null || response === void 0 ? void 0 : response.Parameters) === null || _e === void 0 ? void 0 : _e[disclaimerName]) === null || _f === void 0 ? void 0 : _f['#cdata-section'];
    }
    else if (((_g = result === null || result === void 0 ? void 0 : result[disclaimerName]) === null || _g === void 0 ? void 0 : _g['#cdata-section']) &&
        typeof ((_h = result === null || result === void 0 ? void 0 : result[disclaimerName]) === null || _h === void 0 ? void 0 : _h['#cdata-section']) === 'string') {
        disclaimer = (_j = result === null || result === void 0 ? void 0 : result[disclaimerName]) === null || _j === void 0 ? void 0 : _j['#cdata-section'];
    }
    else if (((_l = (_k = response === null || response === void 0 ? void 0 : response.Parameters) === null || _k === void 0 ? void 0 : _k.Disclaimers) === null || _l === void 0 ? void 0 : _l[disclaimerName]) &&
        typeof ((_o = (_m = response === null || response === void 0 ? void 0 : response.Parameters) === null || _m === void 0 ? void 0 : _m.Disclaimers) === null || _o === void 0 ? void 0 : _o[disclaimerName]) === 'string') {
        disclaimer = (_q = (_p = response === null || response === void 0 ? void 0 : response.Parameters) === null || _p === void 0 ? void 0 : _p.Disclaimers) === null || _q === void 0 ? void 0 : _q[disclaimerName];
    }
    else if (((_r = result === null || result === void 0 ? void 0 : result.Disclaimers) === null || _r === void 0 ? void 0 : _r[disclaimerName]) &&
        typeof ((_s = result === null || result === void 0 ? void 0 : result.Disclaimers) === null || _s === void 0 ? void 0 : _s[disclaimerName]) === 'string') {
        disclaimer = (_t = result === null || result === void 0 ? void 0 : result.Disclaimers) === null || _t === void 0 ? void 0 : _t[disclaimerName];
    }
    else if (((_u = response === null || response === void 0 ? void 0 : response.Parameters) === null || _u === void 0 ? void 0 : _u[disclaimerName]) &&
        typeof ((_v = response === null || response === void 0 ? void 0 : response.Parameters) === null || _v === void 0 ? void 0 : _v[disclaimerName]) === 'string') {
        disclaimer = (_w = response === null || response === void 0 ? void 0 : response.Parameters) === null || _w === void 0 ? void 0 : _w[disclaimerName];
    }
    else if ((result === null || result === void 0 ? void 0 : result[disclaimerName]) && typeof (result === null || result === void 0 ? void 0 : result[disclaimerName]) === 'string') {
        disclaimer = result === null || result === void 0 ? void 0 : result[disclaimerName];
    }
    if (!financingDisclaimersTextStyle) {
        disclaimer = disclaimer.replace(/<\/?[^>]+(>|$)/g, '');
    }
    return disclaimer;
};
const resolveProductLabel = (response) => {
    var _a;
    const descriptionFromParameters = (_a = response.Parameters) === null || _a === void 0 ? void 0 : _a.Description;
    if (typeof descriptionFromParameters === 'string') {
        return descriptionFromParameters;
    }
    if (descriptionFromParameters === null || descriptionFromParameters === void 0 ? void 0 : descriptionFromParameters['#text']) {
        return descriptionFromParameters === null || descriptionFromParameters === void 0 ? void 0 : descriptionFromParameters['#text'];
    }
    return undefined;
};
export const mergeFSAGProduct = (financing, product, disclaimersTextStyle) => {
    const result = Object.assign({}, financing);
    if (product) {
        const resolvedProductLabel = resolveProductLabel(product);
        const resolvedGlobalDisclaimer = resolveDisclaimer(product, 'GlobalDisclaimer', disclaimersTextStyle);
        const resolvedCalculationDisclaimer = resolveDisclaimer(product, 'CalculationDisclaimer', disclaimersTextStyle);
        const resolvedProductDisclaimer = resolveDisclaimer(product, 'ProductDisclaimer', disclaimersTextStyle);
        const resolvedParameters = resolveParameters(product);
        if (resolvedProductLabel) {
            result.product = {
                label: resolvedProductLabel,
            };
        }
        if (resolvedGlobalDisclaimer) {
            result.globalDisclaimer = resolvedGlobalDisclaimer;
        }
        if (resolvedCalculationDisclaimer) {
            result.calculationDisclaimer = resolvedCalculationDisclaimer;
        }
        if (resolvedProductDisclaimer) {
            result.productDisclaimer = resolvedProductDisclaimer;
        }
        if (resolvedParameters) {
            const newParameters = financing
                ? financing.parameters.filter((parameter) => resolvedParameters.findIndex((newParameter) => newParameter.id === parameter.id) !==
                    -1)
                : [];
            resolvedParameters.forEach((resolvedParameter) => {
                const index = newParameters.findIndex((newParameter) => newParameter.id === resolvedParameter.id);
                if (index < 0) {
                    newParameters.push(resolvedParameter);
                }
                else {
                    newParameters[index] = resolvedParameter;
                }
            });
            result.parameters = newParameters;
        }
    }
    return result;
};
export const createFSAGRequest = (name, domain, product, vehicle, dealer) => {
    const request = {};
    if (name && domain && vehicle) {
        request['@Name'] = name;
        request['@Domain'] = domain;
        request.Vehicle = vehicle;
        if (dealer && typeof dealer === 'object' && Object.keys(dealer).length > 0) {
            request.Dealer = dealer;
        }
        if (product && typeof product === 'object' && Object.keys(product).length > 0) {
            request.Product = product;
        }
    }
    return request;
};
export const mergeDeep = (target, ...sources) => {
    if (!sources.length) {
        return target;
    }
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, {
                        [key]: {},
                    });
                }
                mergeDeep(target[key], source[key]);
            }
            else {
                Object.assign(target, {
                    [key]: source[key],
                });
            }
        }
    }
    return mergeDeep(target, ...sources);
};
const isObject = (item) => {
    return item && typeof item === 'object' && !Array.isArray(item);
};
export const createCrsLogger = (vehicleId, requestedData) => {
    const logPrefix = `crs-api | ${vehicleId || 'no-vehicle-id'} | ${requestedData} : `;
    return {
        debug: (...optionalParams) => console.debug(logPrefix, ...optionalParams),
        info: (...optionalParams) => console.info(logPrefix, ...optionalParams),
        error: (...optionalParams) => console.error(logPrefix, ...optionalParams),
        trace: (...optionalParams) => console.trace(logPrefix, ...optionalParams),
        warn: (...optionalParams) => console.warn(logPrefix, ...optionalParams),
    };
};

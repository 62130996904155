var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { debounce } from './debounce';
import styled from 'styled-components';
export default class AbstractSlider extends React.Component {
    constructor(props) {
        super(props);
        this.touchStartX = null;
        this.touchStartY = null;
        this.touchMoveX = null;
        this.touchMoveY = null;
        this.touchEndX = null;
        this.moveX = 0;
        this.scrollDirection = null;
        this.isLongTouch = false;
        this.swipeDone = true;
        this.hasSlided = false;
        this.debounceScrollEndHandler = debounce(this.scrollEndHandler.bind(this), 100);
        this.debounceResizeHandler = debounce(this.resizeHandler.bind(this), 100);
        this.slides = [];
        this.transformBouncePercentage = 0.15;
        this.classes = {
            classSliderAnimate: 'sc-j-swipe-gallery-animate',
            classHideCursorMove: 'sc-j-swipe-gallery-hide-cursor-move',
        };
        this.swipeEvents = {
            DOWN: ['mousedown', 'touchstart'],
            MOVE: ['mousemove', 'touchmove'],
            UP: ['mouseup', 'touchend'],
            OUT: ['mouseleave', 'touchleave'],
        };
        this.mouseWheelEvents = {
            MOVE: ['wheel', 'mousewheel'],
        };
        this.state = { slideIndex: 0, slidesCount: 0, actionType: '' };
        this.slider = React.createRef();
        this.clickCaptureHandler = this.clickCaptureHandler.bind(this);
        this.mouseDownHandler = this.mouseDownHandler.bind(this);
        this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
        this.mouseUpHandler = this.mouseUpHandler.bind(this);
        this.prevSlide = this.prevSlide.bind(this);
        this.nextSlide = this.nextSlide.bind(this);
    }
    componentDidMount() {
        this.focusSlide(0);
        this.slides = [...this.slider.current.querySelectorAll('.item')];
        this.slidesCount = this.slides.length;
        if (this.slides.length <= 1) {
            this.slider.current.classList.add(this.classes.classHideCursorMove);
            return;
        }
        this.addEvents();
    }
    componentWillUnmount() {
        this.removeEvents();
    }
    componentDidUpdate() {
        if (Number.isInteger(this.props.slide) && this.slideIndex !== this.props.slide) {
            this.focusSlide(this.props.slide);
        }
    }
    get slideIndex() {
        const { slideIndex } = this.state;
        return slideIndex;
    }
    set slideIndex(slideIndex) {
        if (this.state.slideIndex !== slideIndex) {
            this.setState({ slideIndex });
            this.transformSlider(slideIndex * this.slideWidth);
        }
    }
    get slidesCount() {
        const { slidesCount } = this.state;
        return slidesCount;
    }
    set slidesCount(slidesCount) {
        this.setState({ slidesCount });
    }
    get actionType() {
        const { actionType } = this.state;
        return actionType;
    }
    set actionType(actionType) {
        if (this.state.actionType !== actionType) {
            this.setState({ actionType });
        }
    }
    get sliderTranslate3d() {
        return Math.abs(parseFloat(AbstractSlider.getTranslate3d(this.slider.current)[0].replace('px', '')));
    }
    get hasTouch() {
        return 'ontouchstart' in document.documentElement;
    }
    addEvents() {
        this.slider.current.classList.remove(this.classes.classHideCursorMove);
        this.slider.current.addEventListener('click', this.clickCaptureHandler, true);
        this.addMultipleEvent(this.slider.current, this.mouseDownHandler, this.swipeEvents.DOWN);
        this.addMultipleEvent(this.slider.current, this.scrollHandler, this.mouseWheelEvents.MOVE);
        this.addMultipleEvent(this.slider.current, this.debounceScrollEndHandler, this.mouseWheelEvents.MOVE);
        window.addEventListener('resize', this.debounceResizeHandler);
    }
    removeEvents() {
        this.slider.current.removeEventListener('click', this.clickCaptureHandler, true);
        this.removeMultipleEvent(this.slider.current, this.mouseDownHandler, this.swipeEvents.DOWN);
        this.removeMultipleEvent(this.slider.current, this.scrollHandler, this.mouseWheelEvents.MOVE);
        this.removeMultipleEvent(this.slider.current, this.debounceScrollEndHandler, this.mouseWheelEvents.MOVE);
        window.removeEventListener('resize', this.debounceResizeHandler);
    }
    removeMultipleEvent(element, callback, events) {
        events.forEach((event) => {
            element.removeEventListener(event, callback);
        });
    }
    addMultipleEvent(element, callback, events) {
        events.forEach((event) => {
            element.addEventListener(event, callback);
        });
    }
    clickCaptureHandler(event) {
        if (this.hasSlided) {
            event.preventDefault();
            event.stopPropagation();
            this.hasSlided = false;
        }
    }
    static getEventXPosition(event) {
        if ('pageX' in event && Number.isInteger(event.pageX)) {
            return event.pageX;
        }
        if ('changedTouches' in event && event.changedTouches[0]) {
            return event.changedTouches[0].clientX || event.changedTouches[0].pageX;
        }
        return 0;
    }
    static getEventYPosition(event) {
        if ('pageY' in event && Number.isInteger(event.pageY)) {
            return event.pageY;
        }
        if ('changedTouches' in event && event.changedTouches[0]) {
            return event.changedTouches[0].clientY || event.changedTouches[0].pageY;
        }
        return 0;
    }
    get slideWidth() {
        if (!this.slides[0]) {
            return 0;
        }
        return (AbstractSlider.getComputedPropertyValue(this.slides[0], 'width') +
            AbstractSlider.getComputedPropertyValue(this.slides[0], 'margin-left') +
            AbstractSlider.getComputedPropertyValue(this.slides[0], 'margin-right'));
    }
    get sliderWidth() {
        return this.slider.current
            ? AbstractSlider.getComputedPropertyValue(this.slider.current, 'width')
            : 0;
    }
    static getComputedPropertyValue(element, property) {
        const computedCSSValueString = getComputedStyle(element, null).getPropertyValue(property) || '';
        return parseFloat(computedCSSValueString.replace(/px/, ''));
    }
    getScrollDirection() {
        const verticalScroll = Math.abs(this.touchMoveY - this.touchStartY);
        const horizontalScroll = Math.abs(this.touchMoveX - this.touchStartX);
        return horizontalScroll >= verticalScroll ? 'HORIZONTAL' : 'VERTICAL';
    }
    static getTranslate3d(element) {
        const values = element.style.transform.split(/\w+\(|\);?/);
        if (!values[1] || !values[1].length) {
            return ['0px'];
        }
        return values[1].split(/,\s?/g);
    }
    isCorrectSwipeDistance(absoluteSwipeDistance) {
        return absoluteSwipeDistance > 2;
    }
    hasSwipePercentage(absoluteSwipeDistance) {
        const snapPercentage = 15;
        return absoluteSwipeDistance > (this.slideWidth / 100) * snapPercentage;
    }
    static isHidden(el) {
        return el.offsetParent === null;
    }
    getScrollIndex() {
        const calculatedIndex = this.moveX / this.slideWidth;
        const fixedIndex = parseFloat(calculatedIndex.toFixed(2));
        const naturalNumber = Number(fixedIndex.toString().split('.')[0]);
        const decimalNumber = Number(fixedIndex.toString().split('.')[1]);
        let nextIndex = this.slideIndex;
        if (naturalNumber >= this.slideIndex) {
            nextIndex =
                decimalNumber > 15 && naturalNumber < this.slides.length - 1
                    ? naturalNumber + 1
                    : naturalNumber;
        }
        else {
            nextIndex = decimalNumber > 85 && naturalNumber > 0 ? naturalNumber - 1 : naturalNumber;
        }
        return nextIndex;
    }
    mouseDownHandler(event) {
        AbstractSlider.preventDefaultOnNonTouchDevices(event);
        this.swipeDone = true;
        this.detectLongTouch();
        this.touchStartX = AbstractSlider.getEventXPosition(event);
        this.touchStartY = AbstractSlider.getEventYPosition(event);
        this.slider.current.classList.remove(this.classes.classSliderAnimate);
        this.addMultipleEvent(this.slider.current, this.mouseMoveHandler, this.swipeEvents.MOVE);
        this.addMultipleEvent(this.slider.current, this.mouseUpHandler, this.swipeEvents.UP);
        this.addMultipleEvent(this.slider.current, this.mouseUpHandler, this.swipeEvents.OUT);
    }
    mouseMoveHandler(event) {
        AbstractSlider.preventDefaultOnNonTouchDevices(event);
        this.touchMoveY = AbstractSlider.getEventYPosition(event);
        this.touchMoveX = AbstractSlider.getEventXPosition(event);
        if (!this.scrollDirection && this.swipeDone) {
            this.swipeDone = false;
            this.scrollDirection = this.getScrollDirection();
        }
        if (this.scrollDirection === 'HORIZONTAL') {
            if (this.hasTouch && event.cancelable) {
                event.preventDefault();
            }
            this.moveX = this.slideIndex * this.slideWidth + (this.touchStartX - this.touchMoveX);
            this.transformSlider(this.moveX);
        }
    }
    mouseUpHandler(event) {
        if (this.scrollDirection !== 'HORIZONTAL') {
            this.scrollDirection = null;
            this.swipeDone = true;
            return;
        }
        this.removeMultipleEvent(this.slider.current, this.mouseMoveHandler, this.swipeEvents.MOVE);
        this.removeMultipleEvent(this.slider.current, this.mouseUpHandler, this.swipeEvents.UP);
        this.removeMultipleEvent(this.slider.current, this.mouseUpHandler, this.swipeEvents.OUT);
        this.touchEndX = AbstractSlider.getEventXPosition(event);
        this.touchMoveX = AbstractSlider.getEventXPosition(event);
        const absoluteSwipeDistance = Math.abs(this.touchStartX - this.touchEndX);
        if (this.isCorrectSwipeDistance(absoluteSwipeDistance)) {
            if (this.hasSwipePercentage(absoluteSwipeDistance) || !this.isLongTouch) {
                this.moveX = this.slideIndex * this.slideWidth + (this.touchStartX - this.touchEndX);
                this.actionType = 'swipe';
            }
            this.focusSlide();
            this.hasSlided = true;
        }
        else {
            this.focusSlide();
        }
        this.slider.current.classList.add(this.classes.classSliderAnimate);
        this.scrollDirection = null;
        this.swipeDone = true;
    }
    scrollEndHandler() {
        this.focusSlide(this.getScrollIndex());
        this.slider.current.classList.add(this.classes.classSliderAnimate);
    }
    detectLongTouch() {
        this.isLongTouch = false;
        setTimeout(() => {
            this.isLongTouch = true;
        }, 250);
    }
    static preventDefaultOnNonTouchDevices(event) {
        if (!('ontouchstart' in document.documentElement)) {
            event.preventDefault();
        }
    }
    scrollHandler(event) {
        if (Math.abs(event.deltaY) < Math.abs(event.deltaX)) {
            event.preventDefault();
            this.slider.current.classList.remove(this.classes.classSliderAnimate);
            this.moveX =
                event.deltaX < 0
                    ? this.sliderTranslate3d - Math.abs(event.deltaX)
                    : this.sliderTranslate3d + Math.abs(event.deltaX);
            this.transformSlider(this.moveX);
        }
    }
    transformSlider(targetPosition) {
        return __awaiter(this, void 0, void 0, function* () {
            let newTargetPosition = targetPosition;
            const maxTargetPosition = this.slidesCount * this.slideWidth -
                this.sliderWidth +
                this.slideWidth * this.transformBouncePercentage;
            if (targetPosition > maxTargetPosition) {
                newTargetPosition = maxTargetPosition;
            }
            this.slider.current.style.transform = `translate3d(-${newTargetPosition}px,0,0)`;
            this.hasSlided = false;
        });
    }
    resizeHandler() {
        if (this.slides.length <= 1) {
            return;
        }
        this.transformSlider(this.slideIndex * this.sliderWidth);
    }
    prevSlide() {
        if (this.slideIndex === 0) {
            return;
        }
        this.slider.current.classList.add(this.classes.classSliderAnimate);
        this.focusSlide(this.slideIndex - 1);
    }
    nextSlide() {
        if (this.slideIndex === this.slides.length - 1) {
            return;
        }
        this.slider.current.classList.add(this.classes.classSliderAnimate);
        this.focusSlide(this.slideIndex + 1);
    }
    focusSlide(index) {
        if (index === undefined || Number.isNaN(index)) {
            if (this.moveX > this.slideIndex * this.slideWidth &&
                this.slideIndex < this.slidesCount - 1) {
                this.slideIndex += Math.floor(this.moveX / this.slideWidth);
                if (this.slideIndex > this.slidesCount) {
                    this.slideIndex = this.slidesCount - 1;
                }
            }
            else if (this.moveX < this.slideIndex * this.slideWidth && this.slideIndex > 0) {
                this.slideIndex -= Math.abs(Math.floor(this.moveX / this.slideWidth));
                if (this.slideIndex < 0) {
                    this.slideIndex = 0;
                }
            }
        }
        else {
            this.slideIndex = index;
        }
    }
    render() {
        const { children, omitSlideItemContainer } = this.props;
        const items = React.Children.toArray(children);
        const slideable = this.state.slidesCount * this.slideWidth >= this.sliderWidth;
        return (React.createElement(SliderContainer, { ref: this.slider, slideable: slideable },
            omitSlideItemContainer && items,
            !omitSlideItemContainer &&
                items.map((item, index) => (React.createElement(Item, { className: `item item-${index}`, key: `item${index}`, "data-index": index }, item)))));
    }
}
export const SliderContainer = styled.div `
  display: flex;
  flex-flow: row;
  width: 100%;
  align-items: center;
  transition: transform 0.3s ease-out;
  justify-content: ${(props) => (props.slideable ? 'flex-start' : 'center')};
  touch-action: pan-y;
`;
export const Item = styled.div `
  position: relative;
  display: flex;
  flex-shrink: 0;
  cursor: grab;
  user-select: none;
`;

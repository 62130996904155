var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getScsUrlPartsFromConfiguration, getVersionUrl } from './hooks';
import { fetchSVD, fetchVehicleRaw, getContextForDetailPage, getVehicleIdFromUrl, getVehicleRequestUrl, initializationPromises, } from './utils';
export function initializeFeatureApp(featureServices, config) {
    if (featureServices['s2:async-ssr-manager']) {
        return ssrInitialization(featureServices, config);
    }
    if (featureServices['s2:serialized-state-manager']) {
        return csrAfterSSRInitialization(featureServices, config);
    }
    return csrInitialization(featureServices, config);
}
function createLoadingPromise(featureServices, config, context, additionalPromises = []) {
    const i18nService = featureServices['dbad:audi-i18n-service'];
    const promises = [
        ...additionalPromises,
        i18nService.setLocale(),
        configurationServicePromise(featureServices),
    ];
    return initializationPromises(promises);
}
function ssrInitialization(featureServices, config) {
    const asyncSsrManager = featureServices['s2:async-ssr-manager'];
    const serializedStateManager = featureServices['s2:serialized-state-manager'];
    const audiFetchConfigService = featureServices['audi-fetch-config-service'];
    const i18nService = featureServices['dbad:audi-i18n-service'];
    const context = getContext(featureServices, config);
    const vehicleId = getVehicleId(featureServices, config);
    const initialization = {
        loadingPromise: undefined,
        initializationType: 'ssr',
        contextId: context.contextId,
        vehicleId,
    };
    const additionalPromises = [
        i18nServiceLoadedPromise(i18nService),
        (() => __awaiter(this, void 0, void 0, function* () {
            var _a;
            let envConfig = null;
            if ('audi:envConfigService' in featureServices) {
                envConfig = yield featureServices['audi:envConfigService'].getConfiguration('vtp');
                initialization.envConfig = envConfig;
            }
            if (vehicleId) {
                if (audiFetchConfigService) {
                    yield audiFetchConfigService.loadConfigurations([
                        { url: context.contextServiceUrl, id: context.contextId },
                    ]);
                }
                const scsUrlParts = getScsUrlPartsFromConfiguration((key) => audiFetchConfigService.getConfigurationEntryById(key, context.contextId), (_a = featureServices['vtp-configuration-service']) === null || _a === void 0 ? void 0 : _a.getConfiguration(), envConfig);
                const versionUrl = getVersionUrl(scsUrlParts);
                const svd = yield fetchSVD(versionUrl);
                const vehicleUrl = getVehicleRequestUrl(scsUrlParts, vehicleId, svd);
                const vehicleResponse = yield fetchVehicleRaw(vehicleUrl);
                initialization.vehicleRaw = vehicleResponse.vehicle;
            }
            if (serializedStateManager) {
                const serializedState = {
                    vehicleRaw: initialization.vehicleRaw,
                    envConfig,
                };
                serializedStateManager.register(() => JSON.stringify(serializedState));
            }
        }))(),
    ];
    const loadingPromise = createLoadingPromise(featureServices, config, context, additionalPromises);
    asyncSsrManager.scheduleRerender(loadingPromise.featureAppInitializationPromise);
    initialization.loadingPromise = loadingPromise;
    return initialization;
}
function csrAfterSSRInitialization(featureServices, config) {
    const serializedStateManager = featureServices['s2:serialized-state-manager'];
    const context = getContext(featureServices, config);
    const serializedSate = serializedStateManager.getSerializedState()
        ? JSON.parse(serializedStateManager.getSerializedState())
        : undefined;
    if (!serializedSate) {
        return csrInitialization(featureServices, config);
    }
    const additionalPromises = [
        (() => __awaiter(this, void 0, void 0, function* () {
            if ('audi-fetch-config-service' in featureServices) {
                yield featureServices['audi-fetch-config-service'].loadConfigurations([
                    { url: context.contextServiceUrl, id: context.contextId },
                ]);
            }
        }))(),
    ];
    const loadingPromise = createLoadingPromise(featureServices, config, context, additionalPromises);
    const trackingService = featureServices['audi-tracking-service'];
    if (trackingService) {
        trackingService.featureAppName = __FEATURE_APP_NAME__;
    }
    return {
        loadingPromise,
        initializationType: 'csr',
        vehicleRaw: serializedSate.vehicleRaw,
        contextId: context.contextId,
        vehicleId: getVehicleId(featureServices, config),
        envConfig: serializedSate.envConfig,
    };
}
function csrInitialization(featureServices, config) {
    const context = getContext(featureServices, config);
    const vehicleId = getVehicleId(featureServices, config);
    const initialization = {
        loadingPromise: undefined,
        initializationType: 'csr',
        contextId: context.contextId,
        vehicleId,
    };
    const additionalPromises = [
        (() => __awaiter(this, void 0, void 0, function* () {
            let envConfig = null;
            if ('audi:envConfigService' in featureServices) {
                envConfig = yield featureServices['audi:envConfigService'].getConfiguration('vtp');
                console.log('additionalPromises envConfig');
                initialization.envConfig = envConfig;
            }
            if ('audi-fetch-config-service' in featureServices) {
                yield featureServices['audi-fetch-config-service'].loadConfigurations([
                    { url: context.contextServiceUrl, id: context.contextId },
                ]);
            }
        }))(),
    ];
    const loadingPromise = createLoadingPromise(featureServices, config, context, additionalPromises);
    const trackingService = featureServices['audi-tracking-service'];
    if (trackingService) {
        trackingService.featureAppName = __FEATURE_APP_NAME__;
    }
    initialization.loadingPromise = loadingPromise;
    return initialization;
}
function i18nServiceLoadedPromise(i18nService) {
    return new Promise((resolve) => {
        if (i18nService) {
            i18nService.registerCallback(() => {
                resolve();
            });
        }
        else {
            resolve();
        }
    });
}
function configurationServicePromise(featureServices) {
    const configurationService = featureServices['vtp-configuration-service'];
    const configServicePromise = new Promise((resolve) => {
        if (configurationService) {
            configurationService.subscribeConfiguration(() => resolve());
        }
        else {
            resolve();
        }
    });
    const timeoutPromise = new Promise((res) => setTimeout(() => res(), 500));
    return Promise.race([configServicePromise, timeoutPromise]);
}
function getVehicleId(featureServices, config) {
    const serverRequest = featureServices['s2:server-request'];
    if (config === null || config === void 0 ? void 0 : config.vehicleId) {
        return config.vehicleId;
    }
    return serverRequest ? getVehicleIdFromUrl(serverRequest.url) : getVehicleIdFromUrl();
}
function getContext(featureServices, config) {
    const serverRequest = featureServices['s2:server-request'];
    if ((config === null || config === void 0 ? void 0 : config.contextServiceUrl) && (config === null || config === void 0 ? void 0 : config.contextId)) {
        return { contextServiceUrl: config.contextServiceUrl, contextId: config.contextId };
    }
    if (serverRequest) {
        const host = serverRequest.headers.origin || serverRequest.headers.host;
        const url = new URL(`https://${host}${serverRequest.url}`);
        return getContextForDetailPage(url);
    }
    return getContextForDetailPage();
}

import * as React from 'react';
import { createContext, useEffect, useMemo, useReducer } from 'react';
import { setFootnoteReferences } from '../state/actions';
import { footnoteReferenceReducer } from '../state/reducers';
export const FootnoteReferenceStateContext = createContext(undefined);
export const FootnoteReferenceDispatchContext = createContext(undefined);
export const FootnoteReferenceContextProvider = ({ footnoteReferenceServiceScopeManager, customScope, children, }) => {
    const footnoteReferenceService = useMemo(() => customScope
        ? footnoteReferenceServiceScopeManager.getCustomScopeRefService(customScope)
        : footnoteReferenceServiceScopeManager.getDefaultScopeRefService(), [footnoteReferenceServiceScopeManager, customScope]);
    const INITIAL_STATE = {
        references: [],
        footnoteReferenceService,
    };
    const [state, dispatch] = useReducer(footnoteReferenceReducer, INITIAL_STATE);
    useEffect(() => {
        footnoteReferenceService.unregisterCallback();
        footnoteReferenceService.registerCallback((footnoteReferences) => {
            dispatch(setFootnoteReferences(footnoteReferences));
        });
        return () => {
            footnoteReferenceService.unregisterCallback();
        };
    }, [footnoteReferenceService]);
    return (React.createElement(FootnoteReferenceStateContext.Provider, { value: state },
        React.createElement(FootnoteReferenceDispatchContext.Provider, { value: dispatch }, customScope ? React.createElement("div", { "data-fefa-custom-id": customScope }, children) : children)));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createFSAGRequest } from '../services/crs/helpers';
import { getCalculateRate, getDefaultProduct, getProducts } from '../services/crs/api';
export class ModelFinanceForm {
    constructor(crsServiceBaseUrl, trackingService) {
        this.crsServiceBaseUrl = crsServiceBaseUrl;
        this.trackingService = trackingService;
    }
    getDefaultRawDataForProduct(domain, product, vehicle, dealer) {
        return __awaiter(this, void 0, void 0, function* () {
            const requestData = createFSAGRequest('Defaults', domain, product, vehicle, dealer);
            return getDefaultProduct(this.crsServiceBaseUrl, requestData, undefined, undefined, this.trackingService, false);
        });
    }
    getDefaultDataForProduct(domain, product, vehicle, dealer) {
        return __awaiter(this, void 0, void 0, function* () {
            const rawData = yield this.getDefaultRawDataForProduct(domain, product, vehicle, dealer);
            return this.sanitizeParameters(rawData);
        });
    }
    getCalculateRateData(domain, product, productParameter, vehicle, dealer) {
        return __awaiter(this, void 0, void 0, function* () {
            const productParam = productParameter && productParameter['@ID'] && productParameter['@ID'] !== product['@ID']
                ? { Parameter: productParameter }
                : null;
            const productData = this.prepareProductRequestData(product, productParam);
            const requestData = createFSAGRequest('CalculateRate', domain, productData, vehicle, dealer);
            return getCalculateRate(this.crsServiceBaseUrl, requestData, undefined, undefined, this.trackingService, false);
        });
    }
    getProducts(domain, vehicle, defaultProduct) {
        return __awaiter(this, void 0, void 0, function* () {
            const requestData = createFSAGRequest('Products', domain, undefined, vehicle, undefined);
            const rawData = yield getProducts(this.crsServiceBaseUrl, requestData, undefined, undefined, this.trackingService, false);
            const response = this.prepareProductsResponse(rawData);
            return this.prepareProductResponse(response, defaultProduct);
        });
    }
    prepareProductsResponse(responseData) {
        if (!!responseData && !!responseData.Products && !!responseData.Products.Product) {
            return responseData.Products;
        }
        return {};
    }
    prepareProductRequestData(product, productParameter) {
        const returnValue = product || {};
        if (!!product && !!productParameter && !!productParameter.Parameter) {
            returnValue.Parameter = productParameter.Parameter;
        }
        return returnValue;
    }
    prepareProductResponse(data, defaultProduct) {
        if (!!data && !!data.Product) {
            if (!Array.isArray(data.Product)) {
                const tmpArray = [];
                tmpArray.push(data.Product);
                data.Product = tmpArray;
            }
            data.Product.forEach((product) => {
                if (product['@ID'] === defaultProduct['@ID']) {
                    data.defaultProduct = product;
                }
            });
            if (!data.defaultProduct) {
                [data.defaultProduct] = data.Product;
            }
        }
        else {
            throw data.Error ? data.Error : {};
        }
        return data;
    }
    sanitizeParameters(data) {
        const parameters = data.Parameters;
        if (parameters) {
            if (!!parameters.Description && !!parameters.Description['#text']) {
                parameters.Description = parameters.Description['#text'];
            }
            if (!!parameters.Group && !(parameters.Group instanceof Array)) {
                parameters.Group = [parameters.Group];
            }
        }
        return { Parameters: parameters };
    }
}

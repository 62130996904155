import React from 'react';
import styled from 'styled-components';
import { Text } from '@audi/audi-ui-react-v2';
import { useServicesContext } from '../../../context';
import { useFormattedPrice } from '../../../hooks';
const ParameterItem = styled.div `
  flex: 1 1 50%;
  margin-bottom: var(${(props) => props.theme.responsive.spacing.s});

  @media (max-width: 767px) {
    flex: 1 100%;
  }
`;
export const FinanceLayerParameter = ({ parameter }) => {
    const { audiMarketContextService, getConfiguration } = useServicesContext();
    const currency = getConfiguration().currency ||
        (audiMarketContextService === null || audiMarketContextService === void 0 ? void 0 : audiMarketContextService.getContextItem('nemo.locale.currency'));
    const isCurrency = parameter.unit === currency;
    const formattedValue = isCurrency
        ? useFormattedPrice(parameter.formattedValue || '', parameter.unitSymbol || '', parameter.unit || '')
        : `${parameter.formattedValue || ''} ${parameter.unit || ''}`;
    return (React.createElement(ParameterItem, null,
        React.createElement(Text, { as: "p", weight: "bold" },
            parameter.label,
            " ",
            !!parameter.note && React.createElement(React.Fragment, null, parameter.note)),
        React.createElement(Text, { variant: "copy1", as: "p" }, formattedValue)));
};

export function getFSAGDefaultProduct(productData) {
    if (Array.isArray(productData.Products.Product)) {
        const defaultProductFromFSAG = productData.Products.Product.find((product) => product['@Default'] === 'Yes') ||
            productData.Products.Product[0];
        return defaultProductFromFSAG;
    }
    return productData.Products.Product ? productData.Products.Product : undefined;
}
export const patchProductData = (productData, financeRequestData, productParameter, defaultProductData) => {
    if (!Array.isArray(productData) || !productParameter || !Object.keys(productParameter).length) {
        return;
    }
    const selectedProduct = productData.find((product) => product['@ID'] === productParameter['@ID']);
    if (!selectedProduct) {
        return;
    }
    if (financeRequestData.Product && !Array.isArray(financeRequestData.Product)) {
        financeRequestData = Object.assign(Object.assign({}, financeRequestData), { Product: { '@ID': selectedProduct['@ID'] } });
    }
    defaultProductData['@ID'] = selectedProduct['@ID'];
};
export const patchFormParameter = (defaultProductData, formParameter) => {
    if (!!formParameter && Object.keys(formParameter).length > 0) {
        return Object.assign(Object.assign({}, defaultProductData), { Parameter: formParameter });
    }
    return undefined;
};
export const getPaymentValue = (payment_, currencyPattern) => {
    const payment = payment_.filter((object) => object['@ID'] === 'Rate');
    let value = '';
    if (payment.length && payment[0].Data && payment[0].Units) {
        const unitSymbol = typeof payment[0].Units === 'string' ? payment[0].Units : payment[0].Units['@Symbol'];
        const unitText = typeof payment[0].Units === 'string' ? payment[0].Units : payment[0].Units['#text'];
        value = getPriceFormatted(payment[0].Data['#text'], unitSymbol, unitText, currencyPattern);
    }
    return value;
};
const getPriceFormatted = (value, unit = '', textUnit = '', pattern) => {
    if (value && pattern) {
        switch (pattern) {
            case '{{currency}}{{price}}':
                return `${unit !== '' ? unit : textUnit} ${value}`;
            case '{{price}}{{currency}}':
                return `${value} ${unit !== '' ? unit : textUnit}`;
            case '{{currency_text}}{{price}}':
                return `${textUnit !== '' ? textUnit : unit} ${value}`;
            case '{{price}}{{currency_text}}':
                return `${value} ${textUnit !== '' ? textUnit : unit}`;
            default:
                const displayTextUnit = textUnit !== '' ? textUnit : '';
                const displayUnit = unit !== '' ? unit : displayTextUnit;
                return `${value || ''} ${displayUnit}`;
        }
    }
    else {
        return '';
    }
};
export const mergeDeep = (target, ...sources) => {
    if (!sources.length) {
        return target;
    }
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, {
                        [key]: {},
                    });
                }
                mergeDeep(target[key], source[key]);
            }
            else {
                Object.assign(target, {
                    [key]: source[key],
                });
            }
        }
    }
    return mergeDeep(target, ...sources);
};
const isObject = (item) => {
    return item && typeof item === 'object' && !Array.isArray(item);
};

import React from 'react';
import styled from 'styled-components';
import { Button, LayoutItem } from '@audi/audi-ui-react-v2';
import { trackCTAClick } from './tracking';
import { useServicesContext } from '../../context';
import { usePostData } from '../CTA';
import { openFocusLayerV2 } from '../layer/FocusLayerV2';
export const CTAButton = ({ link, vehicle, icon, loading, disabled }) => {
    var _a;
    const layerManager = useServicesContext().getAdditionalService('gfa:layer-manager');
    const configurationService = useServicesContext().getAdditionalService('vtp-configuration-service');
    const iframeForms = ((_a = configurationService === null || configurationService === void 0 ? void 0 : configurationService.getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes.iframeForms) || false;
    const postData = typeof document !== 'undefined' && usePostData(vehicle, link.dataProfile);
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    return (React.createElement(StyledLayoutItem, { key: `${link.type}_${link.label}` },
        (link.target === 'same-window' || link.target === 'new-window') && (React.createElement(React.Fragment, null,
            link.method === 'POST' && (React.createElement("form", { method: "post", action: link.url, target: link.target === 'new-window' ? '_blank' : '_self' },
                React.createElement(StyledButton, { onClick: () => {
                        trackCTAClick(trackingService, link, vehicle.id);
                    }, type: "submit", variant: link.variant, spaceStackEnd: "xxs", spaceInlineEnd: "xxs", "data-testid": link.type, loading: loading, disabled: disabled, "data-tracking-exclude": true, icon: icon }, link.label),
                React.createElement("input", { type: "hidden", name: "inquiry_json_data", value: postData }))),
            link.method === 'GET' && link.type !== 'phone' && (React.createElement(StyledButton, { variant: link.variant, href: link.url, spaceInlineEnd: "xxs", spaceStackEnd: "xxs", loading: loading, disabled: disabled, newWindow: link.target === 'new-window', "data-testid": link.type, "data-tracking-exclude": true, onClick: () => {
                    trackCTAClick(trackingService, link);
                }, icon: icon }, link.label)))),
        link.target === 'open-in-layer' && (React.createElement(StyledButton, { variant: link.variant, spaceInlineEnd: "xxs", spaceStackEnd: "xxs", loading: loading, disabled: disabled, onClick: () => {
                trackCTAClick(trackingService, link);
                openFocusLayerV2(link, postData, layerManager, iframeForms);
            }, "data-testid": link.type, "data-tracking-exclude": true, icon: icon }, link.label)),
        link.type === 'phone' && (React.createElement(StyledButton, { variant: link.variant, href: link.url, spaceInlineEnd: "xxs", spaceStackEnd: "xxs", "data-testid": link.type, "data-tracking-exclude": true, disabled: disabled, onClick: () => {
                trackCTAClick(trackingService, link);
            }, icon: icon }, link.label))));
};
const StyledLayoutItem = styled((props) => React.createElement(LayoutItem, Object.assign({}, props))) `
  @media (max-width: 480px) {
    width: 100%;
  }
`;
const StyledButton = styled((props) => React.createElement(Button, Object.assign({}, props))) `
  @media (min-width: 440px) and (max-width: 479px) {
    width: 100%;
  }
  font-weight: normal;
`;

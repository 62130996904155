import { useEffect, useState } from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { getConsumptionLabels, getEmissionRepresentation } from '../../utils';
import { TestCycleTypesFromConfig, } from '@oneaudi/vtp-configuration-service';
import { useFuelTypesLookup } from './useFuelTypesLookup';
import { messages } from '../../i18n/messages';
export const useConsumptionLabels = (vehicle) => {
    const [consumptionValues, setConsumptionValues] = useState(['', '']);
    const wltp = useI18n(messages.drivingCycles.wltp.label);
    const nedc = useI18n(messages.drivingCycles.nedc.label);
    const fuelTypesLookup = useFuelTypesLookup();
    const emissionRepresentation = vehicle.noNedc
        ? [TestCycleTypesFromConfig.WLTP]
        : getEmissionRepresentation();
    useEffect(() => {
        if (vehicle) {
            const testCycleLabels = {
                wltp,
                nedc,
            };
            const consumptionLabels = getConsumptionLabels(vehicle, emissionRepresentation, testCycleLabels, fuelTypesLookup);
            if (consumptionValues[0] !== consumptionLabels[0] ||
                consumptionValues[1] !== consumptionLabels[1]) {
                setConsumptionValues(consumptionLabels);
            }
        }
    }, [nedc, wltp, vehicle, fuelTypesLookup, emissionRepresentation, consumptionValues]);
    return consumptionValues;
};

export var RateLayouts;
(function (RateLayouts) {
    RateLayouts["MATCHING_LAYOUT"] = "matching";
    RateLayouts["DEFAULT_LAYOUT"] = "";
})(RateLayouts || (RateLayouts = {}));
export var FinanceTemplate;
(function (FinanceTemplate) {
    FinanceTemplate["SPAIN_USED_CARS"] = "spainUsedCars";
    FinanceTemplate["DEFAULT"] = "default";
})(FinanceTemplate || (FinanceTemplate = {}));

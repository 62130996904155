var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, PopoverTrigger, Popover, PopoverBody, Text, } from '@audi/audi-ui-react-v2';
import { DangerousElement } from '../DangerousElement';
import { useFSCheckoutPostData, fsCheckoutPostRequest } from '../../utils/fsCheckoutCTA';
export const FinanceCheckoutPopoverButton = ({ buttonInfo, vehicle, showFinancingbuttonInfo, trackButtonClick, variant, buttonSize, scContextId, financeCheckoutTranslations, showAsText, isStrechedButton, }) => {
    const [isApiInprogress, setIsApiInprogress] = useState(false);
    const [isPopupStatus, setIsPopupStatus] = useState(false);
    const [fsCheckoutLink, setFsCheckoutLink] = useState(buttonInfo.notFoundPageUrl);
    const targetRef = useRef(null);
    useEffect(() => {
        if (isPopupStatus) {
            fsOnBtnClick();
        }
    }, [isPopupStatus]);
    const { popoverContinueButton, popoverCloseButton, popoverHeading, popoverContent } = financeCheckoutTranslations;
    const postDataFSCheckout = typeof document !== 'undefined' &&
        useFSCheckoutPostData(vehicle, buttonInfo.dataProfile, vehicle.countryCode, vehicle.langCode, scContextId);
    const fsOnBtnClick = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setIsApiInprogress(true);
            const fsLink = yield fsCheckoutPostRequest(buttonInfo.url, postDataFSCheckout);
            trackButtonClick(buttonInfo, showAsText ? 'text' : variant);
            setFsCheckoutLink(fsLink);
        }
        catch (e) {
            setFsCheckoutLink(buttonInfo.notFoundPageUrl);
        }
        setIsApiInprogress(false);
    });
    if (!showFinancingbuttonInfo) {
        return null;
    }
    return (React.createElement(PopoverTrigger, { keepContentHeight: true, targetRef: targetRef }, (triggerProps, close) => {
        setIsPopupStatus(triggerProps['aria-expanded']);
        return (React.createElement(React.Fragment, null,
            React.createElement(Button, Object.assign({}, triggerProps, { variant: showAsText ? 'text' : variant, size: buttonSize, stretch: isStrechedButton, spaceStackEnd: showAsText ? 'm' : 'xxs', spaceInlineEnd: showAsText ? 'xxl' : 'xxs', "data-testid": `${buttonInfo.type}` }), buttonInfo.label),
            React.createElement(StyledPopover, { "aria-label": "Popover title" },
                React.createElement(StyledPopoverBody, null,
                    React.createElement(React.Fragment, null,
                        React.createElement(Text, { as: "h2", variant: "order4", weight: "bold", spaceStackEnd: "l" }, popoverHeading),
                        React.createElement(Text, { spaceStackEnd: "l" },
                            React.createElement(DangerousElement, { as: "span", html: popoverContent, sanitize: false })),
                        React.createElement(FSPopoverCTASectionWrap, null,
                            React.createElement(StyledButton, { variant: "secondary", onClick: close, spaceInlineEnd: "xxs", spaceStackEnd: "xxs", loading: isApiInprogress, "data-testid": `${buttonInfo.type}-close` }, popoverCloseButton),
                            buttonInfo.type === 'finance-checkout' && buttonInfo.method === 'POST' && (React.createElement(React.Fragment, null,
                                React.createElement(StyledButton, { href: fsCheckoutLink, newWindow: buttonInfo.target, variant: "primary", spaceInlineEnd: "xxs", spaceStackEnd: "xxs", "data-testid": `${buttonInfo.type}-continue`, loading: isApiInprogress, "data-tracking-exclude": true }, popoverContinueButton)))))))));
    }));
};
const FSPopoverCTASectionWrap = styled.div `
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  min-height: 55px;
  margin-bottom: 5px;
  @media (max-width: 480px) {
    flex-direction: column;
    width: 98%;
  }
`;
const StyledPopover = styled((props) => React.createElement(Popover, Object.assign({}, props))) `
  max-width: none;
`;
const StyledPopoverBody = styled((props) => React.createElement(PopoverBody, Object.assign({}, props))) `
  overflow-y: visible;
`;
const StyledButton = styled((props) => React.createElement(Button, Object.assign({}, props))) `
  @media (max-width: 479px) {
    width: 100%;
  }
`;

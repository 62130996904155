var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import styled from 'styled-components';
import { Text } from '@audi/audi-ui-react-v2';
import { ACTIONS } from '@oneaudi/stck-store';
import { DangerousElement } from '../../DangerousElement';
import { messages } from '../../../i18n/messages';
import { crsFinanceModel } from './CrsFinanceModel';
import { ModelFinanceForm } from './ModelFinanceForm';
import { getNestedObject } from './objectServices';
import { getPriceFormatted } from '../services/formatServices';
import { FootnoteReference } from '../../footnotes/components/FootnoteReference';
import { useDispatch } from 'react-redux';
import { useServicesContext } from '../../../context';
import { LeasingWithoutFinancing } from './LeasingWithoutFinancing';
import { getDefaultProduct } from '../services/crs/api';
const coreCiAssets = '/bin/nemo.static/cms4i-nemo/includes/core-ci/assets';
export const LeasingInformation = ({ vehicle, fullVehicle, trackLayerLinkClick, configureFinanceComponents, }) => {
    const { getConfiguration, getEnvironmentConfig } = useServicesContext();
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const crsServiceBaseUrl = useMemo(() => { var _a; return (_a = getEnvironmentConfig()) === null || _a === void 0 ? void 0 : _a.crsServiceBaseUrl; }, [getEnvironmentConfig]);
    const currencyPattern = useMemo(() => { var _a; return (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.currencyPattern; }, [getConfiguration]);
    const leasingDisclaimer = useI18n(messages.leasing.leasingDisclaimerText);
    const financeRateButtonText = useI18n(messages.leasing.financeRateButtonChange);
    const [calculateRate, setCalculateRate] = useState();
    if (!(fullVehicle === null || fullVehicle === void 0 ? void 0 : fullVehicle.detail.financingRequest) && !vehicle.financing) {
        return (React.createElement(LeasingWithoutFinancing, { fullVehicle: fullVehicle, trackLayerLinkClick: trackLayerLinkClick, configureFinanceComponents: configureFinanceComponents }));
    }
    const dispatch = useDispatch();
    useEffect(() => {
        var _a;
        if (fullVehicle && !configureFinanceComponents.disableDynamicFinancing) {
            const financeRequest = fullVehicle.detail.financingRequest.Request;
            const productFinanceRequest = ((_a = fullVehicle.detail.financingProductsRequest) === null || _a === void 0 ? void 0 : _a.Request) || financeRequest;
            getDefaultProduct(crsServiceBaseUrl, financeRequest, undefined, undefined, trackingService, false).then((defaultResponse) => {
                dispatch(ACTIONS.FINANCING.addFinancingForVehicle({
                    vehicleId: fullVehicle.basic.id,
                    financing: {
                        defaultResponse: { Response: defaultResponse },
                        defaultProductData: {},
                        calculateRate,
                        vehicleData: {
                            default: financeRequest,
                            product: productFinanceRequest,
                        },
                    },
                }));
            });
        }
    }, [fullVehicle, configureFinanceComponents.disableDynamicFinancing]);
    useEffect(() => {
        if (!calculateRate) {
            const fn = () => __awaiter(void 0, void 0, void 0, function* () {
                const financeData = crsFinanceModel.getCrsModelData(vehicle.financing, vehicle.id);
                const currentFinancingData = yield getCurrentFinancingData(financeData, crsServiceBaseUrl, trackingService);
                setCalculateRate(currentFinancingData);
            });
            fn();
        }
    }, [crsServiceBaseUrl, calculateRate, vehicle]);
    const financing = vehicle.financing || {};
    const leasing = calculateRate
        ? getCalculateRateLeasingData(currencyPattern, calculateRate)
        : getLeasingData(financing, currencyPattern);
    const hasLeasingTemplate = leasingDisclaimer.includes('%s');
    let leasingOutput = null;
    if (leasing.value && hasLeasingTemplate) {
        const leasingDisclaimers = leasingDisclaimer.split('*%s*');
        leasingOutput = leasingDisclaimers
            .map((part) => React.createElement(React.Fragment, null, part))
            .reduce((parts, part) => {
            return parts === null ? (React.createElement(React.Fragment, null,
                part,
                React.createElement(Text, { weight: "bold", as: "span", className: "sc-finance-rate" }, leasing.value))) : (React.createElement(React.Fragment, null,
                parts,
                part));
        }, null);
        leasingOutput = (React.createElement(React.Fragment, null,
            leasingOutput,
            leasing.footnote && React.createElement(FootnoteReference, { refId: leasing.footnote }),
            "\u00A0"));
    }
    else if (leasing.value && !hasLeasingTemplate) {
        leasingOutput = (React.createElement(React.Fragment, null,
            React.createElement(Text, { weight: "bold", as: "span", className: "sc-finance-rate" },
                leasing.value,
                leasing.footnote && React.createElement(FootnoteReference, { refId: leasing.footnote }),
                "\u00A0"),
            leasingDisclaimer));
    }
    return (React.createElement(React.Fragment, null, leasing && (React.createElement(React.Fragment, null,
        React.createElement("input", { type: "hidden", className: "sc-vehicle-key", "data-vehicle-id": vehicle.id, value: vehicle.modelCode.code }),
        React.createElement(Text, { variant: "copy1", spaceStackEnd: "s", className: "sc-detail-rate" }, leasingOutput),
        leasing.disclaimer && (React.createElement(React.Fragment, null,
            React.createElement(LeasingDisclaimerStyle, { variant: "copy2", className: "sc-j-detail-disclaimer" },
                React.createElement(DangerousElement, { as: "span", html: leasing.disclaimer, sanitize: false })))),
        !configureFinanceComponents.disableDynamicFinancing && (React.createElement(LinkWrapper, null,
            React.createElement("a", { className: "audi-link-s audi-link-s--arrow sc-detail-change-rate js-modal-layer-link", "data-layer-type": "ModalLayerCrsFinanceElement" },
                React.createElement("span", { className: "audi-link-s__text" }, financeRateButtonText),
                React.createElement("svg", { className: "audi-link-s__icon" },
                    React.createElement("use", { xlinkHref: `${coreCiAssets}/icons/svg/forward-small.svg#audi-icon-forward-small` })))))))));
};
const LeasingDisclaimerStyle = styled(Text) `
  color: var(${(props) => props.theme.colors.base.grey[60]});
  hyphens: auto;
`;
const LinkWrapper = styled.div `
  margin-top: var(${(props) => props.theme.responsive.spacing.l});
`;
function getCurrentFinancingData(financeData_, crsServiceBaseUrl, trackingService) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            if (!financeData_.domain && !financeData_.Vehicle && !financeData_.Product) {
                throw new Error(`corrupted crs finance data! Some Data is not present (Domain: ${financeData_.domain}, Vehicle: ${financeData_.Vehicle}, Product: ${financeData_.Product})`);
            }
            const modelFinanceForm = new ModelFinanceForm(crsServiceBaseUrl, trackingService);
            const productData = yield modelFinanceForm.getProducts(financeData_.domain, financeData_.Vehicle, financeData_.Product);
            const defaultProductData = productData.defaultProduct &&
                productData.defaultProduct['@ID'] &&
                productData.Product.length > 1
                ? { '@ID': productData.defaultProduct['@ID'] }
                : {};
            const calculateData = yield modelFinanceForm.getCalculateRateData(financeData_.domain, financeData_.Product, defaultProductData, financeData_.Vehicle, financeData_.Dealer);
            return calculateData;
        }
        catch (e) {
            console.error(e);
        }
        return null;
    });
}
function getLeasingData(financing_ = {}, currencyPattern) {
    const leasing = {};
    if (getNestedObject(financing_, 'parameters'.split('.')) &&
        getNestedObject(financing_, 'product.label'.split('.'))) {
        if (financing_.parameters) {
            financing_.parameters.forEach((param) => {
                if (param.id === 'Rate') {
                    const value = param.formattedValue ? param.formattedValue : param.value;
                    leasing.value = getPriceFormatted(value || '', param.unitSymbol || '', param.unit || '', currencyPattern);
                }
            });
        }
        leasing.disclaimer = getCalculationDisclaimer(financing_);
        leasing.footnote = '#fn_leasing';
    }
    return leasing;
}
function getCalculationDisclaimer(financing_) {
    return (financing_ === null || financing_ === void 0 ? void 0 : financing_.calculationDisclaimer) ? financing_.calculationDisclaimer : '';
}
function getCalculateRateLeasingData(currencyPattern, calculateRate) {
    const leasing = {};
    if (Object.keys(calculateRate).length > 0) {
        leasing.disclaimer =
            calculateRate.Disclaimers &&
                calculateRate.Disclaimers.CalculationDisclaimer &&
                calculateRate.Disclaimers.CalculationDisclaimer['#cdata-section']
                ? calculateRate.Disclaimers.CalculationDisclaimer['#cdata-section']
                : '';
        leasing.footnote = 'fn_leasing';
        if (calculateRate.Payment) {
            calculateRate.Payment.forEach((param) => {
                if (param['@ID'] === 'Rate') {
                    leasing.value = getPriceFormatted(param.Value['#text'] || '', param.Units['@Symbol'] || '', param.Units['#text'] || '', currencyPattern);
                }
            });
        }
    }
    return leasing;
}

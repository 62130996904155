var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useSelector } from 'react-redux';
import fetch from 'cross-fetch';
import { SELECTORS } from '@oneaudi/stck-store';
export function fsCheckoutPostRequest(requestUrl, postData) {
    return __awaiter(this, void 0, void 0, function* () {
        const options = {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
            },
        };
        const response = yield fetch(requestUrl, Object.assign({}, options));
        response.parsedBody = yield response.json().catch((error) => {
            throw error;
        });
        if (!response.ok) {
            throw new Error(response);
        }
        return response.parsedBody.offerEntryPoint;
    });
}
export function useFSCheckoutPostData(vehicle, dataProfile, countryCode, language, scContextId) {
    let postDataString;
    useSelector((state) => {
        const financeCheckout = SELECTORS.FINANCING.getFinancingStateRaw(state)[vehicle.id];
        if (financeCheckout && dataProfile === 'finance-checkout-only') {
            const WebCalcRequest = JSON.stringify(financeCheckout.financeRequestData);
            const WebCalcResponse = JSON.stringify(financeCheckout.financeReponseData);
            postDataString = {
                CarID: vehicle.carId,
                Country: vehicle.carId.substring(0, 3),
                Brand: 'A',
                Stock: scContextId.toLowerCase(),
                Locale: `${language}-${countryCode}`,
                WebCalcRequest,
                WebCalcResponse,
            };
        }
    });
    return postDataString;
}

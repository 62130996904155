import React, { useState } from 'react';
import styled from 'styled-components';
import { CoreCiIcon } from '../..';
import { FinanceLayer } from './FinanceLayer';
const InfoIWrapper = styled.div `
  margin-left: 6px;
  cursor: pointer;
`;
const Icon = styled.div ``;
export const FinanceInfoI = ({ vehicleId, financing, trackLayerLinkClick, trackFinInfoLayerClose, }) => {
    var _a;
    const [showLayer, setShowLayer] = useState(false);
    if (!(financing === null || financing === void 0 ? void 0 : financing.parameters) || !((_a = financing === null || financing === void 0 ? void 0 : financing.product) === null || _a === void 0 ? void 0 : _a.label)) {
        return null;
    }
    const onClickHandler = (event) => {
        setShowLayer((prevState) => !prevState);
        if (trackLayerLinkClick !== undefined) {
            trackLayerLinkClick(event);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(InfoIWrapper, { "data-testid": "finance-info-i", onClick: (event) => {
                onClickHandler(event);
            }, "data-tracking-exclude": true },
            React.createElement(Icon, null,
                React.createElement(CoreCiIcon, { icon: "audi-system-info-small", size: "small" }))),
        showLayer && (React.createElement(FinanceLayer, { vehicleId: vehicleId, setShowLayer: setShowLayer, trackFinInfoLayerClose: trackFinInfoLayerClose }))));
};

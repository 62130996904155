export const urlSeparatorFor = (url) => {
    return url && url.indexOf('?') > -1 ? '&' : '?';
};
export const vtpImageUrlAdaption = (url, width, height) => {
    if (url.indexOf('vtpimages.audi.de') > -1) {
        const parts = url.split('/');
        if (parts.length < 7) {
            return url;
        }
        parts[5] = String(width);
        parts[6] = String(height);
        return parts.join('/');
    }
    if (url.indexOf('vtpimages.audi.com') > -1) {
        return `${url.split('?')[0]}?width=${width}&height=${height}&fit=bounds`;
    }
    return url;
};
export function getImageUrl(url, width, height) {
    if (url.indexOf('vtpimages') > -1) {
        return vtpImageUrlAdaption(url, width, height);
    }
    if (url.includes('mediaservice')) {
        return url.includes('?') ? `${url}&wid=${width}` : `${url}?wid=${width}`;
    }
    return url;
}

import * as React from 'react';
import { createRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ArrowTileContainer } from './ArrowTileContainer';
import Pagination from './Pagination';
import { TileElement } from './Tile';
const observerOptions = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
};
const getObserver = (ref, handler) => {
    const observer = ref.current;
    if (observer !== null) {
        return observer;
    }
    const newObserver = new IntersectionObserver(handler, observerOptions);
    ref.current = newObserver;
    return newObserver;
};
export const NUMBER_OF_DESKTOP_TILES = 3;
export const NUMBER_OF_TABLET_TILES = 2;
export const getDeviceType = () => {
    if (window.matchMedia('(min-width: 1024px)').matches) {
        return 'desktop';
    }
    if (window.matchMedia('(min-width: 768px) and (max-width: 1023px)').matches) {
        return 'tablet';
    }
    return 'mobile';
};
const SliderComponent = ({ onChangeHandler, isSuggestedCars, children, }) => {
    const tiles = React.Children.toArray(children);
    const scrollContainer = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const tileRefs = Array(tiles.length)
        .fill(1)
        .map(() => createRef());
    const desktop = getDeviceType() === 'desktop';
    const tablet = getDeviceType() === 'tablet';
    const updateIndexFromArrow = (index) => {
        if (onChangeHandler) {
            onChangeHandler('arrow');
        }
        updateIndex(index);
    };
    const updateIndexFromPagination = (index) => {
        if (onChangeHandler) {
            onChangeHandler('text link');
        }
        updateIndex(index);
    };
    const updateIndex = (index) => {
        var _a;
        if (index < 0 || index >= tiles.length) {
            return;
        }
        scrollContainer.current.scrollTo({
            behavior: 'smooth',
            left: (((_a = tileRefs[currentIndex].current) === null || _a === void 0 ? void 0 : _a.clientWidth) || 0) * index,
            top: 0,
        });
        setCurrentIndex(index);
    };
    const observer = useRef(null);
    useEffect(() => {
        let timeoutId;
        const handler = (entries) => {
            const filteredEntries = entries.sort((e1, e2) => e2.intersectionRatio - e1.intersectionRatio);
            const maxIntersectionEntry = filteredEntries.length > 0 ? filteredEntries[0] : null;
            if (maxIntersectionEntry) {
                const index = parseFloat(maxIntersectionEntry.target.getAttribute('data-index'));
                clearTimeout(timeoutId);
                const scrollPosition = scrollContainer.current.scrollLeft;
                timeoutId = setTimeout(() => {
                    if (scrollPosition !== scrollContainer.current.scrollLeft) {
                        return;
                    }
                    setCurrentIndex(desktop ? Math.floor(index / NUMBER_OF_DESKTOP_TILES) * NUMBER_OF_DESKTOP_TILES : index);
                }, 200);
            }
        };
        if (observer.current) {
            observer.current.disconnect();
        }
        const newObserver = getObserver(observer, handler);
        tileRefs.forEach((tileRef) => {
            newObserver.observe(tileRef.current);
        });
        return () => {
            newObserver.disconnect();
        };
    }, [tileRefs, desktop]);
    const missingTilesForEven = desktop
        ? NUMBER_OF_DESKTOP_TILES - (tiles.length % NUMBER_OF_DESKTOP_TILES)
        : tablet
            ? NUMBER_OF_TABLET_TILES - (tiles.length % NUMBER_OF_TABLET_TILES)
            : 0;
    return (React.createElement(React.Fragment, null,
        React.createElement(ArrowTileContainer, { currentIndex: currentIndex, length: tiles.length, updateIndex: updateIndexFromArrow, isSuggestedCars: isSuggestedCars },
            React.createElement(SliderContainer, { scrollable: true, ref: scrollContainer },
                tiles.map((tile, index) => (React.createElement(TileElement, { ref: tileRefs[index], className: `tile-${index}`, key: `tile${index}`, "data-index": index }, tile))),
                [...Array(missingTilesForEven)].map((_undefinedValue, index) => (React.createElement(TileElement, { key: `filluptile${index}` }))))),
        React.createElement(Pagination, { currentIndex: currentIndex, length: tiles.length, updateIndex: updateIndexFromPagination })));
};
const SliderContainer = styled.div `
  width: 100%;
  align-items: stretch;
  display: flex;
  overflow: ${(props) => (props.scrollable ? 'scroll' : 'hidden')};
  padding-bottom: 1px;
  position: relative;
  scroll-snap-type: x mandatory;
  scroll-padding: auto;
  scrollbar-width: none;
  scroll-snap-align: center;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    scroll-padding: 0;
  }
`;
export const Slider = React.memo(SliderComponent);

import styled from 'styled-components';

export const ListContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(${(props): string => props.theme.responsive.spacing.m});

  @media screen and (min-width: ${(props): string => props.theme.breakpoints.m}px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const GridContainer = styled.section``;

import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useI18n } from '@oneaudi/i18n-context';
import { Icon, iconSystemInfoSmall } from './icon';
import { AudiPlatformProvider } from '@audi/audi-ui-react-v2';
export const RenderedMarker = ({ picture }) => {
    const popupContent = useI18n({
        id: 'nemo.ui.sc.stage.renderedDisclaimer',
        defaultMessage: '',
    });
    if (!isTypedPicture(picture) || picture.type !== 'render') {
        return null;
    }
    if (!popupContent.trim()) {
        return null;
    }
    const popupButtonRef = useRef(null);
    const [popupButtonBottom, setPopupButtonBottom] = useState(0);
    const [popupButtonLeft, setPopupButtonLeft] = useState(0);
    const [open, setOpen] = useState(false);
    const clickHandler = (evt) => {
        evt.preventDefault();
        const popupButtonCoord = popupButtonRef.current.getBoundingClientRect();
        if (popupButtonCoord) {
            setPopupButtonBottom(popupButtonCoord.bottom + document.documentElement.scrollTop);
            setPopupButtonLeft(popupButtonCoord.left);
        }
        setOpen(true);
        setTimeout(() => setOpen(false), 1000);
    };
    const handleScroll = () => {
        setOpen(false);
    };
    useEffect(() => {
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (React.createElement(PopupContainer, null,
        React.createElement(PopupButton, { type: "button", "aria-labelledby": picture.key, onClick: clickHandler, "data-testid": "infoBtn", onMouseEnter: clickHandler, ref: popupButtonRef },
            React.createElement(Icon, { icon: iconSystemInfoSmall })),
        open &&
            ReactDOM.createPortal(React.createElement(AudiPlatformProvider, null,
                React.createElement(PopupContent, { role: "tooltip", id: picture.key, top: popupButtonBottom, left: popupButtonLeft }, popupContent)), document.body)));
};
const PopupContainer = styled.div `
  position: absolute;
  top: var(${(props) => props.theme.responsive.spacing.s});
  left: var(${(props) => props.theme.responsive.spacing.s});
`;
const PopupContent = styled.div `
  display: block;
  background-color: var(${(props) => props.theme.colors.base.grey[0]});
  position: absolute;
  left: ${(props) => props.left - 10.5}px;
  top: ${(props) => props.top + 7}px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
  width: 18vw;
  max-width: 320px;
  word-wrap: break-word;
  z-index: 999;
  padding: var(${(props) => props.theme.responsive.spacing.s});
  font-family: var(${(props) => props.theme.fontFamily});
  font-size: var(${(props) => props.theme.responsive.typography.copy1.fontSize});
  color: var(${(props) => props.theme.colors.base.grey[80]});
  font-weight: var(${(props) => props.theme.fontWeights.normal});
  font-stretch: var(${(props) => props.theme.responsive.typography.copy1.fontStretch});
  text-align: left;
  line-height: var(${(props) => props.theme.responsive.typography.copy1.lineHeight});
  -webkit-font-smoothing: antialiased;

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 13px;
    width: 18px;
    height: 8px;
    background-color: var(${(props) => props.theme.colors.base.grey[0]});
    clip-path: polygon(0 100%, 50% 0, 100% 100%);
  }
  @media (max-width: ${(props) => props.theme.breakpoints.m}px) {
    width: 315px;
  }
`;
const PopupButton = styled.button `
  position: absolute;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  color: var(${(props) => props.theme.colors.base.grey[100]});
  cursor: pointer;
  z-index: 2;

  svg {
    display: block;
  }

  path {
    fill: var(${(props) => props.theme.colors.base.grey[0]});
  }
`;
export function isTypedPicture(picture) {
    return 'type' in picture;
}

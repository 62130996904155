var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { httpRequest } from './httpRequest';
export const fetchSVD = (versionUrl) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield httpRequest(versionUrl, {
        cache: 'no-cache',
    });
    const { version } = response.parsedBody;
    return version;
});
export const fetchVehicleRaw = (vehicleRequestUrl) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield httpRequest(vehicleRequestUrl);
    return response.parsedBody;
});
export const fetchMatches = (matchingRequestUrl) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield httpRequest(matchingRequestUrl);
    return {
        matches: response.parsedBody.vehicleBasic,
        matchingScores: response.parsedBody.debug.finalScores,
        filterExpression: response.parsedBody.filterExpression,
    };
});
export const fetchCompare = (compareRequestUrl) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    if (!compareRequestUrl) {
        throw new Error('no valid url for compare request');
    }
    const response = yield httpRequest(compareRequestUrl);
    const compareDataRaw = response.parsedBody;
    if (!((_a = compareDataRaw === null || compareDataRaw === void 0 ? void 0 : compareDataRaw.basic[0]) === null || _a === void 0 ? void 0 : _a.title) || !((_b = compareDataRaw === null || compareDataRaw === void 0 ? void 0 : compareDataRaw.basic[1]) === null || _b === void 0 ? void 0 : _b.title)) {
        throw new Error('no valid cars for compare found');
    }
    return compareDataRaw;
});

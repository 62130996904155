import React, { useMemo } from 'react';
import { Text } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import styled from 'styled-components';
import { messages } from '../../../i18n/messages';
import { RegularPrice } from './RegularPrice';
import { Prices } from './Prices';
import { AvailableSoon } from '../AvailableSoon';
import { ExceptionalFinancing } from '../ExceptionalFinancing';
import { FinancingWrapper } from '../FinancingWrapper';
import { useServicesContext } from '../../../context';
import { isAvailableSoonVehicle, isBevAgencyVehicle, isNationWideSellingVehicle, } from '../../../utils';
import { useAvailableSoonLabels } from '../../../hooks';
import { usePriceLabel } from '../../../hooks/text/usePriceLabel';
const ConditionalFlexWrap = styled.div `
  display: ${(props) => (props.isFlex ? 'flex' : 'block')};

  @media screen and (max-width: 768px) {
    display: block;
  }
`;
const NWSDisclaimerStyle = styled(Text) `
  color: var(${(props) => props.theme.colors.base.grey[60]});
`;
export const PriceInformation = ({ vehicle, fullVehicle, loanTypeLink, trackLayerLinkClick, trackFinInfoLayerClose, configureFinanceComponents, }) => {
    var _a, _b, _c, _d, _e, _f;
    const vehicleBasic = vehicle || fullVehicle.basic;
    const { disableFinancing } = configureFinanceComponents;
    const { getConfiguration } = useServicesContext();
    const scopesFromConfig = useMemo(() => { var _a; return (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes; }, [(_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes]);
    const regularPrice = (_b = vehicle.typedPrices) === null || _b === void 0 ? void 0 : _b.find((typedPrice) => {
        return typedPrice.type === 'regular';
    });
    const retailPrice = (_c = vehicle.typedPrices) === null || _c === void 0 ? void 0 : _c.find((typedPrice) => {
        return typedPrice.type === 'retail';
    });
    const isNewCar = ((_d = vehicle === null || vehicle === void 0 ? void 0 : vehicle.type) === null || _d === void 0 ? void 0 : _d.toUpperCase()) === 'N';
    const useDynamicLabel = scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.useDynamicPriceLabels;
    const regularNameText = (_e = regularPrice === null || regularPrice === void 0 ? void 0 : regularPrice.nameText) === null || _e === void 0 ? void 0 : _e.toLowerCase();
    const regularLabel = usePriceLabel(regularPrice, false, isBevAgencyVehicle(vehicle) && isNewCar, vehicle.type, vehicle.countryCode);
    const regularFootnoteSuffix = regularNameText && useDynamicLabel ? `--${regularNameText}` : null;
    const retailNameText = (_f = retailPrice === null || retailPrice === void 0 ? void 0 : retailPrice.nameText) === null || _f === void 0 ? void 0 : _f.toLowerCase();
    const retailLabel = usePriceLabel(retailPrice, configureFinanceComponents.hideActionPriceLabel, isBevAgencyVehicle(vehicle) && isNewCar, vehicle.type, vehicle.countryCode);
    const retailNameTextwithSuffic = retailNameText && useDynamicLabel ? `--${retailNameText}` : null;
    const retailFootnoteSuffix = retailNameText && isBevAgencyVehicle(vehicle)
        ? `--${retailNameText}--bev`
        : retailNameTextwithSuffic;
    const footnoteLabelNc = useI18n(messages.prices.footnote.nc);
    const footnoteLabelNcRetail = useI18n(messages.prices.footnote.ncRetail);
    const footnoteLabelUc = useI18n(messages.prices.footnote.uc);
    const footnoteLabelUcRetail = useI18n(messages.prices.footnote.ucRetail);
    const priceLabel = useI18n(messages.prices.text);
    const nwsLabel = useI18n(messages.prices.nws);
    const financeLabelNc = useI18n(messages.prices.finance.nc);
    const financeLabelUc = useI18n(messages.prices.finance.uc);
    const financeLabel = isNewCar ? financeLabelNc : financeLabelUc;
    const showAvailableFromDateIndependentlyFromData = scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.showAvailableSoonDate;
    const availableSoonVehicle = isAvailableSoonVehicle(vehicle, showAvailableFromDateIndependentlyFromData);
    const { expectedPriceLabel } = useAvailableSoonLabels(vehicle);
    const showNwsDisclaimer = isNationWideSellingVehicle(vehicle) && (scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.showNwsDisclaimer);
    const showRegularPrice = !(scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.hideRegularPrice) && (retailPrice === null || retailPrice === void 0 ? void 0 : retailPrice.formatted) !== (regularPrice === null || regularPrice === void 0 ? void 0 : regularPrice.formatted);
    const showPrices = !(scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.hidePrices);
    const isExceptionalFinancing = !!vehicleBasic.financeAmount && !!vehicleBasic.financeLoan;
    return (React.createElement(React.Fragment, null,
        React.createElement(AvailableSoon, { vehicle: vehicle }),
        React.createElement(ConditionalFlexWrap, { isFlex: isExceptionalFinancing },
            !!(regularPrice === null || regularPrice === void 0 ? void 0 : regularPrice.formatted) && showPrices && showRegularPrice && !availableSoonVehicle && (React.createElement(RegularPrice, { price: regularPrice === null || regularPrice === void 0 ? void 0 : regularPrice.formatted, showPrice: showRegularPrice, showPrices: showPrices, label: regularLabel, footnoteLabel: isNewCar ? footnoteLabelNc : footnoteLabelUc, showNwsDisclaimer: showNwsDisclaimer, footnoteSuffix: regularFootnoteSuffix })),
            showPrices && (React.createElement(Prices, { vehicle: vehicle, price: retailPrice === null || retailPrice === void 0 ? void 0 : retailPrice.formatted, regularPrice: regularPrice === null || regularPrice === void 0 ? void 0 : regularPrice.formatted, showPrices: showPrices, label: retailLabel, footnoteLabel: isNewCar
                    ? footnoteLabelNcRetail || footnoteLabelNc
                    : footnoteLabelUcRetail || footnoteLabelUc, financeLabel: financeLabel, showNwsDisclaimer: showNwsDisclaimer, availableSoon: availableSoonVehicle, expectedPriceLabel: expectedPriceLabel, footnoteSuffix: retailFootnoteSuffix })),
            !disableFinancing ? (React.createElement(React.Fragment, null,
                React.createElement(FinancingWrapper, { vehicle: vehicle, fullVehicle: fullVehicle, trackLayerLinkClick: trackLayerLinkClick, trackFinInfoLayerClose: trackFinInfoLayerClose, configureFinanceComponents: configureFinanceComponents }),
                isExceptionalFinancing && (React.createElement(ExceptionalFinancing, { financeAmount: vehicleBasic.financeAmount, financeLoan: vehicleBasic.financeLoan, loanTypeLink: loanTypeLink })))) : null),
        priceLabel !== '' && (React.createElement(Text, { variant: "copy2", spaceStackStart: "s" }, priceLabel)),
        showNwsDisclaimer && isNewCar && (React.createElement(NWSDisclaimerStyle, { variant: "copy2", className: "sc-detail-carinfo-special-disclaimer", spaceStackStart: "s" },
            "* ",
            nwsLabel))));
};

import { Button, Loader } from '@audi/audi-ui-react-v2';
import React, { useState } from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { useSelector, useDispatch } from 'react-redux';
import { SELECTORS } from '@oneaudi/stck-store';
import { useServicesContext, useSvd, loadFilteredResults } from '@oneaudi/vtp-shared';
import { AudiStockcarsStoreServiceV1 } from '@oneaudi/stockcars-store-service';
import { getAppContext } from '../hooks/tileHook';
import { Container, LoaderContainer } from './LoadMoreButton.styles';

const LoadMoreButton: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const loadMoreBtnLabel = useI18n({ id: 'nemo.ui.sc.load-more-cars.btn' });

  const storeService = useServicesContext().getAdditionalService(
    'audi-stockcars-store-service'
  ) as AudiStockcarsStoreServiceV1;

  const { getConfiguration, getEnvironmentConfig } = useServicesContext();
  const svd = useSvd();

  const dispatch = useDispatch();
  const { vehiclesCount, vehicles } = useSelector((state: any) => {
    return {
      vehiclesCount: SELECTORS.VEHICLES.getTotalCountState(state),
      vehicles: SELECTORS.VEHICLES.getResultVehicleIdsState(state),
    };
  });
  const appContext = getAppContext();

  if (!vehiclesCount || !vehicles || vehiclesCount <= vehicles.length) {
    return null;
  }

  const loadMore = async () => {
    setLoading(true);
    await loadFilteredResults(
      { vtpConfiguration: getConfiguration()! },
      getEnvironmentConfig()!,
      storeService.store,
      dispatch,
      appContext !== 'suggested' ? appContext : 'results',
      svd.svd
    );
    setLoading(false);
  };

  return (
    <Container>
      <Button
        variant="primary"
        spaceStackEnd="xxl"
        onClick={loadMore}
        disabled={loading}
        data-testid="loadMore"
      >
        {loadMoreBtnLabel}
      </Button>
      {loading && (
        <LoaderContainer>
          <Loader variant="linear" />
        </LoaderContainer>
      )}
    </Container>
  );
};

export default LoadMoreButton;

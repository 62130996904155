import { Button, Text, NavigationBar, NavigationItem } from '@audi/audi-ui-react-v2';
import {
  ConsumptionTileElement,
  FootnoteReference,
  getRelatedProduct,
  OrderTypes,
  useServicesContext,
  VehicleBasic,
  Campaign,
  isCampaignActive,
  EnvKConsumptionAndEmissions,
} from '@oneaudi/vtp-shared';
import React, { useEffect, useState } from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { getContentConfiguration } from '../../hooks/tileHook';
import { GfaLayerManagerV1 } from '@volkswagen-onehub/gfa-layer-manager';
import { FocusLayerSizeV2 } from '@volkswagen-onehub/layer-manager';
import styled from 'styled-components';
import { FeatureAppLoader } from '@feature-hub/react';
import TileWarranties from './warranties/TileWarranties';
import TileCampaigns from './campaigns/TileCampaigns';
import { trackLayerLinkClick, trackLayerOpen, trackLayerClose, trackTabChange } from '../tracking';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { EnvkvValuesContainer, TabsContainer } from '../Tile.styles';
import DistinctiveDGT from './DistinctiveDGT';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';

interface Props {
  vehicle: VehicleBasic;
  detailsPageLink: string;
}

const Tabs: React.FC<Props> = ({ vehicle, detailsPageLink }) => {
  const [activeTab, setActiveTab] = useState('consumption');
  const consumptionLabel = useI18n({ id: 'nemo.ui.sc.tiles.tabs.emissions' });
  const warrantiesLabel = useI18n({ id: 'nemo.ui.sc.tiles.tabs.warranty' });
  const campaignsLabel = useI18n({ id: 'nemo.ui.sc.tiles.tabs.advantages' });
  const distinctiveDGTLabel = useI18n({
    id: 'nemo.ui.sc.tiles.tabs.distinctive',
    defaultMessage: 'Distinctive DGT',
  });
  const consumptionLayerLabel = useI18n({ id: 'nemo.ui.sc.tiles.consumption.link.text' });

  const consumptionDisplayedItems = ['consumption', 'emission', 'efficiency'] as OrderTypes[];
  if (isElectricalFn(vehicle)) {
    consumptionDisplayedItems.push('electrical-range');
  }

  const layerManager = useServicesContext().getAdditionalService(
    'gfa:layer-manager'
  ) as GfaLayerManagerV1;
  const trackingService = useServicesContext().getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;
  const localeService = useServicesContext().getAdditionalService(
    'gfa:locale-service'
  ) as LocaleServiceV1;

  const contentConfiguration = getContentConfiguration();
  const getConfigurationObj = useServicesContext().getConfiguration();

  const scStartPageUrl = getConfigurationObj?.urls?.scStartPageLink;

  const consumptionVersion = getConfigurationObj?.featureApps?.consumptionVersion || '2.15.0';
  const warrantiesVersion = getConfigurationObj?.featureApps?.warrantiesVersion || '2.16.0';

  const openLayer = () => {
    layerManager?.openFocusLayer(
      () => (
        <LayerContainer className="tileLayer">
          <div data-fefa-custom-id="tileConsumption">
            <FeatureAppLoader
              featureAppId="tileConsumption"
              src={`https://fa-vtp-consumption.cdn.prod.arcade.apps.one.audi/${consumptionVersion}/fh/app.js`}
              config={{
                vehicleId: vehicle.id,
                eecImageUrl: `https://fa-vtp-consumption.cdn.prod.arcade.apps.one.audi/${consumptionVersion}/static`,
              }}
            />
          </div>
          <FeatureAppLoader
            featureAppId="tile-footnotes"
            src="https://fa-footnote-engine.cdn.prod.arcade.apps.one.audi/v2.2.3/fh/app.js"
            config={{
              apiUrls: [
                `${scStartPageUrl}/commons/sc_footnotes/_jcr_content/content/sc_footnotes.json`,
              ],
              omitSideSpacing: true,
              omitBottomSpacing: true,
              uiTheme: 'light',
              layerElementClassName: 'tileLayer',
            }}
          />
        </LayerContainer>
      ),
      {},
      {
        size: FocusLayerSizeV2.A,
        userCloseable: true,
        onClose: () => {
          if (trackingService) {
            trackLayerClose(
              trackingService,
              'vtp emission layer',
              contentConfiguration?.appContext
            );
          }
        },
      }
    );
    trackLayerLinkClick(
      trackingService,
      consumptionLayerLabel,
      'text link',
      relatedProduct,
      contentConfiguration?.appContext
    );
    trackLayerOpen(
      trackingService,
      'emission layer',
      'vtp emission layer',
      undefined,
      contentConfiguration?.appContext
    );
  };

  const isDEMarket = localeService.countryCode?.toLowerCase() === 'de';
  const consumptionTabPrereq =
    contentConfiguration?.tile.consumptionItems && hasConsumptionValues(vehicle);
  const hasConsumptionTab = () => {
    if (!consumptionTabPrereq) {
      return false;
    }
    if (!isDEMarket) {
      return true;
    }
    return !!vehicle.envkv2024;
  };

  const pollutionBadge = vehicle.pollutionBadge?.code;
  const hasDistinctiveDGTTab =
    pollutionBadge &&
    contentConfiguration?.tile?.distinctiveDGT?.showDistinctiveDGTTab &&
    contentConfiguration?.tile?.distinctiveDGT?.pollutionBadgeBaseURL;

  // TODO: implement warranties from used.warrantyTypes (for markets with old warranty information)
  const hasWarrantiesTab = contentConfiguration?.tile.warranties && hasWarrantyValues(vehicle);
  const hasFAWarranties = hasWarrantiesTab && vehicle.warrantyInfo?.warranties?.length;
  const hasFormerWarranties = hasWarrantiesTab && vehicle.used?.warrantyTypes;
  const hasCampaigns = hasCampaignsValues(vehicle);
  const activeCampaigns = hasCampaigns
    ? (vehicle.campaigns as Campaign[]).filter((campaign: Campaign) => isCampaignActive(campaign))
    : [];
  const hasCampaignsTab = activeCampaigns.length > 0;

  if (!hasConsumptionTab() && !hasCampaignsTab && !hasWarrantiesTab && !hasDistinctiveDGTTab) {
    return null;
  }
  const pollutionBadgeBaseURL = contentConfiguration?.tile?.distinctiveDGT?.pollutionBadgeBaseURL;
  const polltionBadgeAssetURL = `${pollutionBadgeBaseURL}/img/environmental_labels/${vehicle.countryCode?.toLocaleLowerCase()}/${
    vehicle.pollutionBadge?.code
  }.png`;

  useEffect(() => {
    if (!hasConsumptionTab()) {
      if (hasCampaignsTab) {
        setActiveTab('campaigns');
      } else if (hasDistinctiveDGTTab) {
        setActiveTab('distinctive');
      } else if (hasWarrantiesTab) {
        setActiveTab('warranties');
      }
    }
  }, []);
  const getLabelForTabTracking = (tab: string): string => {
    switch (tab) {
      case 'consumption':
        return consumptionLabel;
      case 'warranties':
        return warrantiesLabel;
      case 'campaigns':
        return campaignsLabel;
      case 'distinctive':
        return distinctiveDGTLabel;
      default:
        return consumptionLabel;
    }
  };
  const relatedProduct = getRelatedProduct(vehicle);
  const handleTabChange = (tab: string) => {
    const activeTabLabel = getLabelForTabTracking(tab);
    trackTabChange(
      trackingService,
      activeTabLabel,
      relatedProduct,
      contentConfiguration?.appContext
    );
    setActiveTab(tab);
  };

  const hasWarrantyPlus = vehicle.warrantyPlus;
  const consumptionFootnoteRef =
    vehicle.type === 'U' ? 'fn_consumption_stockcar_used' : 'fn_consumption_stockcar_new';

  return (
    <TabsContainer>
      <NavigationBar
        size="small"
        selected={activeTab}
        onSelect={(tab) => {
          handleTabChange(tab);
        }}
        spaceStackEnd="s"
      >
        {hasConsumptionTab() && (
          <NavigationItem id="consumption">
            {consumptionLabel}
            {vehicle.type === 'U' && (
              <Text variant="copy2" as="span">
                <FootnoteReference refId="fn_original_state" useSpan />
              </Text>
            )}
            <Text variant="copy2" as="span">
              <FootnoteReference
                refId={consumptionFootnoteRef}
                data-testid={consumptionFootnoteRef}
                useSpan
              />
            </Text>
          </NavigationItem>
        )}
        {hasCampaignsTab && <NavigationItem id="campaigns">{campaignsLabel}</NavigationItem>}
        {hasDistinctiveDGTTab && (
          <NavigationItem id="distinctive">{distinctiveDGTLabel}</NavigationItem>
        )}
        {hasWarrantiesTab && <NavigationItem id="warranties">{warrantiesLabel}</NavigationItem>}
      </NavigationBar>
      {hasConsumptionTab() && activeTab === 'consumption' && (
        <>
          {isDEMarket && (
            <EnvkvValuesContainer>
              <EnvKConsumptionAndEmissions vehicle={vehicle} data-testid="pkv-envkv" />
            </EnvkvValuesContainer>
          )}
          {!isDEMarket && (
            <>
              <ConsumptionTileElement
                vehicleId={vehicle.id}
                order={contentConfiguration?.tile.consumptionItems!}
                displayedItems={consumptionDisplayedItems}
                hideLayerBtn
                data-testid="consumptionElement"
              />
              <Button
                variant="text"
                size="small"
                spaceInlineStart="s"
                onClick={openLayer}
                data-testid="consumptionLayer"
              >
                {consumptionLayerLabel}
              </Button>
            </>
          )}
        </>
      )}
      {hasCampaignsTab && activeTab === 'campaigns' && (
        <TileCampaigns
          activeCampaigns={activeCampaigns}
          detailsPageLink={detailsPageLink}
          vehicle={vehicle}
        />
      )}
      {activeTab === 'distinctive' && (
        <>
          <DistinctiveDGT assetUrl={polltionBadgeAssetURL} pollutionBadge={pollutionBadge} />
        </>
      )}
      {activeTab === 'warranties' && (
        <>
          <TileWarranties
            version={warrantiesVersion}
            vehicle={vehicle}
            hasFAWarranties={hasFAWarranties}
            hasFormerWarranties={hasFormerWarranties}
            hasWarrantyPlus={hasWarrantyPlus}
            data-testid="warrantyElement"
          />
        </>
      )}
    </TabsContainer>
  );
};

function isElectricalFn(vehicle: VehicleBasic) {
  const phevIdentifier = 'OVC_HEV';
  let isElectrical = false;
  const engineType = vehicle.io?.engineType || '';
  if (vehicle.io && vehicle.io.fuels && vehicle.io.fuels.length) {
    isElectrical = !!vehicle.io.fuels.filter((item = { fuel: undefined }) => {
      return item.fuel === 'ELECTRICAL' && engineType !== phevIdentifier;
    }).length;
  }
  return isElectrical;
}

export function hasConsumptionValues(vehicle: VehicleBasic | undefined) {
  return (
    vehicle?.io?.hasWltp ||
    vehicle?.io?.hasNedc ||
    vehicle?.vlsEnergyProvision?.hasWltp ||
    vehicle?.vlsEnergyProvision?.hasNedc
  );
}

export function hasWarrantyValues(vehicle: VehicleBasic | undefined) {
  if (
    (vehicle?.used?.warrantyTypes && vehicle?.used?.warrantyTypes.length > 0) ||
    (vehicle?.warrantyInfo?.warranties?.length && vehicle?.warrantyInfo?.warranties?.length > 0)
  ) {
    return true;
  }
  return false;
}

export function hasCampaignsValues(vehicle: VehicleBasic | undefined) {
  return vehicle?.hasCampaigns && vehicle?.campaigns?.length;
}

const LayerContainer = styled.div``;

export default Tabs;

import { useI18n } from '@oneaudi/i18n-context';
export const usePKVConsumptionLabels = (engineType) => {
    switch (engineType) {
        case 'PEV':
            return useI18n({
                id: `nemo.ui.pkv.${engineType}.power.consumption.label`,
                defaultMessage: 'Stromverbrauch (kombiniert)',
            });
        case 'ICE':
        case 'NOVC_HEV':
            return useI18n({
                id: `nemo.ui.pkv.${engineType}.fuel.consumption.label`,
                defaultMessage: 'Kraftstoffverbrauch (kombiniert)',
            });
        case 'OVC_HEV':
            return [
                useI18n({
                    id: `nemo.ui.pkv.${engineType}.fuel.consumption.weighted.label`,
                    defaultMessage: 'Kraftstoffverbrauch (gewichtet kombiniert)',
                }),
                useI18n({
                    id: `nemo.ui.pkv.${engineType}.power.consumption.weighted.label`,
                    defaultMessage: 'Stromverbrauch (gewichtet kombiniert)',
                }),
                useI18n({
                    id: `nemo.ui.pkv.${engineType}.power.consumption.discharged.battery.label`,
                    defaultMessage: 'Kraftstoffverbrauch bei entladener Batterie (kombiniert)',
                }),
            ];
        default:
            return '';
    }
};

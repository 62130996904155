export const getRelatedProduct = (vehicle) => {
    return {
        productInfo: {
            productId: vehicle.id || '',
            productName: vehicle.modelCode ? vehicle.modelCode.description : '',
            manufacturer: 'Audi',
        },
        category: {
            primaryCategory: vehicle.symbolicCarlineGroup ? vehicle.symbolicCarlineGroup.code : '',
            subCategory1: vehicle.symbolicCarline ? vehicle.symbolicCarline.code : '',
            productType: vehicle.type === 'N' ? 'new car' : 'used car',
        },
        attributes: vehicle,
    };
};

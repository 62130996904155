import { useEffect, useState } from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { formatLabel } from '../../components/tiles/warranty/formatLabel';
export const useWarrantyLabel = (techDataKey, ItemLabel) => {
    const [warrantyLabel, setWarrantyLabel] = useState('');
    const warrantyTypeWrrantyDesc = useI18n({
        id: 'nemo.ui.sc.highlights.warranty.yes',
        defaultMessage: 'Yes',
    });
    const audiPlusLabel = formatLabel(ItemLabel);
    useEffect(() => {
        if (techDataKey.includes('WarrantyTypeWarranty')) {
            setWarrantyLabel(`${audiPlusLabel}: ${warrantyTypeWrrantyDesc}`);
        }
        else {
            setWarrantyLabel(audiPlusLabel);
        }
    }, [warrantyLabel, techDataKey, ItemLabel, audiPlusLabel]);
    return warrantyLabel;
};

export var FuelTypes;
(function (FuelTypes) {
    FuelTypes["ELECTRICAL"] = "ELECTRICAL";
    FuelTypes["PETROL"] = "PETROL";
    FuelTypes["DIESEL"] = "DIESEL";
    FuelTypes["HYDROGEN"] = "HYDROGEN";
    FuelTypes["H2NG"] = "H2NG";
})(FuelTypes || (FuelTypes = {}));
export var EngineType;
(function (EngineType) {
    EngineType["PEV"] = "PEV";
    EngineType["ICE"] = "ICE";
    EngineType["NOVC_HEV"] = "NOVC_HEV";
    EngineType["OVC_HEV"] = "OVC_HEV";
})(EngineType || (EngineType = {}));

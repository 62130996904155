import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { ProductData } from '@oneaudi/vtp-shared';
import { AppContextForThisInstance, InitialTab } from '../../@types/editor';

const currentOrigin = typeof window !== 'undefined' ? window.location.origin : '';
const getEventName = (ctaType: string, eventName: string): string => {
  let parsedEventName = '';
  switch (ctaType) {
    case 'contact':
    case 'nws':
      parsedEventName = `${eventName} - click on contact`;
      break;
    case 'ecom':
      parsedEventName = `${eventName} - click on oneaudi shop`;
      break;
    case 'phone':
      parsedEventName = `${eventName} - call dealer`;
      break;
    default:
      parsedEventName = `${eventName} - click on generic cta`;
  }
  return parsedEventName;
};
interface FeatureAppNames {
  eventName: string;
  componentName: string;
}
export const getFeatureAppNames = (appContext?: AppContextForThisInstance): FeatureAppNames => {
  if (appContext === 'suggested') {
    return {
      eventName: 'vtp suggested cars',
      componentName: 'vtp-suggested-cars',
    };
  }
  return {
    eventName: 'vtp results',
    componentName: ' vtp-results',
  };
};

export const trackImageChange = (
  trackingService: TrackingServiceV2,
  elementName: 'arrow' | 'image' | 'other',
  label: string,
  pos: string,
  relatedProduct: ProductData,
  actionType: 'swipe' | 'click' | 'automatic',
  appContext?: AppContextForThisInstance
) => {
  const { eventName, componentName } = getFeatureAppNames(appContext);
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'navigation',
          eventName: `${eventName} - change gallery image`,
        },
        attributes: {
          componentName,
          actionType,
          label,
          targetURL: '',
          clickID: '',
          elementName,
          value: '',
          pos,
          relatedProduct,
        },
      },
    });
  }
};
export const trackSliderChange = (
  trackingService: TrackingServiceV2,
  elementName: 'arrow' | 'text link' | 'other'
) => {
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'content',
          eventName: 'vtp suggested cars - navigate',
        },
        attributes: {
          componentName: 'vtp-suggested-cars',
          actionType: 'click',
          label: '',
          targetURL: '',
          clickID: '',
          elementName,
          value: '',
          pos: '',
        },
      },
    });
  }
};

export const trackViewChange = (
  trackingService: TrackingServiceV2,
  viewType: 'list' | 'grid',
  label: string
) => {
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'content',
          eventName: viewType === 'list' ? 'vtp results - list view' : 'vtp results - grid view',
        },
        attributes: {
          componentName: 'vtp-results',
          label,
          targetURL: '',
          clickID: '',
          elementName: 'text link',
          value: '',
          pos: '',
        },
      },
      componentUpdate: {
        attributes: {
          viewType,
        },
      },
    });
  }
};

export const trackSorting = (trackingService: TrackingServiceV2, label: string, value: string) => {
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'content',
          eventName: 'vtp results - sort',
        },
        attributes: {
          componentName: 'vtp-results',
          label,
          targetURL: '',
          clickID: '',
          elementName: 'drop down',
          value,
          pos: '',
        },
      },
      componentUpdate: {
        attributes: {
          sortingOption: value,
        },
      },
    });
  }
};

export const trackDetailsPageNav = (
  trackingService: TrackingServiceV2,
  label: string | any,
  targetURL: string,
  elementName: string,
  appContext?: AppContextForThisInstance
) => {
  const completeURL = `${currentOrigin}${targetURL}`;
  const { eventName, componentName } = getFeatureAppNames(appContext);
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'internal_link',
          eventName: `${eventName} - go to detail page`,
        },
        attributes: {
          componentName,
          label,
          actionType: 'click',
          targetURL: completeURL,
          clickID: '',
          elementName,
          value: '',
          pos: '',
        },
      },
    });
  }
};

export const trackAddToFav = (
  trackingService: TrackingServiceV2,
  label: string,
  relatedProduct: ProductData,
  appContext?: AppContextForThisInstance
) => {
  const { eventName, componentName } = getFeatureAppNames(appContext);
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'favorite',
          eventName: `${eventName} - add to favorites`,
        },
        attributes: {
          componentName,
          label,
          targetURL: '',
          clickID: '',
          elementName: 'icon',
          value: '',
          pos: '',
          relatedProduct,
        },
      },
    });
  }
};

export const trackRemoveFromFav = (
  trackingService: TrackingServiceV2,
  label: string,
  relatedProduct: ProductData,
  appContext?: AppContextForThisInstance
) => {
  const { eventName, componentName } = getFeatureAppNames(appContext);
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'favorite',
          eventName: `${eventName} - remove from favorites`,
        },
        attributes: {
          componentName,
          label,
          targetURL: '',
          clickID: '',
          elementName: 'icon',
          value: '',
          pos: '',
          relatedProduct,
        },
      },
    });
  }
};

export const trackTabChange = (
  trackingService: TrackingServiceV2,
  label: string,
  relatedProduct?: ProductData,
  appContext?: AppContextForThisInstance
) => {
  const { eventName, componentName } = getFeatureAppNames(appContext);
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'content',
          eventName: `${eventName} - tab click`,
        },
        attributes: relatedProduct
          ? {
              componentName,
              label,
              targetURL: '',
              clickID: '',
              elementName: 'tab',
              value: '',
              pos: '',
              relatedProduct,
              actionType: 'click',
            }
          : {
              componentName,
              label,
              targetURL: '',
              clickID: '',
              elementName: 'tab',
              value: '',
              pos: '',
              actionType: 'click',
            },
      },
    });
  }
};
const getEcomEventAction = (targetURL: string) =>
  targetURL.startsWith(window.location.origin) ? 'internal_link' : 'exit_link';
export const trackCTAClick = (
  trackingService: TrackingServiceV2,
  eventAction: 'call' | 'internal_link' | 'exit_link',
  type: string,
  label: string,
  targetURL: string,
  relatedProduct: ProductData,
  appContext?: AppContextForThisInstance
) => {
  const { eventName, componentName } = getFeatureAppNames(appContext);
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: type === 'ecom' ? getEcomEventAction(targetURL) : eventAction,
          eventName: getEventName(type, eventName),
        },
        attributes: {
          componentName,
          label,
          targetURL,
          clickID: '',
          elementName: 'text link',
          value: '',
          pos: '',
          relatedProduct,
        },
      },
    });
  }
};

export const trackLayerLinkClick = (
  trackingService: TrackingServiceV2,
  label: string,
  elementName: 'text link' | 'icon',
  relatedProduct: ProductData,
  appContext?: AppContextForThisInstance
) => {
  const { eventName, componentName } = getFeatureAppNames(appContext);
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'internal_link',
          eventName: `${eventName} - open modal layer `,
        },
        attributes: {
          componentName,
          label,
          targetURL: '',
          clickID: '',
          elementName,
          value: '',
          pos: '',
          relatedProduct,
          actionType: 'click',
        },
      },
    });
  }
};
export const trackLayerOpen = (
  trackingService: TrackingServiceV2,
  eventType: string,
  pageID?: string,
  promotionName?: string,
  appContext?: AppContextForThisInstance
) => {
  const { eventName, componentName } = getFeatureAppNames(appContext);
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'view_change',
          eventName: `${eventName} - ${eventType}`,
        },
        attributes: {
          componentName,
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        },
      },
      componentUpdate: {
        page: {
          pageInfo: {
            pageID,
          },
          attributes: {
            promotionName,
          },
        },
      },
    });
  }
};
export const trackLayerClose = (
  trackingService: TrackingServiceV2,
  value: string,
  appContext?: AppContextForThisInstance
) => {
  const { eventName, componentName } = getFeatureAppNames(appContext);
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'content',
          eventName: `${eventName} - close modal layer`,
        },
        attributes: {
          componentName,
          label: '',
          targetURL: '',
          clickID: '',
          elementName: 'text link',
          value,
          pos: '',
        },
      },
      componentUpdate: {
        page: {
          pageInfo: {
            pageID: '',
          },
          attributes: {
            promotionName: '',
          },
        },
      },
    });
  }
};

export const trackFeatureAppReady = (
  trackingService: TrackingServiceV2,
  viewType: 'list' | 'grid',
  sortingOption: string,
  appContext?: AppContextForThisInstance
) => {
  const { eventName, componentName } = getFeatureAppNames(appContext);
  if (trackingService) {
    trackingService.track({
      event: {
        eventInfo: {
          eventAction: 'feature_app_ready',
          eventName: `${eventName} - feature app ready`,
        },
        attributes: {
          version: __FEATURE_APP_VERSION__,
          componentName,
          label: '',
          targetURL: '',
          clickID: '',
          elementName: '',
          value: '',
          pos: '',
        },
      },
      componentUpdate: {
        attributes: {
          implementer: 2,
          viewType,
          sortingOption,
        },
      },
    });
  }
};
export const registerSuggestedCarsImpressionTracking = (
  trackingService: TrackingServiceV2,
  ref: any,
  appContext: AppContextForThisInstance,
  tabState: InitialTab
) => {
  const { eventName, componentName } = getFeatureAppNames(appContext);
  let initalTab = '';
  switch (tabState) {
    case 'previouslyViewedCars':
      initalTab = 'cars_recently_seen';
      break;
    case 'similarCarsFromDealer':
      initalTab = 'similar_cars_from_dealer';
      break;
    default:
      initalTab = 'similar_cars';
      break;
  }
  if (trackingService) {
    if (ref !== null) {
      trackingService.registerImpressionTracking(ref.current, () => {
        return {
          event: {
            eventInfo: {
              eventAction: 'impression',
              eventName: `${eventName} - impression`,
            },
            attributes: {
              componentName,
              label: '',
              targetURL: '',
              clickID: '',
              elementName: '',
              value: initalTab,
              pos: '',
            },
          },
        };
      });
    }
  }
};

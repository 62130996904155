import * as React from 'react';
import { Text } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { useI18n } from '@oneaudi/i18n-context';
const VehicleOrderStatusWrap = styled.div `
  margin-top: var(${(props) => props.theme.responsive.spacing.s});
`;
const IN_DELIVERY_STATES = ['7', '8', '9', '10'];
const AT_DEALER_STATES = ['11', '12'];
export var DeliveryStates;
(function (DeliveryStates) {
    DeliveryStates["IN_DELIVERY"] = "in-delivery";
    DeliveryStates["AT_DEALER"] = "at-dealer";
})(DeliveryStates || (DeliveryStates = {}));
export const hasOrderStatus = (orderStatus) => {
    if (!orderStatus) {
        return false;
    }
    const validType = !Number.isNaN(parseInt(orderStatus, 10));
    const validState = IN_DELIVERY_STATES.includes(orderStatus) || AT_DEALER_STATES.includes(orderStatus);
    return validType && validState;
};
export const getOrderStatusTextKey = (orderStatus) => {
    if (IN_DELIVERY_STATES.includes(orderStatus)) {
        return DeliveryStates.IN_DELIVERY;
    }
    if (AT_DEALER_STATES.includes(orderStatus)) {
        return DeliveryStates.AT_DEALER;
    }
    return '';
};
export const VehicleOrderStatus = ({ vehicleOrderStatus }) => {
    if (!hasOrderStatus(vehicleOrderStatus)) {
        return null;
    }
    const orderStatusTextKey = getOrderStatusTextKey(vehicleOrderStatus);
    const orderStatusLabel = useI18n({ id: 'nemo.ui.sc.details.dealer.order-status.label' });
    const orderStatus = hasOrderStatus(vehicleOrderStatus)
        ? useI18n({
            id: orderStatusTextKey === 'in-delivery'
                ? 'nemo.ui.sc.details.dealer.order-status.in-delivery'
                : 'nemo.ui.sc.details.dealer.order-status.at-dealer',
        })
        : '';
    return (React.createElement(VehicleOrderStatusWrap, null,
        React.createElement(Text, { variant: "copy1", weight: "bold", as: "span" },
            orderStatusLabel,
            ":",
            ' '),
        React.createElement(Text, { variant: "copy1", as: "span" }, orderStatus)));
};

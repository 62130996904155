var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ACTIONS } from '@oneaudi/stck-store';
import { isCampaignActive } from '../../../utils';
export function getActiveCampaign(fullVehicle, envConfig, svd, configuration) {
    return __awaiter(this, void 0, void 0, function* () {
        const campaigns = yield getActiveCampaigns(fullVehicle, envConfig, svd, configuration);
        return campaigns.reduce(isOlderCampaign, {});
    });
}
export function getActiveCampaigns(fullVehicle, envConfig, svd, configuration) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        if ((_a = fullVehicle.basic.campaigns) === null || _a === void 0 ? void 0 : _a.length) {
            const activeCampaigns = yield fetchCampaigns(envConfig, svd, configuration);
            if (!activeCampaigns.length) {
                return [];
            }
            const vehiclesActiveCampaigns = fullVehicle.basic.campaigns.filter((vehicleCampaign) => {
                return (vehicleCampaign.type &&
                    vehicleCampaign.type === 'FINANCING' &&
                    vehicleCampaign.typeDetail &&
                    activeCampaigns.some((activeCampaign) => activeCampaign.campaignId === vehicleCampaign.campaignId) &&
                    isCampaignActive(vehicleCampaign));
            });
            return vehiclesActiveCampaigns;
        }
        return [];
    });
}
export function fetchCampaigns(envConfig, svd, configuration) {
    return __awaiter(this, void 0, void 0, function* () {
        const url = `${envConfig.scs.baseUrl}${envConfig.scs.defaultApiVersion}/structure/campaigns/${configuration.scs.scsMarketPath}?svd=${svd}`;
        try {
            const response = yield fetch(url);
            const data = yield response.json();
            return data;
        }
        catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    });
}
export const resetFinancingCampaign = (dispatch, fullVehicle) => {
    dispatch(ACTIONS.FINANCING.addFinancingForVehicle({
        vehicleId: fullVehicle.basic.id,
        financing: { campaign: {} },
    }));
};
export const isOlderCampaign = (acc, current) => {
    if (!acc.startDate) {
        return current;
    }
    return acc.startDate < current.startDate ? acc : current;
};

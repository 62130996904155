import { useI18n } from '@oneaudi/i18n-context';
export const useCO2ClassLabels = (engineType) => {
    switch (engineType) {
        case 'PEV':
        case 'ICE':
        case 'NOVC_HEV':
            return useI18n({
                id: `nemo.ui.pkv.${engineType}.co2.class.label`,
                defaultMessage: `${decodeURIComponent('CO%E2%82%82')}-Klasse`,
            });
        case 'OVC_HEV':
            return [
                useI18n({
                    id: `nemo.ui.pkv.${engineType}.co2.class.weighted.label`,
                    defaultMessage: `${decodeURIComponent('CO%E2%82%82')}-Klasse (gewichtet kombiniert)`,
                }),
                useI18n({
                    id: `nemo.ui.pkv.${engineType}.co2.class.discharged.battery.label`,
                    defaultMessage: `${decodeURIComponent('CO%E2%82%82')}-Klasse bei entladener Batterie`,
                }),
            ];
        default:
            return '';
    }
};

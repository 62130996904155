import { useEffect, useState } from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { useFormattedDate } from './useFormattedDate';
import { messages } from '../../i18n/messages';
export const useVehicleInspectionDueDate = (timestamp) => {
    const inspectionDueDateFallbackDescription = useI18n({
        id: 'nemo.ui.sc.highlight-label.vehicleinspectionduedate.fallback',
        defaultMessage: 'sdd fallback',
    });
    const descriptionPast = useI18n(messages.vehicleInspectionDueDatePastDescription);
    const descriptionFuture = useFormattedDate(timestamp);
    const [description, setDescription] = useState('');
    useEffect(() => {
        if (!timestamp) {
            setDescription(inspectionDueDateFallbackDescription);
            return;
        }
        if (Number.isNaN(timestamp)) {
            return;
        }
        if (new Date().getTime() - Number(timestamp) < 0) {
            setDescription(descriptionFuture);
        }
        else {
            setDescription(descriptionPast);
        }
    }, [descriptionFuture, descriptionPast, timestamp]);
    return description;
};

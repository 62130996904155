import React, { useEffect } from 'react';
import styled from 'styled-components';
export const Iframe = ({ src, originUrl = src }) => {
    const handleIframePostMessage = (event) => {
        const iframe = document.querySelector('iframe[name=cta-iframe]');
        const iframeUrl = new URL(originUrl);
        if (event.origin === iframeUrl.origin) {
            try {
                const messageDataJson = JSON.parse(event.data);
                if (messageDataJson.height) {
                    iframe.height = `${messageDataJson.height}px`;
                }
            }
            catch (error) {
                console.warn('No message data');
            }
        }
    };
    useEffect(() => {
        window.addEventListener('message', handleIframePostMessage);
        return () => {
            window.removeEventListener('message', handleIframePostMessage);
        };
    });
    return React.createElement(StyledIframe, { name: "cta-iframe", src: src, width: "100%" });
};
const StyledIframe = styled.iframe `
  border: 0;
`;

import React from 'react';
import { VehicleBasic } from '@oneaudi/vtp-shared';
import { getContentConfiguration, isListView as isListViewFn } from '../hooks/tileHook';
import DetailInfoItem from './DetailInfoItem';
import { GridContainer, ListContainer } from './DetailInfo.styles';

export interface Props {
  vehicle: VehicleBasic;
}

const DetailInfo: React.FC<Props> = ({ vehicle }) => {
  const isListView = isListViewFn();

  const Container = isListView ? ListContainer : GridContainer;
  const detailItems = getContentConfiguration()?.tile.detailInformation;

  if (!detailItems) {
    return null;
  }

  return (
    <Container>
      {detailItems.map((item) => (
        <DetailInfoItem vehicle={vehicle} itemKey={item!} key={item} data-testid="detailItem" />
      ))}
    </Container>
  );
};

export default DetailInfo;

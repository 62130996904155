import { Divider, Layout, LayoutItem, SkeletonShape } from '@audi/audi-ui-react-v2';

import React from 'react';
import {
  GridTileContainer,
  GridGallery,
  GridContent,
  ListTileContainer,
  ListGallery,
  ListContent,
} from '../Tile.styles';
import { LayoutContainer } from '../Tiles.styles';
import {
  GridContainer as GridContainerDetailInfo,
  ListContainer as ListContainerDetailInfo,
} from '../DetailInfo.styles';
import {
  ListContainer as ListActionButtonContainer,
  GridContainer as GridActionButtonContainer,
} from '../ActionButtons.styles';
import { Container as GalleryContainer } from '../Gallery/Gallery.styles';
import { InitialViewTypeForTheTiles } from '../../../@types/editor.d';
import { CTAContainer } from '@oneaudi/vtp-shared';

interface Props {
  viewType: InitialViewTypeForTheTiles;
}

export const TileSkeleton: React.FC<Props> = ({ viewType }) => {
  const TileContainer = viewType === 'grid' ? GridTileContainer : ListTileContainer;
  const Gallery = viewType === 'grid' ? GridGallery : ListGallery;
  const Content = viewType === 'grid' ? GridContent : ListContent;
  const DetailInfoContainer =
    viewType === 'grid' ? GridContainerDetailInfo : ListContainerDetailInfo;
  const ActionButtonContainer =
    viewType === 'grid' ? GridActionButtonContainer : ListActionButtonContainer;

  return (
    <TileContainer>
      <Gallery>
        <GalleryContainer>
          <SkeletonShape variant="rectangular" height="310px" width="100%" spaceStackEnd="l" />
        </GalleryContainer>
      </Gallery>
      <Content>
        {/* carline */}
        <SkeletonShape variant="rectangular" height="32px" width="150px" />
        {/* model description */}
        <SkeletonShape variant="rectangular" height="20px" width="180px" spaceStackEnd="l" />
        {/* buyable online / leasing car */}
        <SkeletonShape variant="rectangular" height="20px" width="180px" spaceStackEnd="s" />
        {/* finance */}
        <SkeletonShape variant="rectangular" height="24px" width="200px" spaceStackEnd="m" />
        <Divider as="hr" spaceStackEnd="m" data-testid="dealerLabelSeparator" />
        {/* Dealer contact */}
        <Layout>
          <LayoutItem align="center" spaceInlineEnd="s">
            <SkeletonShape variant="circular" height="24px" width="24px" />
          </LayoutItem>
          <LayoutItem align="center" grow="1">
            <SkeletonShape variant="rectangular" height="20px" width="185px" />
            <SkeletonShape variant="rectangular" height="20px" width="300px" />
            <SkeletonShape variant="rectangular" height="20px" width="230px" />
          </LayoutItem>
        </Layout>
        {/* CTA links */}
        <LayoutContainer spaceStackStart="m" direction="row">
          <LayoutItem>
            <SkeletonShape
              variant="rectangular"
              height="20px"
              width="150px"
              spaceInlineEnd="xxl"
              spaceStackEnd="m"
            />
          </LayoutItem>
          <SkeletonShape
            variant="rectangular"
            height="20px"
            width="100px"
            spaceInlineEnd="xxl"
            spaceStackEnd="m"
          />
          <SkeletonShape
            variant="rectangular"
            height="20px"
            width="75px"
            spaceInlineEnd="xxl"
            spaceStackEnd="m"
          />
          <SkeletonShape
            variant="rectangular"
            height="20px"
            width="150px"
            spaceInlineEnd="xxl"
            spaceStackEnd="m"
          />
        </LayoutContainer>
        {/*  detail info */}
        <Divider as="hr" spaceStackEnd="m" />
        <DetailInfoContainer>
          <SkeletonShape
            variant="rectangular"
            height="20px"
            width="150px"
            spaceInlineEnd="xxl"
            spaceStackEnd="xxs"
          />
          <SkeletonShape
            variant="rectangular"
            height="20px"
            width="150px"
            spaceInlineEnd="xxl"
            spaceStackEnd="xxs"
          />
        </DetailInfoContainer>
        {/* tabs */}
        <Divider as="hr" spaceStackStart="s" />
        {/* tabs navigation */}
        <SkeletonShape variant="rectangular" height="46px" width="360px" spaceStackEnd="s" />
        {/* tabs content */}
        <SkeletonShape variant="rectangular" height="20px" width="300px" spaceStackEnd="s" />
        <SkeletonShape variant="rectangular" height="20px" width="300px" spaceStackEnd="s" />
        <SkeletonShape variant="rectangular" height="20px" width="300px" spaceStackEnd="s" />
        <Divider as="hr" spaceStackStart="s" spaceStackEnd="s" />
        {/* CTAs */}
        <CTAContainer appContext="tiles">
          <SkeletonShape
            variant="rectangular"
            height="60px"
            width="100%"
            spaceStackEnd="xxs"
            spaceInlineEnd="xxl"
          />
        </CTAContainer>
        <ActionButtonContainer>
          <Layout spaceStackEnd="m">
            <LayoutItem align="start">
              <SkeletonShape variant="circular" height="24px" width="24px" spaceInlineEnd="s" />
            </LayoutItem>
            <SkeletonShape variant="rectangular" height="24px" width="200px" />
          </Layout>
          <Layout>
            <LayoutItem align="start">
              <SkeletonShape variant="circular" height="24px" width="24px" spaceInlineEnd="s" />
            </LayoutItem>
            <SkeletonShape variant="rectangular" height="24px" width="200px" />
          </Layout>
        </ActionButtonContainer>
      </Content>
    </TileContainer>
  );
};

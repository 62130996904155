import React from 'react';
import GenericButton from './GenericButton';
import styled from 'styled-components';
import { Divider, Layout } from '@audi/audi-ui-react-v2';
import { useButtons } from './useButtons';
export const Buttons = ({ configurationService, vehicle, appContext, layerManager, trackButtonClick, environmentConfig, financeCheckoutTranslations, showAsText, isTilesButtonMode, isTilesListView, showFinancing, }) => {
    const { isDesktop, buttonsState, scContextId, phoneWithNumber } = useButtons(vehicle, appContext, configurationService, environmentConfig, showAsText, isTilesButtonMode);
    let buttonIndex = 0;
    const isSpecialTilesListView = appContext === 'tiles' && isTilesListView && isDesktop;
    const isStrechedButton = appContext === 'tiles' && !showAsText;
    return !isSpecialTilesListView ? (React.createElement(CTAContainer, { appContext: appContext, showAsText: showAsText, "data-testid": "standard-view" },
        appContext === 'tiles' && !showAsText && buttonsState.length > 0 && (React.createElement(Divider, { as: "hr", spaceStackStart: "s", spaceStackEnd: "s", "data-testid": "ctaSeparator" })),
        buttonsState.map((button, index) => {
            const variant = index === 0 ? 'primary' : 'secondary';
            buttonIndex += 1;
            return (React.createElement(GenericButton, { key: `Button-${buttonIndex}-vehicle-${vehicle.id}`, vehicle: vehicle, buttonInfo: button, appContext: appContext, variant: variant, "data-testId": `standard-button-${variant}`, layerManager: layerManager, scContextId: scContextId, financeCheckoutTranslations: financeCheckoutTranslations, phoneWithNumber: phoneWithNumber, configurationService: configurationService, trackButtonClick: trackButtonClick, isStrechedButton: isStrechedButton, showAsText: showAsText, showFinancing: showFinancing }));
        }))) : (React.createElement(Layout, { direction: "column", "data-testid": "tiles-list-view" },
        appContext === 'tiles' && !showAsText && buttonsState.length > 0 && (React.createElement(Divider, { as: "hr", spaceStackStart: "s", spaceStackEnd: "s", "data-testid": "ctaSeparator" })),
        buttonsState[0] && (React.createElement(GenericButton, { key: `Button-${buttonIndex}-vehicle-${vehicle.id}`, vehicle: vehicle, buttonInfo: buttonsState[0], "data-testId": "list-button-primary", appContext: appContext, variant: "primary", layerManager: layerManager, scContextId: scContextId, financeCheckoutTranslations: financeCheckoutTranslations, phoneWithNumber: phoneWithNumber, configurationService: configurationService, trackButtonClick: trackButtonClick, isStrechedButton: true, showAsText: showAsText, showFinancing: showFinancing })),
        React.createElement(TilesRowContainer, null, buttonsState.slice(1).map((button) => {
            buttonIndex += 1;
            return (React.createElement(TilesRowItem, null,
                React.createElement(GenericButton, { key: `Button-${buttonIndex}-vehicle-${vehicle.id}`, vehicle: vehicle, buttonInfo: button, "data-testId": "list-button-secondary", appContext: appContext, variant: "secondary", layerManager: layerManager, scContextId: scContextId, financeCheckoutTranslations: financeCheckoutTranslations, phoneWithNumber: phoneWithNumber, isStrechedButton: true, configurationService: configurationService, trackButtonClick: trackButtonClick, showFinancing: showFinancing })));
        }))));
};
export const CTAContainer = styled.div `
  display: flex;
  flex-direction: ${(props) => props.appContext === 'tiles' && !props.showAsText ? 'column' : 'row'};
  margin: ${(props) => props.appContext === 'carinfo'
    ? `var(${props.theme.responsive.spacing.xs}) var(${props.theme.responsive.spacing.xs}) 0 0`
    : ''};
  margin-left: ${(props) => props.appContext === 'tiles' && props.showAsText
    ? `calc(
    var(${props.theme.responsive.spacing.l}) +
      var(${props.theme.responsive.spacing.s})
  );`
    : ''}
  flex-wrap: ${(props) => (props.appContext === 'tiles' && props.showAsText ? `wrap;` : '')}
    ${(props) => props.appContext === 'carinfo'
    ? `  
  @media (max-width: 768px) {
    display: block;
  }
  @media (max-width: 479px) {
    flex-direction: column;
    margin-right: 0px;
  }`
    : ``};
`;
const TilesRowContainer = styled.div `
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  justify-content: space-between;
`;
const TilesRowItem = styled.div `
  box-sizing: border-box;
  flex-grow: 1;
`;

import { VehicleBasic, useServicesContext } from '@oneaudi/vtp-shared';
import { getContentConfiguration } from './tileHook';
import { FeatureAppConfig } from '../FeatureHubAppDefinition';
import { useMemo } from 'react';

export const useVehicleDetailsPageUrl = (vehicle: VehicleBasic | undefined) => {
  const contentConfiguration = getContentConfiguration();
  const servicesContext = useServicesContext();

  return useMemo(() => {
    if (!vehicle) {
      return undefined;
    }
    const detailsPageUrlPattern = contentConfiguration?.urls?.detailsPageUrlPattern;
    const featureAppConfig = servicesContext?.featureAppConfig as FeatureAppConfig;

    return detailsPageUrlPattern
      ? `${featureAppConfig.baseUrl || ''}${detailsPageUrlPattern?.replace(
          'SC_VEHICLE_ID',
          vehicle.id
        )}`
      : vehicle.entryUrl;
  }, [contentConfiguration, servicesContext, vehicle]);
};

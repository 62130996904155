import { TestCycleTypesFromConfig, } from '@oneaudi/vtp-configuration-service';
import { FuelTypes } from '../interfaces';
import { getConsumptionValueUnitTuple, isPHEV } from './vehicle';
export const getConsumptionLabels = (vehicle, emissionRepresentation_, testCycleLabels, fuelTypesLookup) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const emissionRepresentation = !Array.isArray(emissionRepresentation_) || !emissionRepresentation_.length
        ? [TestCycleTypesFromConfig.WLTP]
        : emissionRepresentation_;
    const fuels = ((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vlsEnergyProvision) === null || _a === void 0 ? void 0 : _a.hasWltp) || ((_b = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vlsEnergyProvision) === null || _b === void 0 ? void 0 : _b.hasNedc)
        ? (_c = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vlsEnergyProvision) === null || _c === void 0 ? void 0 : _c.fuels
        : vehicle === null || vehicle === void 0 ? void 0 : vehicle.io.fuels;
    if (!fuels || !fuels.length) {
        return ['', null];
    }
    let primaryConsumptionValue;
    let secondaryConsumptionValue;
    if (isPHEV(vehicle)) {
        const primaryFuel = fuels.find((fuelType) => fuelType.fuel === FuelTypes.PETROL || fuelType.fuel === FuelTypes.DIESEL);
        const electricalFuel = fuels.find((fuelType) => fuelType.fuel === FuelTypes.ELECTRICAL);
        const [primaryWltpValue = '', primaryWltpUnit = ''] = getConsumptionValueUnitTuple(primaryFuel, TestCycleTypesFromConfig.WLTP);
        const [primaryNedcValue = '', primaryNedcUnit = ''] = getConsumptionValueUnitTuple(primaryFuel, TestCycleTypesFromConfig.NEDC);
        const [plugInWltpValue = '', plugInWltpUnit = ''] = getConsumptionValueUnitTuple(electricalFuel, TestCycleTypesFromConfig.WLTP);
        const [plugInNedcValue = '', plugInNedcUnit = ''] = getConsumptionValueUnitTuple(electricalFuel, TestCycleTypesFromConfig.NEDC);
        const primaryEngineValues = new Map();
        if (primaryWltpValue) {
            primaryEngineValues.set(TestCycleTypesFromConfig.WLTP, `${primaryWltpValue} ${primaryWltpUnit} (${testCycleLabels[TestCycleTypesFromConfig.WLTP]})`);
        }
        if (primaryNedcValue) {
            primaryEngineValues.set(TestCycleTypesFromConfig.NEDC, `${primaryNedcValue} ${primaryNedcUnit} (${testCycleLabels[TestCycleTypesFromConfig.NEDC]})`);
        }
        const secondaryEngineValues = new Map();
        if (plugInWltpValue) {
            secondaryEngineValues.set(TestCycleTypesFromConfig.WLTP, `${plugInWltpValue} ${plugInWltpUnit} (${testCycleLabels[TestCycleTypesFromConfig.WLTP]})`);
        }
        if (plugInNedcValue) {
            secondaryEngineValues.set(TestCycleTypesFromConfig.NEDC, `${plugInNedcValue} ${plugInNedcUnit} (${testCycleLabels[TestCycleTypesFromConfig.NEDC]})`);
        }
        if (emissionRepresentation.length === 2 &&
            secondaryEngineValues.size === 2 &&
            primaryEngineValues.size === 2) {
            primaryConsumptionValue = `${fuelTypesLookup[(_d = fuels[0]) === null || _d === void 0 ? void 0 : _d.fuel]} ${primaryEngineValues.get(emissionRepresentation[0])}; ${primaryEngineValues.get(emissionRepresentation[1])}`;
            secondaryConsumptionValue = `${fuelTypesLookup[(_e = fuels[1]) === null || _e === void 0 ? void 0 : _e.fuel]} ${secondaryEngineValues.get(emissionRepresentation[0])}; ${secondaryEngineValues.get(emissionRepresentation[1])}`;
            return [primaryConsumptionValue, secondaryConsumptionValue || null];
        }
        const alternativeTestCycle = emissionRepresentation[0] === TestCycleTypesFromConfig.WLTP
            ? TestCycleTypesFromConfig.NEDC
            : TestCycleTypesFromConfig.WLTP;
        primaryConsumptionValue = `${fuelTypesLookup[(_f = fuels[0]) === null || _f === void 0 ? void 0 : _f.fuel]} ${primaryEngineValues.get(emissionRepresentation[0]) ||
            primaryEngineValues.get(alternativeTestCycle)}`;
        secondaryConsumptionValue = `${fuelTypesLookup[(_g = fuels[1]) === null || _g === void 0 ? void 0 : _g.fuel]} ${secondaryEngineValues.get(emissionRepresentation[0]) ||
            secondaryEngineValues.get(alternativeTestCycle)}`;
        return [primaryConsumptionValue, secondaryConsumptionValue || null];
    }
    const [wltpValue = '', wltpUnit = ''] = getConsumptionValueUnitTuple(fuels[0], TestCycleTypesFromConfig.WLTP);
    const [nedcValue = '', nedcUnit = ''] = getConsumptionValueUnitTuple(fuels[0], TestCycleTypesFromConfig.NEDC);
    const engineValuesLookup = new Map();
    if (wltpValue) {
        engineValuesLookup.set(TestCycleTypesFromConfig.WLTP, `${fuelTypesLookup[(_h = fuels[0]) === null || _h === void 0 ? void 0 : _h.fuel]} ${wltpValue} ${wltpUnit} (${testCycleLabels[TestCycleTypesFromConfig.WLTP]})`);
    }
    if (nedcValue && !vehicle.noNedc) {
        engineValuesLookup.set(TestCycleTypesFromConfig.NEDC, `${fuelTypesLookup[(_j = fuels[0]) === null || _j === void 0 ? void 0 : _j.fuel]} ${nedcValue} ${nedcUnit} (${testCycleLabels[TestCycleTypesFromConfig.NEDC]})`);
    }
    const alternativeTestCycle = emissionRepresentation[0] === TestCycleTypesFromConfig.WLTP
        ? TestCycleTypesFromConfig.NEDC
        : TestCycleTypesFromConfig.WLTP;
    primaryConsumptionValue =
        engineValuesLookup.get(emissionRepresentation[0]) ||
            engineValuesLookup.get(alternativeTestCycle);
    secondaryConsumptionValue =
        emissionRepresentation.length === 2 && engineValuesLookup.size === 2
            ? engineValuesLookup.get(emissionRepresentation[1])
            : null;
    return [primaryConsumptionValue || '', secondaryConsumptionValue || null];
};

import { useI18n } from '@oneaudi/i18n-context';
export const usePKVEmissionLabels = (engineType) => {
    switch (engineType) {
        case 'PEV':
        case 'ICE':
        case 'NOVC_HEV':
            return useI18n({
                id: `nemo.ui.pkv.${engineType}.emission.label`,
                defaultMessage: `${decodeURIComponent('CO%E2%82%82')}-Emissionen (kombiniert)`,
            });
        case 'OVC_HEV':
            return useI18n({
                id: `nemo.ui.pkv.${engineType}.emission.weighted.label`,
                defaultMessage: `${decodeURIComponent('CO%E2%82%82')}-Emissionen (gewichtet kombiniert)`,
            });
        default:
            return '';
    }
};

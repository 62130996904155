import { useEffect, useState } from 'react';
import { useServicesContext } from '../../context';
export const useFormattedDate = (timestamp) => {
    var _a;
    const { audiMarketContextService, getConfiguration } = useServicesContext();
    const localeService = useServicesContext().getAdditionalService('gfa:locale-service');
    const { language, countryCode } = localeService;
    const datePattern = ((_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.datePattern) ||
        (audiMarketContextService === null || audiMarketContextService === void 0 ? void 0 : audiMarketContextService.getContextItem('stockcar.custom.date.pattern'));
    const [formattedDate, setFormattedDate] = useState();
    const INVALID_DATE_ERROR = 'Invalid Date';
    useEffect(() => {
        let formDate = '';
        if (Number.isNaN(timestamp)) {
            setFormattedDate(formDate);
            return;
        }
        try {
            const date = new Date(timestamp);
            formDate =
                date.toLocaleDateString(language) === INVALID_DATE_ERROR
                    ? ''
                    : date.toLocaleDateString(`${language}-${countryCode}`);
            if (date instanceof Date && !Number.isNaN(date.getTime())) {
                if (datePattern) {
                    let dd = date.getDate();
                    let mm = date.getMonth() + 1;
                    const yyyy = date.getFullYear().toString();
                    dd = dd < 10 ? `0${dd}` : dd;
                    mm = mm < 10 ? `0${mm}` : mm;
                    formDate = datePattern
                        .toLowerCase()
                        .replace('dd', dd)
                        .replace('mm', mm)
                        .replace('yyyy', yyyy);
                }
            }
        }
        catch (err) {
            setFormattedDate('');
            return;
        }
        setFormattedDate(formDate);
    }, [language, countryCode, timestamp, datePattern]);
    return formattedDate;
};

import { TestCycleTypesFromConfig, } from '@oneaudi/vtp-configuration-service';
import { getEmissionValueUnitTuple } from './vehicle';
export const getEmissionLabels = (vehicle, emissionRepresentation_, testCycleLabels) => {
    var _a, _b, _c;
    const emissionRepresentation = !Array.isArray(emissionRepresentation_) || !emissionRepresentation_.length
        ? [TestCycleTypesFromConfig.WLTP]
        : emissionRepresentation_;
    const fuels = ((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vlsEnergyProvision) === null || _a === void 0 ? void 0 : _a.hasWltp) || ((_b = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vlsEnergyProvision) === null || _b === void 0 ? void 0 : _b.hasNedc)
        ? (_c = vehicle === null || vehicle === void 0 ? void 0 : vehicle.vlsEnergyProvision) === null || _c === void 0 ? void 0 : _c.fuels
        : vehicle === null || vehicle === void 0 ? void 0 : vehicle.io.fuels;
    if (!fuels || !fuels.length) {
        return ['', null];
    }
    const engineFuel0 = fuels[0];
    const [wltpValue = '', wltpUnit = ''] = getEmissionValueUnitTuple(engineFuel0, TestCycleTypesFromConfig.WLTP);
    const hasWltp = wltpValue !== '';
    const wltpLabel = hasWltp && `${wltpValue} ${wltpUnit}`;
    const [nedcValue = '', nedcUnit = ''] = getEmissionValueUnitTuple(engineFuel0, TestCycleTypesFromConfig.NEDC);
    const hasNedc = nedcValue !== '';
    const nedcLabel = `${nedcValue} ${nedcUnit}`;
    const valueLookup = new Map();
    if (hasWltp) {
        valueLookup.set(TestCycleTypesFromConfig.WLTP, wltpLabel);
    }
    if (hasNedc && !vehicle.noNedc) {
        valueLookup.set(TestCycleTypesFromConfig.NEDC, nedcLabel);
    }
    let primaryEmissionValue = valueLookup.has(emissionRepresentation[0]) &&
        `${valueLookup.get(emissionRepresentation[0])} (${testCycleLabels[emissionRepresentation[0]]})`;
    const alternativeValue = emissionRepresentation[0] === TestCycleTypesFromConfig.WLTP
        ? TestCycleTypesFromConfig.NEDC
        : TestCycleTypesFromConfig.WLTP;
    let secondaryEmissionValue = valueLookup.has(alternativeValue) &&
        `${valueLookup.get(alternativeValue)} (${testCycleLabels[alternativeValue]})`;
    if (!primaryEmissionValue && secondaryEmissionValue) {
        primaryEmissionValue = secondaryEmissionValue;
        secondaryEmissionValue = null;
    }
    secondaryEmissionValue =
        emissionRepresentation.length === 2 && !!secondaryEmissionValue ? secondaryEmissionValue : null;
    return [primaryEmissionValue || '', secondaryEmissionValue || null];
};

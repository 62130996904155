import React from 'react';
import { getButtons } from '../../../ctaButtons/CTAHelper';
import { CTAButton } from '../../../ctaButtons/CTAButton';
import { useServicesContext } from '../../../../context';
const FinanceModalCTA = ({ vehicle, disabled }) => {
    var _a;
    const { getConfiguration } = useServicesContext();
    const vtpConfigCTAs = ((_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.cta)
        ? getConfiguration().cta
        : [];
    const linkMap = new Map([...vtpConfigCTAs].map((link) => [link.type, link]));
    const links = getButtons(vehicle, linkMap, true);
    const filteredLinks = links.filter((link) => ['contact', 'leasing', 'ecom'].includes(link.type));
    if (!filteredLinks.length) {
        return null;
    }
    const renderLink = (ctaLink) => {
        const link = Object.assign(Object.assign({}, ctaLink), { variant: 'secondary' });
        return React.createElement(CTAButton, { link: link, vehicle: vehicle, disabled: disabled });
    };
    return (React.createElement(React.Fragment, null, filteredLinks.map((linkConfiguration) => {
        return renderLink(linkConfiguration);
    })));
};
export default FinanceModalCTA;

import * as React from 'react';
import { FinancingAllInPricingElement } from './FinancingAllInPricingElement';
import { RateTemplate } from './static-financing/RateTemplate';
import { useServicesContext } from '../../context';
import { CRSFinanceElement } from './dynamic-finance/crs-element';
export const FinancingWrapper = ({ vehicle, fullVehicle, trackLayerLinkClick, trackFinInfoLayerClose, configureFinanceComponents, }) => {
    var _a;
    const vehicleBasic = vehicle || fullVehicle.basic;
    const { getConfiguration } = useServicesContext();
    const scopesFromConfig = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes;
    const hideAllInPricing = scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.hideAllInPricing;
    const rateTemplate = (React.createElement(RateTemplate, { vehicleBasic: vehicleBasic, trackLayerLinkClick: trackLayerLinkClick, trackFinInfoLayerClose: trackFinInfoLayerClose, configureFinanceComponents: configureFinanceComponents }));
    const [isClient, setIsClient] = React.useState(false);
    React.useEffect(() => {
        setIsClient(true);
    }, []);
    return (React.createElement(React.Fragment, null,
        !hideAllInPricing && React.createElement(FinancingAllInPricingElement, { vehicle: vehicle }),
        vehicleBasic.financing && (React.createElement(React.Fragment, null, fullVehicle && isClient ? (React.createElement(CRSFinanceElement, { fullVehicle: fullVehicle, trackLayerLinkClick: trackLayerLinkClick, rateTemplate: rateTemplate, configureFinanceComponents: configureFinanceComponents })) : (rateTemplate)))));
};

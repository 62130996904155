import * as React from 'react';
import { CoreCiIconStyled } from './CoreCiIconStyled';
export const ASSET_URL = 'https://assets.audi.com/audi-core-ci/13/assets/icons/svg/%FILE_NAME%.svg';
export const CoreCiIcon = ({ icon, size = 'small', margin = '0', rotation = '0', width = '0', height = '0', }) => {
    const svg = ASSET_URL.replace('%FILE_NAME%', icon);
    let svgWidth;
    let svgHeight;
    if (size === 'custom') {
        svgWidth = width;
        svgHeight = height;
    }
    else if (size === 'small') {
        svgWidth = '24px';
        svgHeight = '24px';
    }
    else {
        svgWidth = '48px';
        svgHeight = '48px';
    }
    return (React.createElement(CoreCiIconStyled, { size: size, backgroundImage: svg, margin: margin, rotation: rotation, width: svgWidth, height: svgHeight, "data-testid": "core-ci-icon", "data-icon": icon }));
};

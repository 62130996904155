export const getContextForDetailPage = (location = window.location) => {
    const [baseUrl, layerUrl = ''] = location.href.split('#layer=');
    const url = layerUrl.includes('sc_detail') ? `${location.origin}${layerUrl}` : baseUrl;
    const contextBaseUrl = url.split(/\.(sc_compare|sc_detail|html)/).shift();
    const [, market, brandOrWeb, language, stockIdentifier] = (layerUrl || location.pathname).split('/');
    return {
        contextServiceUrl: contextBaseUrl ? `${contextBaseUrl}.context.json` : '',
        contextId: market && brandOrWeb && language && stockIdentifier
            ? `${market}/${brandOrWeb}/${language}/${stockIdentifier.split('.').shift()}`
            : url,
    };
};

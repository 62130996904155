var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useSvd } from './useSvd';
import { useVehicleUrl } from './useVehicleUrl';
import { fetchVehicleRaw } from '../../utils';
export const useVehicleRaw = (vehicleId) => {
    const [vehicleRaw, setVehicleRaw] = useState();
    const { svd, svdError } = useSvd();
    const [error, setError] = useState();
    const vehicleRequestUrl = useVehicleUrl(vehicleId, svd);
    useEffect(() => {
        if (svdError) {
            setError(svdError);
        }
    }, [svdError]);
    useEffect(() => {
        const getVehicleRaw = (requestUrl) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const { vehicle } = yield fetchVehicleRaw(requestUrl);
                const item = vehicle;
                setVehicleRaw(item);
            }
            catch (err) {
                setError(err);
            }
        });
        if (!error && vehicleRequestUrl) {
            getVehicleRaw(vehicleRequestUrl);
        }
    }, [error, svd, vehicleRequestUrl]);
    return { vehicleRaw, error };
};

import {
  isTypedPicture,
  Picture,
  RenderedMarker,
  urlSeparatorFor,
  vtpImageUrlAdaption,
} from '@oneaudi/vtp-shared';
import React, { useEffect, useState } from 'react';
import { ImageContainer, StyledPicture, StyledImage } from './GalleryImage.styles';

export interface GalleryImageProps {
  picture: Picture;
  alt?: string;
  active?: boolean;
  // this prop is intended as a manual override in cases the native lazy loading does not bring the desired effect.
  // Picture that are not rendered initially will be loaded eagerly once this prop is set to true later
  renderPicture?: boolean;
}

const GalleryImage: React.FC<GalleryImageProps> = ({
  picture,
  alt,
  active,
  renderPicture: renderPictureFromProps = true,
}) => {
  const [renderPicture, setRenderPicture] = useState(renderPictureFromProps);
  const [loading, setLoading] = useState<'lazy' | 'eager'>('lazy');
  const urlSeparator = urlSeparatorFor(picture.url);

  const isMediaServiceImage = picture.url.indexOf('mediaservice') > -1;
  const isVtpImage = picture.url.indexOf('vtpimages') > -1;

  /* eslint-disable no-nested-ternary */
  const fallbackSrc = isMediaServiceImage
    ? `${picture.url + urlSeparator}wid=400&mimetype=image/webp`
    : isVtpImage
      ? vtpImageUrlAdaption(picture.url, '480', '360')
      : picture.url;
  /* eslint-enable no-nested-ternary */

  useEffect(() => {
    if (renderPictureFromProps && !renderPicture) {
      setRenderPicture(true);
      setLoading('eager');
    }
  }, [renderPictureFromProps]);

  return (
    <ImageContainer
      photoImage={isTypedPicture(picture) && picture.type !== 'render'}
      data-testid="imageContainer"
    >
      {renderPicture && (
        <StyledPicture>
          {isMediaServiceImage && (
            <>
              <source
                srcSet={`${picture.url + urlSeparator}wid=400&mimetype=image/webp 1x, ${
                  picture.url + urlSeparator
                }wid=600&mimetype=image/webp 2x`}
                media="(max-width: 567px)"
                type="image/webp"
              />
              <source
                srcSet={`${picture.url + urlSeparator}wid=400&mimetype=image/webp`}
                media="(min-width: 568px)"
                type="image/webp"
              />
            </>
          )}
          {isVtpImage && (
            <>
              <source
                srcSet={vtpImageUrlAdaption(picture.url, '600', '450')}
                media="(max-width: 567px)"
              />
              <source
                srcSet={vtpImageUrlAdaption(picture.url, '480', '360')}
                media="(min-width: 568px)"
              />
            </>
          )}
          <StyledImage src={fallbackSrc} alt={alt} data-testid="image" loading={loading} />
        </StyledPicture>
      )}
      {active && <RenderedMarker picture={picture} data-testid="renderedMarker" />}
    </ImageContainer>
  );
};

export default GalleryImage;

import * as React from 'react';
import { Text, PopoverBody, PopoverTrigger, Button, Popover, } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { FootnoteReference } from '../../footnotes/components/FootnoteReference';
import { DangerousElement } from '../../DangerousElement';
import { useI18n } from '@oneaudi/i18n-context';
import { messages } from '../../../i18n/messages';
import { Icon, iconSystemInfoSmall } from '../../icon';
import { VisuallyHidden } from '../../utilities';
export const PricesJapan = ({ vehicle, allInPrice, expenses, retailPrice, showPrices, retailPriceLabel, footnoteLabel, financeLabel, showNwsDisclaimer, availableSoon, expectedLabel, footnoteSuffix, }) => {
    const nwsDisclaimer = showNwsDisclaimer ? '<sup>*</sup>' : '';
    const vatReclaimableLabel = useI18n(messages.prices.vatReclaimable);
    const allInPriceLabel = useI18n(messages.prices.allIn);
    const allInPriceTooltip = useI18n(messages.prices.allInTooltipInfo);
    const allInPriceFallback = useI18n(messages.prices.allInFallback);
    const expensesLabel = useI18n(messages.prices.expenses);
    const expensesTooltip = useI18n(messages.prices.expensesTooltipInfo);
    const expensesFallback = useI18n(messages.prices.expensesFallback);
    const priceDisclaimerTooltip = useI18n({
        id: 'nemo.ui.sc.details.popover.price.disclaimer',
        defaultMessage: '',
    });
    const allInPriceLabelUI = [allInPriceLabel, allInPrice === null || allInPrice === void 0 ? void 0 : allInPrice.formatted]
        .filter((item) => !!item)
        .join(' ');
    const allInPriceFallBackUI = [allInPriceLabel, allInPriceFallback].join(' ');
    const expensesLabelUI = [expensesLabel, expenses === null || expenses === void 0 ? void 0 : expenses.formatted].filter((item) => !!item).join(' ');
    const expensesFallBackUI = [expensesLabel, expensesFallback].join(' ');
    const retailPriceLabelUI = [retailPriceLabel, retailPrice, nwsDisclaimer]
        .filter((item) => !!item)
        .join(' ');
    if (!showPrices && !retailPrice) {
        return null;
    }
    const environmentTax = vehicle.typedPrices.find((typedPrice) => {
        return typedPrice.type === 'environment_tax';
    });
    const financeRate = vehicle.typedPrices.find((typedPrice) => {
        return typedPrice.type === 'finance_rate';
    });
    const downPayment = vehicle.typedPrices.find((typedPrice) => {
        return typedPrice.type === 'down_payment';
    });
    return (React.createElement(React.Fragment, null,
        retailPrice && availableSoon && (React.createElement(Text, { variant: "copy1", weight: "bold", as: "span" },
            expectedLabel,
            React.createElement(FootnoteReference, { refId: "fn_expected_price" }),
            ":\u00A0")),
        React.createElement(React.Fragment, null,
            React.createElement(Text, { variant: "copy1", weight: "bold", spaceStackEnd: "xs" },
                React.createElement(DangerousElement, { html: (allInPrice === null || allInPrice === void 0 ? void 0 : allInPrice.formatted) ? allInPriceLabelUI : allInPriceFallBackUI, as: "span" }),
                React.createElement(PopoverTrigger, null, (triggerProps) => (React.createElement(React.Fragment, null,
                    React.createElement(Button, Object.assign({ spaceInlineStart: "s", variant: "text" }, triggerProps, { icon: React.createElement(Icon, { icon: iconSystemInfoSmall }) })),
                    React.createElement(StyledInfoText, { "aria-label": allInPriceTooltip },
                        React.createElement(PopoverBody, null,
                            React.createElement(Text, null,
                                React.createElement(DangerousElement, { html: allInPriceTooltip })))))))),
            React.createElement(Text, { variant: "copy1", weight: "normal" },
                React.createElement(DangerousElement, { html: (expenses === null || expenses === void 0 ? void 0 : expenses.formatted) ? `${expensesLabelUI} +` : expensesFallBackUI, as: "span" }),
                React.createElement(PopoverTrigger, null, (triggerProps) => (React.createElement(React.Fragment, null,
                    React.createElement(Button, Object.assign({ spaceInlineStart: "l", variant: "text" }, triggerProps, { icon: React.createElement(Icon, { icon: iconSystemInfoSmall }) })),
                    React.createElement(StyledInfoText, { "aria-label": expensesTooltip },
                        React.createElement(PopoverBody, null,
                            React.createElement(Text, null,
                                React.createElement(DangerousElement, { html: expensesTooltip }))))))))),
        retailPrice && (React.createElement(React.Fragment, null,
            React.createElement(Text, { variant: "copy1", weight: "normal", spaceStackEnd: "xs", as: "span", "data-testid": "retail-price-label" },
                React.createElement(DangerousElement, { html: retailPriceLabelUI, as: "span" })),
            React.createElement(VisuallyHidden, { "data-testid": "hidden-fn-pricefn-jp" },
                React.createElement(FootnoteReference, { refId: "fn_price" })),
            React.createElement(PopoverTrigger, null, (triggerProps) => (React.createElement(React.Fragment, null,
                React.createElement(Button, Object.assign({ spaceInlineStart: "l", variant: "text" }, triggerProps, { icon: React.createElement(Icon, { icon: iconSystemInfoSmall }) })),
                React.createElement(StyledInfoText, { "aria-label": priceDisclaimerTooltip },
                    React.createElement(PopoverBody, null,
                        React.createElement(Text, null,
                            React.createElement(DangerousElement, { html: priceDisclaimerTooltip }))))))),
            React.createElement(Text, { variant: "copy1", as: "span" },
                React.createElement(FootnoteReference, { refId: "fn_retail_price" })),
            footnoteSuffix && (React.createElement(Text, { variant: "copy1", as: "span" },
                React.createElement(FootnoteReference, { refId: `fn_price_retail${footnoteSuffix}` }))),
            financeLabel && financeLabel !== ' ' && (React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" }, financeLabel)),
            vehicle.vatReclaimable && (React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" },
                "(",
                vatReclaimableLabel,
                ")")),
            environmentTax && (React.createElement(React.Fragment, null,
                React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" }, useI18n(messages.prices.environmentTax.prefix)),
                React.createElement(Text, { variant: "copy1", weight: "bold", spaceStackEnd: "xs", as: "span" }, environmentTax.formatted),
                React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" }, useI18n(messages.prices.environmentTax.suffix)))))),
        financeRate && (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                retailPrice && (React.createElement(Text, { variant: "copy1", as: "span" }, useI18n(messages.prices.financeRate.prefix))),
                React.createElement(Text, { variant: "copy1", weight: "bold", spaceStackEnd: "xs", as: "span" }, financeRate.formatted),
                React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" }, useI18n(messages.prices.financeRate.suffix)),
                downPayment && (React.createElement(React.Fragment, null,
                    React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" }, useI18n(messages.prices.downPayment.prefix)),
                    React.createElement(Text, { variant: "copy1", weight: "bold", spaceStackEnd: "xs", as: "span" }, downPayment.formatted),
                    React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" }, useI18n(messages.prices.downPayment.suffix))))),
            !retailPrice && environmentTax && (React.createElement("div", null,
                React.createElement(Text, { variant: "copy1", as: "span" }, useI18n(messages.prices.environmentTax.prefix)),
                React.createElement(Text, { variant: "copy1", weight: "bold", spaceStackEnd: "xs", as: "span" }, environmentTax.formatted),
                React.createElement(Text, { variant: "copy1", as: "span", spaceInlineStart: "xxs" }, useI18n(messages.prices.environmentTax.suffix)))))),
        React.createElement(Text, { variant: "copy2", as: "span", spaceInlineStart: "xxs" }, footnoteLabel)));
};
export const StyledInfoText = styled((props) => React.createElement(Popover, Object.assign({}, props))) `
  background-color: #ffffff;
`;

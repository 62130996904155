import React, { createContext, useContext } from 'react';
const FootnoteServiceContext = createContext(null);
export const FootnoteServiceContextProvider = ({ footnoteService, children, }) => {
    return (React.createElement(FootnoteServiceContext.Provider, { value: { footnoteService } }, children));
};
export const useFootnoteService = () => {
    const { footnoteService } = useContext(FootnoteServiceContext);
    if (footnoteService === undefined) {
        throw new Error('useFootnoteReferenceState must be used within a FootnoteReferenceContextProvider');
    }
    return footnoteService;
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIONS, SELECTORS } from '@oneaudi/stck-store';
import { useSvd } from './useSvd';
import { useVehicleUrl } from './useVehicleUrl';
import { fetchVehicleRaw } from '../../utils';
export const useVehicle = (vehicleId) => {
    const storedVehicle = useSelector((state) => { var _a; return (_a = SELECTORS === null || SELECTORS === void 0 ? void 0 : SELECTORS.VEHICLES) === null || _a === void 0 ? void 0 : _a.getVehiclesMap(state).get(vehicleId); });
    const [vehicle, setVehicle] = useState(storedVehicle);
    const { svd, svdError } = useSvd();
    const [error, setError] = useState(svdError);
    const vehicleRequestUrl = useVehicleUrl(vehicleId, svd);
    const dispatch = useDispatch();
    const vehicleIsPending = useSelector((state) => SELECTORS.PENDING_IDS.isPending(state, vehicleId));
    useEffect(() => {
        setVehicle(storedVehicle);
    }, [storedVehicle]);
    useEffect(() => {
        if (svdError) {
            setError(svdError);
        }
    }, [svdError]);
    useEffect(() => {
        const getAndStoreVehicle = (url) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                dispatch(ACTIONS.PENDING_IDS.addPendingID({ id: vehicleId }));
                const { vehicle: vehicleRaw } = yield fetchVehicleRaw(url);
                const item = vehicleRaw.basic;
                setVehicle(item);
                dispatch(ACTIONS.VEHICLES.setVehicles({ vehicles: [item] }));
            }
            catch (err) {
                setError(err);
            }
            finally {
                dispatch(ACTIONS.PENDING_IDS.removePendingID({ id: vehicleId }));
            }
        });
        if (!error && !storedVehicle && vehicleRequestUrl && !vehicleIsPending) {
            getAndStoreVehicle(vehicleRequestUrl);
        }
    }, [error, dispatch, storedVehicle, vehicleRequestUrl, vehicleIsPending, vehicleId]);
    if (typeof window === 'undefined') {
        fetchVehicleRaw(vehicleRequestUrl).then((response) => {
            return response.vehicle.basic;
        });
    }
    return { vehicle, error };
};

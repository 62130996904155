import React from 'react';
import { Button } from '@audi/audi-ui-react-v2';
import { useServicesContext } from '../../context';
import { DealerChain } from './DealerChain';
import { useChainDealers } from '../../hooks/api/useChainDealers';
export const DealerChainInfoButton = ({ label, vehicleId, onClick, children, }) => {
    const layerManager = useServicesContext().getAdditionalService('gfa:layer-manager');
    const { dealers, error, dealersHeadline } = useChainDealers(vehicleId);
    const openFocusLayer = () => {
        layerManager === null || layerManager === void 0 ? void 0 : layerManager.openFocusLayer(() => React.createElement(DealerChain, { dealers: error ? [] : dealers, dealersHeadline: dealersHeadline }), {}, {
            size: "A",
            userCloseable: true,
        });
        onClick === null || onClick === void 0 ? void 0 : onClick();
    };
    return (React.createElement(React.Fragment, null, vehicleId ? (React.createElement(Button, { variant: "text", size: "medium", className: "js-modal-layer-link sc-js-eec-link", "data-layer-type": "ModalDealerChainLayerElement", "data-id": vehicleId, onClick: openFocusLayer, "data-testid": "dealerchainlayer" },
        label,
        children)) : null));
};

export const initializationPromises = (promises) => {
    const state = {
        initialized: false,
        initializedArr: [],
        error: null,
    };
    const promise = !promises.length
        ?
            Promise.reject('No Promises')
        : Promise.all([...promises]);
    promise
        .then((arr) => {
        state.initializedArr = arr;
        state.initialized = true;
    })
        .catch((err) => {
        state.error = err;
        throw err;
    });
    return {
        state,
        featureAppInitializationPromise: promise,
    };
};

import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Text, LayoutItem } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { FootnoteReference } from '../../../footnotes/components/FootnoteReference';
import { FinanceLayoutHelper } from '../../static-financing/FinanceLayoutHelper';
import { FinanceInfoI } from '../../static-financing/FinanceInfoI';
import { messages } from '../../../../i18n/messages';
import { RateLayouts } from '../../../../interfaces';
import { useFormattedPrice } from '../../../../hooks';
import { useServicesContext } from '../../../../context';
const RateWrapper = styled.div `
  align-items: flex-start;
  display: flex;
  flex-grow: 1;
  margin: ${(props) => props.layout === RateLayouts.MATCHING_LAYOUT
    ? `0 calc(var(${props.theme.responsive.spacing.xs}) * -1)`
    : '0'};
`;
export const RateUK = ({ vehicleId, financing, layout = RateLayouts.DEFAULT_LAYOUT, trackLayerLinkClick, trackFinInfoLayerClose, configureFinanceComponents, }) => {
    var _a, _b, _c;
    const rate = useMemo(() => { var _a; return (_a = financing === null || financing === void 0 ? void 0 : financing.parameters) === null || _a === void 0 ? void 0 : _a.find((param) => param.id === 'Rate'); }, [financing]);
    const formattedPrice = useFormattedPrice(rate === null || rate === void 0 ? void 0 : rate.formattedValue, rate === null || rate === void 0 ? void 0 : rate.unitSymbol, rate === null || rate === void 0 ? void 0 : rate.unit);
    const beforeLabel = useI18n(messages.financeElement.rate.beforeLabel);
    let afterLabel = useI18n(messages.financeElement.rate.afterLabel);
    afterLabel = afterLabel ? `${afterLabel} ` : '';
    const { getConfiguration } = useServicesContext();
    const scopesFromConfig = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes;
    const showShortDisclaimer = (scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.financeOption) === 'STATIC_WITH_DISCLAIMER';
    const showSpecialShortDisclaimer = (scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.financeOption) === 'FULL';
    const makeItBold = configureFinanceComponents.hideFinanceDisclaimer ? 'bold' : 'normal';
    const hasInfoI = (financing === null || financing === void 0 ? void 0 : financing.parameters) && ((_b = financing === null || financing === void 0 ? void 0 : financing.product) === null || _b === void 0 ? void 0 : _b.label);
    return (React.createElement(React.Fragment, null, !showShortDisclaimer && (React.createElement(RateWrapper, { layout: layout },
        hasInfoI && layout === RateLayouts.MATCHING_LAYOUT && React.createElement(FinanceLayoutHelper, null),
        React.createElement("div", null,
            React.createElement(Text, { as: "span", variant: "copy2", weight: makeItBold }, beforeLabel),
            React.createElement(Text, { as: "span", variant: "copy2", weight: makeItBold },
                "\u00A0",
                formattedPrice,
                React.createElement(FootnoteReference, { refId: "fn_rate" }),
                "\u00A0"),
            React.createElement(Text, { as: "span", variant: "copy2", weight: makeItBold },
                afterLabel, (_c = financing === null || financing === void 0 ? void 0 : financing.product) === null || _c === void 0 ? void 0 :
                _c.label)),
        ((hasInfoI && !showShortDisclaimer && !showSpecialShortDisclaimer) ||
            configureFinanceComponents.hideFinanceDisclaimer) && (React.createElement(LayoutItem, { spaceStackStart: "xxs" },
            React.createElement(FinanceInfoI, { vehicleId: vehicleId, financing: financing, trackLayerLinkClick: trackLayerLinkClick, trackFinInfoLayerClose: trackFinInfoLayerClose })))))));
};

import React from 'react';
import { Button } from '@audi/audi-ui-react-v2';
export const PhoneButton = ({ buttonInfo, variant, buttonSize, appContext, phoneWithNumber, trackButtonClick, isStrechedButton, showAsText, }) => {
    const { label, target, url, type } = buttonInfo;
    let phoneButtonLabel = label;
    if (buttonInfo.type === 'phone' && appContext === 'tiles' && phoneWithNumber) {
        phoneButtonLabel = `${buttonInfo.label} ${url}`;
    }
    return (React.createElement(Button, { spaceStackEnd: showAsText ? 'm' : 'xxs', spaceInlineEnd: showAsText ? 'xxl' : 'xxs', size: buttonSize, variant: showAsText ? 'text' : variant, href: `tel://${url}`, target: target, "data-testid": `${type}-button-variant-${variant}`, stretch: isStrechedButton, onClick: () => {
            trackButtonClick(buttonInfo, showAsText ? 'text' : variant);
        } }, phoneButtonLabel));
};

export function getPriceFormatted(value, unit = '', textUnit = '', pattern) {
    if (value && pattern) {
        switch (pattern) {
            case '{{currency}}{{price}}':
                return `${unit !== '' ? unit : textUnit} ${value}`;
            case '{{price}}{{currency}}':
                return `${value} ${unit !== '' ? unit : textUnit}`;
            case '{{currency_text}}{{price}}':
                return `${textUnit !== '' ? textUnit : unit} ${value}`;
            case '{{price}}{{currency_text}}':
                return `${value} ${textUnit !== '' ? textUnit : unit}`;
            default:
                const displayTextUnit = textUnit !== '' ? textUnit : '';
                const displayUnit = unit !== '' ? unit : displayTextUnit;
                return `${value || ''} ${displayUnit}`;
        }
    }
    else {
        return '';
    }
}

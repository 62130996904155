import React from 'react';
import { Text, Button } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { AppContextForThisInstance } from '../../@types/editor.d';
import { ACTIONS, SELECTORS, StockcarsStoreClass } from '@oneaudi/stck-store';
import { useDispatch } from 'react-redux';
import {
  useServicesContext,
  useSvd,
  generateFilterRequestParam,
  fetchResults,
} from '@oneaudi/vtp-shared';

interface ZeroResultsProps {
  context: AppContextForThisInstance;
  newSearchUrl?: string;
}

const ZeroResultsPage: React.FC<ZeroResultsProps> = ({
  context,
  newSearchUrl,
}: ZeroResultsProps) => {
  const zeroResultsHeadline = useI18n({
    id: 'nemo.ui.sc.result.noresultstext',
    defaultMessage: '',
  });
  const resetFiltersLabel = useI18n({
    id: 'nemo.ui.sc.filter-options.reset.label',
    defaultMessage: '',
  });
  const zeroFavsHeadline = useI18n({
    id: 'nemo.ui.sc.favorites.empty.headline',
    defaultMessage: '',
  });

  const zeroFavsInfo = useI18n({
    id: 'nemo.ui.sc.favorites.empty.copy',
    defaultMessage: '',
  });
  const newSearchLabel = useI18n({
    id: 'nemo.ui.sc.favorites.empty.newsearch',
    defaultMessage: '',
  });
  const dispatch = useDispatch();
  const { getEnvironmentConfig, getConfiguration } = useServicesContext();
  const svd = useSvd();

  const store = StockcarsStoreClass.getInstance('', '');
  const presets = SELECTORS.FILTERS.getPersistedPresetsMap(store.state);
  const sorting = SELECTORS.SORTING.getSortingStateRaw(store.state);

  const requestParam = generateFilterRequestParam(new Map(), presets);
  requestParam.set('size', 12);
  requestParam.set('sort', sorting.results);
  requestParam.set('svd', svd);

  const clearAllFilters = async () => {
    const response = await fetchResults(
      getEnvironmentConfig()!.scs.baseUrl,
      getConfiguration()!.scs!.scsMarketPath!,
      requestParam
    );
    dispatch(ACTIONS.FILTERS.handleFilterResponse({ response }));
    // clean the legacy filter chips
    document.querySelector('.sc-md-filter-options')?.remove();
  };

  const headline = context === 'favorites' ? zeroFavsHeadline : zeroResultsHeadline;
  return (
    <>
      <Text as="p" variant="copy2" spaceStackEnd="m" data-testid="zeroResultsHeadline">
        {headline}
      </Text>
      {context === 'results' && (
        <Button
          variant="primary"
          newWindow
          size="medium"
          spaceStackEnd="xxl"
          onClick={clearAllFilters}
          data-testid="reset"
        >
          {resetFiltersLabel}
        </Button>
      )}
      {context === 'favorites' && (
        <>
          <Text as="p" variant="copy2" spaceStackEnd="m" data-testid="zeroFavsHeadline">
            {zeroFavsInfo}
          </Text>
          <Button variant="primary" href={newSearchUrl} size="medium" spaceStackEnd="xxl">
            {newSearchLabel}
          </Button>
        </>
      )}
    </>
  );
};

export default ZeroResultsPage;

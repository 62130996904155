import React from 'react';
import { LayoutItem, Text } from '@audi/audi-ui-react-v2';
import Tile from './Tile';
import { useServicesContext, Slider } from '@oneaudi/vtp-shared';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { trackSliderChange } from './tracking';
import VehicleScoreDisclaimer from './VehicleScoreDisclaimer';
import { SimilarVehiclesState, SuggestedCarsTextKeys } from '../hooks/useSuggestedCars';

export interface SuggestedSliderProps {
  vehicles: SimilarVehiclesState[] | string[];
  tabText: string;
  tabId: string;
  showSlider: boolean;
  isMobile: boolean;
  textKeys: SuggestedCarsTextKeys;
}

const SuggestedSlider: React.FC<SuggestedSliderProps> = ({
  vehicles,
  tabId,
  tabText,
  showSlider,
  isMobile,
  textKeys,
}) => {
  const hasVehicles = vehicles.length > 0;
  const showTab = isMobile || showSlider;
  const tiles = vehicles;
  const trackingService = useServicesContext().getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;
  const onChangeHandler = (elementName: 'text link' | 'arrow') => {
    trackSliderChange(trackingService, elementName);
  };

  return hasVehicles && showTab ? (
    <LayoutItem spaceStackEnd="xxl">
      {isMobile && (
        <>
          <Text variant="order4" as="h4" spaceStackEnd="l" spaceStackStart="xxl">
            {tabText}
          </Text>
          <VehicleScoreDisclaimer tabId={tabId} isMobile textKeys={textKeys} />
        </>
      )}
      <Slider onChangeHandler={onChangeHandler} isSuggestedCars>
        {tiles &&
          tiles.map((vehicle) => {
            return typeof vehicle !== 'string' ? (
              <Tile
                key={`${vehicle.vehicle.id}-suggested`}
                vehicleId={vehicle.vehicle.id}
                matchingScore={vehicle.matchingScore}
              />
            ) : (
              <Tile key={`${vehicle}-last-visited`} vehicleId={vehicle} />
            );
          })}
      </Slider>
    </LayoutItem>
  ) : null;
};
export default SuggestedSlider;

import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  margin-bottom: var(${(props): string => props.theme.responsive.spacing.xxl});
`;

export const LoaderContainer = styled.div`
  margin-bottom: var(${(props): string => props.theme.responsive.spacing.xxl});
`;

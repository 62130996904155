import React from 'react';
import { getConsumptionValueUnitTuple, getEmissionValueUnitTuple } from '../../utils';
import { Text } from '@audi/audi-ui-react-v2';
import { useCO2ClassLabels, usePKVConsumptionLabels, usePKVEmissionLabels } from '../../hooks';
import { TestCycleTypesFromConfig } from '@oneaudi/vtp-configuration-service';
export const EnvKConsumptionAndEmissions = ({ vehicle, }) => {
    var _a, _b, _c, _d;
    if (!vehicle.envkv2024) {
        return null;
    }
    const engineType = vehicle.io.engineType;
    const [consumptionValue, consumptionUnit] = getConsumptionValueUnitTuple(vehicle.io.fuels[0], TestCycleTypesFromConfig.WLTP);
    const [electricConsumptionValue, electricConsumptionUnit] = getConsumptionValueUnitTuple(vehicle.io.fuels[1], TestCycleTypesFromConfig.WLTP);
    const consumptionLabel = usePKVConsumptionLabels(engineType);
    const emptyBatteryConsumptionValue = (_b = (_a = vehicle.io.fuels[0].consumption.wltp.dataSets) === null || _a === void 0 ? void 0 : _a.find((dataSet) => dataSet.em === 'SUSTAINING')) === null || _b === void 0 ? void 0 : _b.values.combined;
    const [emissionValue, emissionUnit] = getEmissionValueUnitTuple(vehicle.io.fuels[0], TestCycleTypesFromConfig.WLTP);
    const emissionLabel = usePKVEmissionLabels(engineType);
    const co2ClassLabel = useCO2ClassLabels(engineType);
    const co2Class = vehicle.envkv2024.find((element) => element.key === 'co2Class');
    const co2ClassValue = (_c = co2Class.values.find((element) => element.key === 'combined')) === null || _c === void 0 ? void 0 : _c.value;
    const emptyBatteryCo2ClassValue = (_d = co2Class.values.find((element) => element.key === 'emptyBattery')) === null || _d === void 0 ? void 0 : _d.value;
    let EnvKvDisclaimer;
    switch (engineType) {
        case 'OVC_HEV':
            const [hybridConsumptionLabel, electricConsumptionLabel, fuelConsumptionEmptyBatteryLabel] = consumptionLabel;
            const [hybridCo2ClassLabel, co2ClassEmptyBatteryLabel] = co2ClassLabel;
            EnvKvDisclaimer = `${hybridConsumptionLabel}: ${consumptionValue} ${consumptionUnit}; ${electricConsumptionLabel}: ${electricConsumptionValue} ${electricConsumptionUnit}; ${emissionLabel}: ${emissionValue} ${emissionUnit}; ${hybridCo2ClassLabel}: ${co2ClassValue}; ${fuelConsumptionEmptyBatteryLabel}: ${emptyBatteryConsumptionValue} ${consumptionUnit};  ${co2ClassEmptyBatteryLabel}: ${emptyBatteryCo2ClassValue}`;
            break;
        default:
            EnvKvDisclaimer = `${consumptionLabel}: ${consumptionValue} ${consumptionUnit}; ${emissionLabel}: ${emissionValue} ${emissionUnit}; ${co2ClassLabel}: ${co2ClassValue}`;
            break;
    }
    return (React.createElement(Text, { variant: "copy2", as: "p", spaceStackStart: "s", "data-testid": "envkvDisclaimer" }, EnvKvDisclaimer));
};

import { getNestedObject } from './objectServices';
class CrsFinanceModel {
    getCrsModelData(financeData_ = {}, vehicleId_ = '') {
        const data = {};
        if (!!financeData_ && !!vehicleId_) {
            try {
                data.domain = financeData_.domain;
                data.id = vehicleId_;
                CrsFinanceModel.getProductData(data, financeData_);
                if (financeData_ && financeData_.vehicle && vehicleId_) {
                    const vehicleData = Object.assign(Object.assign({}, financeData_.vehicle), { id: vehicleId_ });
                    data.Vehicle = CrsFinanceModel.getVehicleData(vehicleData);
                }
                if (financeData_ && financeData_.dealer) {
                    data.Dealer = CrsFinanceModel.getDealerData(financeData_.dealer);
                }
            }
            catch (err) {
                console.error(err);
            }
        }
        return data;
    }
    static getDealerData(dealerData_ = {}) {
        const data = {};
        if (dealerData_.country) {
            data.Country = dealerData_.country;
        }
        if (dealerData_.id) {
            data.ID = dealerData_.id;
        }
        return data;
    }
    static getProductData(financeData = {}, parsedData_ = {}) {
        const productId = getNestedObject(parsedData_, ['product', 'id']);
        if (productId) {
            financeData.Product = {};
            financeData.Product['@ID'] = productId;
        }
    }
    static generateVehicleData(vehcileKeysArray_ = [], vehicleData_ = {}) {
        const data = {};
        vehcileKeysArray_.forEach((item) => {
            if (vehicleData_[item.dataKey]) {
                data[item.key] = vehicleData_[item.dataKey];
            }
        });
        return data;
    }
    static getVehicleDataKeysArray(type_ = 'defaultFields') {
        if (type_ === 'defaultFields') {
            return [
                { key: '@Type', dataKey: 'type' },
                { key: 'Key', dataKey: 'key' },
                { key: 'Brand', dataKey: 'brand' },
                { key: 'FiscalPower', dataKey: 'fiscalPower' },
                { key: 'Year', dataKey: 'year' },
                { key: 'PriceTotal', dataKey: 'priceTotal' },
                { key: 'PriceModel', dataKey: 'priceModel' },
                { key: 'PriceOriginal', dataKey: 'priceOriginal' },
                { key: 'PreviousUsage', dataKey: 'previousUsage' },
                { key: 'Id', dataKey: 'id' },
            ];
        }
        if (type_ === 'usedFields') {
            return [
                { key: 'Mileage', dataKey: 'mileage' },
                { key: 'RegDateFirst', dataKey: 'regDateFirst' },
                { key: 'RegDatePolicyHolder', dataKey: 'regDatePolicyHolder' },
            ];
        }
        return [];
    }
    static getVehicleData(vehicleData_ = {}) {
        const vehicleObject = CrsFinanceModel.generateVehicleData(CrsFinanceModel.getVehicleDataKeysArray(), vehicleData_);
        const vehicleUsedObject = vehicleData_.type === 'Used'
            ? CrsFinanceModel.generateVehicleData(CrsFinanceModel.getVehicleDataKeysArray('usedFields'), vehicleData_)
            : {};
        const data = Object.assign(Object.assign({}, vehicleObject), vehicleUsedObject);
        if (vehicleData_.customFields && vehicleData_.customFields.length) {
            CrsFinanceModel.getVehicleDataFromCustomFields(vehicleData_, data);
        }
        if (vehicleData_.options && vehicleData_.options.length) {
            data.Options = {};
            data.Options.Option = CrsFinanceModel.getVehicleOptions(vehicleData_.options);
        }
        return data;
    }
    static getVehicleOptions(options_ = []) {
        const data = [];
        options_.forEach((option) => {
            const optionData = {};
            if (option.code) {
                optionData['@Code'] = option.code;
            }
            if (option.type) {
                optionData['@Type'] = option.type;
            }
            if (option.family) {
                optionData['@Family'] = option.family;
            }
            if (option.price) {
                optionData['@Price'] = option.price;
            }
            data.push(optionData);
        });
        return data;
    }
    static getVehicleDataFromCustomFields(vehicleData_ = {}, financeData_ = {}) {
        const hsn = CrsFinanceModel.getDataFromCustomField(vehicleData_.customFields, 'HSN');
        if (hsn) {
            financeData_.HSN = hsn;
        }
        const tsn = CrsFinanceModel.getDataFromCustomField(vehicleData_.customFields, 'TSN');
        if (tsn) {
            financeData_.TSN = tsn;
        }
        const flags = CrsFinanceModel.getDataFromCustomField(vehicleData_.customFields, 'Flags');
        if (flags) {
            financeData_.Flags = flags;
        }
        CrsFinanceModel.extractOtherCustomFields(vehicleData_.customFields, financeData_);
    }
    static getDataFromCustomField(customFields_ = [], key_ = '') {
        let value = '';
        customFields_.forEach((item) => {
            if (item.key === key_) {
                value = item.value;
            }
        });
        return value;
    }
    static extractOtherCustomFields(customFields_, financeData_) {
        customFields_.forEach((item) => {
            if (item.key !== 'HSN' && item.key !== 'TSN' && item.key !== 'Flags') {
                financeData_[item.key] = item.value;
            }
        });
    }
}
const crsFinanceModel = new CrsFinanceModel();
export { crsFinanceModel, CrsFinanceModel };

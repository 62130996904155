import React from 'react';
import styled from 'styled-components';
import { LayoutItem, Text } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { messages } from '../../../i18n/messages';
import { FinanceLayoutHelper } from './FinanceLayoutHelper';
import { FinanceInfoI } from './FinanceInfoI';
import { RateLayouts } from '../../../interfaces';
import { useServicesContext } from '../../../context';
import { DangerousElement } from '../../DangerousElement';
const FinanceDisclaimerWrapper = styled.div `
  align-items: flex-start;
  display: flex;
  flex-grow: 1;
  margin: ${(props) => props.layout === RateLayouts.MATCHING_LAYOUT
    ? `0 calc(var(${props.theme.responsive.spacing.xs}) * -1)`
    : '0'};
`;
const StyledCalculationDisclaimer = styled((props) => React.createElement(Text, Object.assign({ as: "div", variant: "copy2" }, props))) `
  color: var(${(props) => props.theme.colors.base.grey[60]});
  display: block;
`;
export const FinanceDisclaimer = ({ vehicleId, financing, layout = RateLayouts.DEFAULT_LAYOUT, trackLayerLinkClick, trackFinInfoLayerClose, hideInfoIcon, }) => {
    var _a, _b, _c, _d;
    const { getConfiguration } = useServicesContext();
    const scopesFromConfig = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes;
    const showShortDisclaimer = (scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.financeOption) === 'STATIC_WITH_DISCLAIMER';
    const showSpecialShortDisclaimer = (scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.financeOption) === 'FULL';
    const disclaimerText = useI18n(messages.financeElement.disclaimerText);
    const interpretDisclaimersTextStyle = scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.interpretDisclaimersTextStyle;
    const hasInfoI = (financing === null || financing === void 0 ? void 0 : financing.parameters) && ((_b = financing === null || financing === void 0 ? void 0 : financing.product) === null || _b === void 0 ? void 0 : _b.label) && !hideInfoIcon;
    if (!showSpecialShortDisclaimer && !showShortDisclaimer) {
        return null;
    }
    if (showSpecialShortDisclaimer) {
        const topSpacing = layout === RateLayouts.MATCHING_LAYOUT ? 'm' : 'xs';
        return (React.createElement(React.Fragment, null,
            React.createElement(FinanceDisclaimerWrapper, { layout: layout },
                hasInfoI && layout === RateLayouts.MATCHING_LAYOUT && React.createElement(FinanceLayoutHelper, null),
                React.createElement(StyledCalculationDisclaimer, { spaceStackStart: topSpacing, className: "sc-car-tile-special-short-disclaimer sc-hypenate", "data-testid": "CalculationDisclaimer" },
                    !interpretDisclaimersTextStyle &&
                        ((_c = financing === null || financing === void 0 ? void 0 : financing.calculationDisclaimer) === null || _c === void 0 ? void 0 : _c.replace(/<\/?[^>]+(>|$)/g, '')),
                    interpretDisclaimersTextStyle && (React.createElement(DangerousElement, { html: financing === null || financing === void 0 ? void 0 : financing.calculationDisclaimer, sanitize: false }))),
                hasInfoI && (React.createElement(LayoutItem, { spaceStackStart: topSpacing },
                    React.createElement(FinanceInfoI, { vehicleId: vehicleId, financing: financing, trackLayerLinkClick: trackLayerLinkClick, trackFinInfoLayerClose: trackFinInfoLayerClose }))))));
    }
    return (React.createElement(StyledCalculationDisclaimer, { className: "sc-car-tile-rate" },
        disclaimerText,
        !interpretDisclaimersTextStyle &&
            ((_d = financing.calculationDisclaimer) === null || _d === void 0 ? void 0 : _d.replace(/<\/?[^>]+(>|$)/g, '')),
        interpretDisclaimersTextStyle && (React.createElement(DangerousElement, { html: financing.calculationDisclaimer, sanitize: false }))));
};

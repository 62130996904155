import React, { useMemo } from 'react';
import { Text } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { messages } from '../../../../i18n/messages';
import { RateLayouts } from '../../../../interfaces';
import { useFormattedPrice } from '../../../../hooks';
import { useServicesContext } from '../../../../context';
import { FinanceDisclaimer } from '../../static-financing/FinanceDisclaimer';
export const RateES = ({ vehicleId, financing, trackLayerLinkClick, trackFinInfoLayerClose, }) => {
    var _a;
    const rate = useMemo(() => { var _a; return (_a = financing === null || financing === void 0 ? void 0 : financing.parameters) === null || _a === void 0 ? void 0 : _a.find((param) => param.id === 'Rate'); }, [financing]);
    const formattedPrice = useFormattedPrice(rate === null || rate === void 0 ? void 0 : rate.formattedValue, rate === null || rate === void 0 ? void 0 : rate.unitSymbol, rate === null || rate === void 0 ? void 0 : rate.unit);
    const beforeLabel = useI18n(messages.financeElement.rate.beforeLabel);
    let afterLabel = useI18n(messages.financeElement.rate.afterLabel);
    const { getConfiguration } = useServicesContext();
    const scopesFromConfig = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes;
    const showShortDisclaimer = (scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.financeOption) === 'STATIC_WITH_DISCLAIMER';
    afterLabel = afterLabel ? `${afterLabel}` : '';
    return (React.createElement(React.Fragment, null,
        !showShortDisclaimer && (React.createElement(Text, { as: "span", "data-testid": "RateLabel", variant: "copy1" },
            beforeLabel,
            React.createElement(Text, { as: "span", "data-testid": "Rate", variant: "copy1", weight: "bold" },
                "\u00A0",
                formattedPrice),
            afterLabel)),
        React.createElement(FinanceDisclaimer, { vehicleId: vehicleId, financing: financing, layout: RateLayouts.DEFAULT_LAYOUT, trackLayerLinkClick: trackLayerLinkClick, trackFinInfoLayerClose: trackFinInfoLayerClose })));
};

import React from 'react';
import { Divider, SkeletonShape } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import { CountAndSortContainer, ResultsBarContainer } from '../ResultsBar.styles';
import { ListTilesContainer } from '../Tiles.styles';
import { TileSkeleton } from './TileSkeleton';

const ResultsBar = styled.div`
  display: flex;
`;

interface Props {}

export const FavoritesSkeleton: React.FC<Props> = () => {
  return (
    <>
      <ResultsBarContainer>
        <CountAndSortContainer>
          <SkeletonShape variant="rectangular" height="32px" width="370px" spaceStackEnd="l" />
          <SkeletonShape variant="rectangular" height="20px" width="205px" />
          <ResultsBar>
            <SkeletonShape variant="rectangular" height="37px" width="305px" />
            <SkeletonShape
              variant="circular"
              height="24px"
              width="24px"
              spaceInlineStart="l"
              spaceStackStart="xs"
            />
          </ResultsBar>
        </CountAndSortContainer>
      </ResultsBarContainer>
      <Divider spaceStackStart="l" />
      <ListTilesContainer>{new Array(3).fill(<TileSkeleton viewType="list" />)}</ListTilesContainer>
    </>
  );
};

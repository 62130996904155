export function debounce(func_, wait_, immediate_, ...restParams) {
    let timeout;
    let later;
    let context;
    let callNow;
    return () => {
        context = this;
        later = () => {
            timeout = null;
            if (!immediate_) {
                func_.apply(context, restParams);
            }
        };
        callNow = immediate_ && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait_);
        if (callNow) {
            func_.apply(context, restParams);
        }
    };
}

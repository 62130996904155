import { useEffect, useState } from 'react';
import { useScsUrlParts } from './useScsUrlParts';
import { getVehicleRequestUrl } from '../../utils';
export const useVehicleUrl = (vehicleId, svd) => {
    const scsUrlParts = useScsUrlParts();
    const [url, setUrl] = useState();
    useEffect(() => {
        if (vehicleId && scsUrlParts && svd) {
            setUrl(getVehicleRequestUrl(scsUrlParts, vehicleId, svd));
        }
    }, [scsUrlParts, vehicleId, svd]);
    return url;
};

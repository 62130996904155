import React from 'react';
import { isListView, TileContext } from '../hooks/tileHook';
import { GridTilesContainer, ListTilesContainer } from './Tiles.styles';

const Tiles: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const tileContext = React.useContext(TileContext);
  const detailsURL = tileContext.contentConfiguration?.urls?.detailsPageUrlPattern
    ? tileContext.contentConfiguration?.urls?.detailsPageUrlPattern
    : '';
  if (isListView()) {
    return (
      <ListTilesContainer
        data-testid="ListTilesContainer"
        className="sc-result-items"
        data-details-url={detailsURL}
      >
        {children}
      </ListTilesContainer>
    );
  }
  return (
    <GridTilesContainer
      data-testid="GridTilesContainer"
      className="sc-result-items"
      data-details-url={detailsURL}
    >
      {children}
    </GridTilesContainer>
  );
};

export default Tiles;

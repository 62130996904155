export const getScsUrlParts = (scsBaseUrl = '', versionsMapping, defaultVersion = 'v1', marketPath = '/') => {
    const [language, market] = marketPath.split('/');
    return {
        versionsMapping,
        marketPath,
        language,
        market,
        scsBaseUrl,
        defaultVersion,
    };
};
export const getVehicleRequestUrl = (scsUrlParts, vehicleId = '', svd = '') => {
    if (!scsUrlParts ||
        !scsUrlParts.scsBaseUrl ||
        !scsUrlParts.language ||
        !scsUrlParts.market ||
        !scsUrlParts.defaultVersion ||
        !vehicleId ||
        !svd) {
        return '';
    }
    const scsAPIurl = `${scsUrlParts.scsBaseUrl}${scsUrlParts.defaultVersion}/vehicles/vehicle/${scsUrlParts.language}/${scsUrlParts.market}`;
    return `${scsAPIurl}/${vehicleId}?svd=${svd}`;
};
export const getMatchingRequestUrl = (scsUrlParts, context, matchingAttributes, svd = '', optionalParams = { size: 3 }) => {
    var _a, _b;
    if (!scsUrlParts ||
        !(scsUrlParts === null || scsUrlParts === void 0 ? void 0 : scsUrlParts.market) ||
        !(scsUrlParts === null || scsUrlParts === void 0 ? void 0 : scsUrlParts.language) ||
        !((_a = scsUrlParts === null || scsUrlParts === void 0 ? void 0 : scsUrlParts.versionsMapping) === null || _a === void 0 ? void 0 : _a.match) ||
        !scsUrlParts.scsBaseUrl ||
        !Array.isArray(matchingAttributes) ||
        !svd) {
        return '';
    }
    const urlContext = context && context !== '' ? context : scsUrlParts === null || scsUrlParts === void 0 ? void 0 : scsUrlParts.language;
    const matchingParams = Object.assign(Object.assign({}, optionalParams), { attributes: encodeURI(matchingAttributes.join(',')), svd });
    let url = `${scsUrlParts.scsBaseUrl}${(_b = scsUrlParts === null || scsUrlParts === void 0 ? void 0 : scsUrlParts.versionsMapping) === null || _b === void 0 ? void 0 : _b.match}/search/match/${urlContext}/${scsUrlParts === null || scsUrlParts === void 0 ? void 0 : scsUrlParts.market}?`;
    for (const [key, value] of Object.entries(matchingParams)) {
        url = `${url}&${key}=${value}`;
    }
    return url;
};
export function generateRequestParamString(url, params = new Map()) {
    const getParams = [];
    for (const [parameterKey, parameter] of params.entries()) {
        if (parameter) {
            const encodedParameter = encodeURIComponent(parameter);
            getParams.push(`${parameterKey}=${encodedParameter}`);
        }
    }
    return `${url}${getParams.length ? `?${getParams.join('&')}` : ''}`;
}

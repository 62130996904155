import styled, { css } from 'styled-components';

export const GridContainer = styled.div``;

export const ListContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-flow: row wrap;
    justify-content: end;
  }
`;

export const FavoritesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  align-items: center;
`;

export const FavoritesOverlay = styled.div`
  position: absolute;
  z-index: 13;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
`;

const GridButtonStyles = css`
  width: 90%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  button {
    width: 100%;
  }
`;

export const GridButtonContainer = styled.div`
  ${GridButtonStyles}
`;

export const ListButtonContainer = styled.div`
  ${GridButtonStyles}

  @media screen and (min-width: 768px) {
    width: auto;
    display: grid;
    column-gap: var(${(props): string => props.theme.responsive.spacing.xxs});
    grid-template-columns: 1fr 1fr;
    align-items: self-start;

    button {
      width: auto;
    }
  }
`;

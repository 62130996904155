import React from 'react';
import {
  useFormattedDate,
  VehicleBasic,
  DangerousElement,
  useVehicleInspectionDueDate,
  Fuel,
  getOrderStatusTextKey,
  hasOrderStatus,
  isAvailableSoonVehicle,
  FootnoteReference,
  useAvailableSoonLabels,
  useServicesContext,
} from '@oneaudi/vtp-shared';
import { useI18n } from '@oneaudi/i18n-context';
import { Text } from '@audi/audi-ui-react-v2';
import { StyledContainer } from './DetailInfoItem.styles';

export interface Props {
  vehicle: VehicleBasic;
  itemKey: string;
}

const DetailInfoItem: React.FC<Props> = ({ vehicle, itemKey }) => {
  let label = useI18n({ id: `nemo.sc.icon.legend.${itemKey}`, defaultMessage: itemKey });
  let footnoteLabelKey: string | undefined;
  let footnoteValueKey: string | undefined;

  const { getConfiguration } = useServicesContext();
  const showAvailableFromDateIndependentlyFromData =
    getConfiguration()?.scopes?.showAvailableSoonDate;
  const availableSoonVehicle = isAvailableSoonVehicle(
    vehicle,
    showAvailableFromDateIndependentlyFromData
  );
  const {
    expectedAbbreviationLabel,
    fromDateLabel,
    availableFromDateOrLabel,
    expectedMileage: expectedMileageLabel,
  } = useAvailableSoonLabels(vehicle);
  const numberplateIndicator = vehicle.used?.numberplateIndicator;
  let value: string | undefined;
  switch (itemKey) {
    case 'FuelType':
      const fuel = vehicle.fuel!;
      const ioFuel = vehicle.io?.fuels?.length && vehicle.io.fuels[0].fuel;
      if (fuel) {
        value = useI18n({
          id: `sc.eec.fuel.${fuel.code.toLowerCase()}`,
          defaultMessage: ioFuel || '',
        });
      } else if (ioFuel) {
        value = useI18n({
          id: `nemo.ui.sc.tiles.fuel.type.${ioFuel.toLowerCase()}.label`,
          defaultMessage: ioFuel,
        });
      }
      break;
    case 'PowerDisplay':
      const model = vehicle.symbolicCarline?.code;
      const MATCHING_MODELS = ['q4etron', 'q4sbetron'];
      const MATCHING_VALS = [220, 195, 210, 250];
      const MATCHING_UNIT = 'kw';
      const isRelevantCarline: boolean = MATCHING_MODELS.some(
        (cl) => cl.toLowerCase() === model?.toLowerCase()
      );
      const matchedValues = MATCHING_VALS.filter((val) =>
        String(vehicle.powerDisplay).match(new RegExp(`${val}\\s?${MATCHING_UNIT}`, 'gmi'))
      );

      if (isRelevantCarline && matchedValues && matchedValues.length > 0) {
        const powerValue = matchedValues.pop();

        footnoteValueKey =
          powerValue === 210 || powerValue === 250
            ? 'max-power-q4-audi-footnote-engine'
            : `nemo-fn_engine-power-boost-${powerValue}`;
      }

      value = vehicle.powerDisplay;
      break;
    case 'InitialRegistrationDate':
      if (vehicle.used?.initialRegistrationDate) {
        value = !numberplateIndicator
          ? useFormattedDate(vehicle.used?.initialRegistrationDate)
          : `${useFormattedDate(vehicle.used?.initialRegistrationDate)} (${numberplateIndicator})`;
      }
      break;
    case 'GeneralInspection':
      if (vehicle.generalinspectionDueDate) {
        value = useVehicleInspectionDueDate(vehicle.generalinspectionDueDate);
      } else if (vehicle.generalInspectionString) {
        value = vehicle.generalInspectionString;
      }
      break;
    case 'Mileage':
      if (vehicle.used && vehicle.used.mileageFormatted) {
        value = `${vehicle.used.mileageFormatted} ${vehicle.used.mileageUnit}`;
      }
      if (availableSoonVehicle) {
        label = expectedMileageLabel;
        footnoteLabelKey = 'fn_expected_mileage';
      }
      footnoteValueKey = 'fn_miles';
      break;
    case 'AvailableFromDate':
      if (availableSoonVehicle) {
        label = `${expectedAbbreviationLabel} ${fromDateLabel}`;
        value = availableFromDateOrLabel;
        footnoteLabelKey = 'fn_available_soon';
      }
      break;
    case 'ElectricRange':
      const electricEngine = vehicle?.io?.fuels?.find(
        (fuelType: Fuel) => fuelType.fuel === 'ELECTRICAL'
      );
      if (electricEngine && electricEngine?.range?.consolidated?.value) {
        value = `${electricEngine?.range?.consolidated.value} ${electricEngine?.range?.consolidated.unit}`;
      }
      break;
    case 'Vin':
      value = vehicle.vin;
      break;
    case 'GearType':
      value = vehicle.gearType?.description;
      break;
    case 'UpholsteryColor':
      value = vehicle.upholsteryColor?.description;
      break;
    case 'UpholsteryType':
      value = vehicle.upholsteryType?.description;
      break;
    case 'ExtColor':
      value = vehicle.extColor?.description;
      break;
    case 'NumPreviousOwners':
      if (vehicle.used?.numPreviousOwners) {
        value = `${vehicle.used?.numPreviousOwners}`;
      }
      break;
    case 'OrderStatus':
      if (vehicle.vehicleOrderStatus && hasOrderStatus(vehicle.vehicleOrderStatus)) {
        const orderStatusTextKey = getOrderStatusTextKey(vehicle.vehicleOrderStatus);
        value = useI18n({
          id:
            orderStatusTextKey === 'in-delivery'
              ? 'nemo.ui.sc.details.dealer.order-status.in-delivery'
              : 'nemo.ui.sc.details.dealer.order-status.at-dealer',
        });
      }
      break;
    case 'NumberPlate':
      label = useI18n({ id: 'nemo.ui.sc.registration.number.label', defaultMessage: '' });
      value = vehicle?.used?.numberplate || '';
      break;
    case 'ProductionYear':
      label = useI18n({ id: 'nemo.ui.sc.details.production.year.label', defaultMessage: '' });
      if (vehicle?.productionYear) {
        value = `${vehicle.productionYear}`;
      }
      break;
    case 'ModelYear':
      label = useI18n({ id: 'nemo.ui.sc.details.model.year.label', defaultMessage: '' });
      if (vehicle?.modelYear) {
        value = `${vehicle.modelYear}`;
      }
      break;
    default:
      break;
  }

  if (!value) {
    return null;
  }

  return (
    <StyledContainer>
      <Text variant="copy2" weight="bold" as="span">
        {label}
        {footnoteLabelKey && <FootnoteReference refId={footnoteLabelKey} />}:&nbsp;
      </Text>
      <Text variant="copy2" as="span">
        {itemKey === 'UpholsteryType' ? <DangerousElement html={value} as="span" /> : value}
        {footnoteValueKey && <FootnoteReference refId={footnoteValueKey} />}
      </Text>
    </StyledContainer>
  );
};

export default DetailInfoItem;

export var FootnoteReferencesActionTypes;
(function (FootnoteReferencesActionTypes) {
    FootnoteReferencesActionTypes[FootnoteReferencesActionTypes["AddFootnoteReference"] = 0] = "AddFootnoteReference";
    FootnoteReferencesActionTypes[FootnoteReferencesActionTypes["RemoveFootnoteReference"] = 1] = "RemoveFootnoteReference";
    FootnoteReferencesActionTypes[FootnoteReferencesActionTypes["SetFootnoteReferencesNumbers"] = 2] = "SetFootnoteReferencesNumbers";
})(FootnoteReferencesActionTypes || (FootnoteReferencesActionTypes = {}));
export const addFootnoteReference = (refId) => {
    return {
        payload: refId,
        type: FootnoteReferencesActionTypes.AddFootnoteReference,
    };
};
export const removeFootnoteReference = (refId) => {
    return {
        payload: refId,
        type: FootnoteReferencesActionTypes.RemoveFootnoteReference,
    };
};
export const setFootnoteReferences = (numberedFootnotes) => {
    return {
        payload: numberedFootnotes,
        type: FootnoteReferencesActionTypes.SetFootnoteReferencesNumbers,
    };
};

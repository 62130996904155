import styled from 'styled-components';

export const StyledImage = styled.img`
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

export const StyledPicture = styled.picture`
  font-size: 0;
`;

export const ImageContainer = styled.div<{ photoImage: boolean }>`
  background-color: var(
    ${(props): string =>
      props.photoImage ? props.theme.colors.base.brand.white : props.theme.colors.base.grey[5]}
  );
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 0;

  @media (min-width: 768px) {
    aspect-ratio: 4/3;
  }
`;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIONS, SELECTORS } from '@oneaudi/stck-store';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { useScsUrlParts } from './useScsUrlParts';
import { fetchSVD } from '../../utils';
export const useVersionUrl = () => {
    const { versionsMapping: { version }, market, language, scsBaseUrl, } = useScsUrlParts();
    const [url, setUrl] = useState();
    useEffect(() => {
        if (!!version && !!market && !!language && !!scsBaseUrl) {
            setUrl(`${scsBaseUrl}${version}/version/${language}/${market}`);
        }
    }, [version, market, language, scsBaseUrl]);
    return url;
};
export const getVersionUrl = (scsUrlParts) => {
    return `${scsUrlParts.scsBaseUrl}${scsUrlParts.versionsMapping.version}/version/${scsUrlParts.language}/${scsUrlParts.market}`;
};
let PENDING = false;
export const useSvd = () => {
    const svd = useSelector((state) => {
        return (state === null || state === void 0 ? void 0 : state.svdVersion) === ACTIONS.SVD_VERSION.PENDING_SVD_VERSION ? null : state === null || state === void 0 ? void 0 : state.svdVersion;
    });
    const svdIsPending = useSelector((state) => SELECTORS.PENDING_IDS.isPending(state, 'svd'));
    const [svdError, setSvdError] = useState();
    const versionUrl = useVersionUrl();
    const dispatch = useDispatch();
    useDeepCompareEffect(() => {
        const getAndStoreSVD = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                dispatch(ACTIONS.PENDING_IDS.addPendingID({ id: 'svd' }));
                const version = yield fetchSVD(versionUrl);
                dispatch(ACTIONS.SVD_VERSION.setSvdVersion({ version }));
            }
            catch (err) {
                setSvdError(err);
            }
            finally {
                dispatch(ACTIONS.PENDING_IDS.removePendingID({ id: 'svd' }));
                PENDING = false;
            }
        });
        if (!PENDING && !svdError && versionUrl && !svd && !svdIsPending) {
            PENDING = true;
            getAndStoreSVD();
        }
    }, [svdError, dispatch, svd, svdIsPending, versionUrl]);
    return { svd, svdError };
};

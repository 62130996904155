import { formatUrl, isBevAgencyVehicle } from '../../utils';
export function getButtons(vehicle, linkMap, isDesktop) {
    const isLiveDemoVehicle = linkMap.has('liveDemo') && vehicle.liveConsulting;
    const showPhoneButton = !isDesktop && linkMap.has('phone');
    const addButton = (buttonId, variant) => {
        linkMap.get(buttonId).variant = variant;
        links.push(linkMap.get(buttonId));
    };
    const isBevAgency = isBevAgencyVehicle(vehicle);
    const links = [];
    if (vehicle.financing &&
        Object.keys(vehicle.financing).length > 0 &&
        vehicle.leasingCar &&
        linkMap.has('leasing')) {
        addButton('leasing', 'primary');
        if (showPhoneButton) {
            addButton('phone', 'secondary');
        }
        if (isLiveDemoVehicle) {
            addButton('liveDemo', 'secondary');
        }
    }
    else if (vehicle.buyableOnline && !vehicle.reservation && linkMap.has('ecom')) {
        addButton('ecom', 'primary');
        if (showPhoneButton) {
            addButton('phone', 'secondary');
        }
        if (linkMap.has('contact')) {
            addButton('contact', 'secondary');
        }
        if (isLiveDemoVehicle) {
            addButton('liveDemo', 'secondary');
        }
    }
    else if (vehicle.nationWideSelling && linkMap.has('nws')) {
        addButton('nws', 'primary');
    }
    else if (isBevAgency && linkMap.has('bevAgency')) {
        addButton('bevAgency', 'primary');
    }
    else if (linkMap.has('contact')) {
        if (showPhoneButton && !vehicle.nationWideSelling) {
            addButton('phone', 'primary');
        }
        addButton('contact', isDesktop || !showPhoneButton ? 'primary' : 'secondary');
        if (isLiveDemoVehicle) {
            addButton('liveDemo', 'secondary');
        }
    }
    return extendCTAsWithFormattedUrl(links, vehicle);
}
export function extendCTAsWithFormattedUrl(links, vehicle) {
    return links.map((link) => {
        const newLink = Object.assign(Object.assign({}, link), { url: link.url });
        if (link.url) {
            newLink.url = formatUrl(link.url, vehicle);
        }
        return newLink;
    });
}
export function getCTAPhone(vehicle, phoneLabel) {
    var _a;
    const phoneNumber = (_a = vehicle.dealer.phoneNumbers) === null || _a === void 0 ? void 0 : _a.find((number) => {
        return number.type === 'phone';
    });
    const phoneNumberAsString = String((phoneNumber === null || phoneNumber === void 0 ? void 0 : phoneNumber.number) ? phoneNumber.number : '');
    if (phoneNumberAsString === '') {
        return null;
    }
    const cleanedPhoneNumber = phoneNumberAsString
        ? `tel:${phoneNumberAsString.replace(/ /gi, '')}`
        : '';
    return {
        method: 'GET',
        type: 'phone',
        target: 'same-window',
        label: phoneLabel,
        variant: 'secondary',
        url: cleanedPhoneNumber,
    };
}

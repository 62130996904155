import React, { useState } from 'react';
import styled from 'styled-components';
import { Text } from '@audi/audi-ui-react-v2';
import { Icon, iconSystemInfoSmall } from '../../../icon';
export const InfoIcon = ({ label, popupContentStyles, isLastInfoIcon, children, onOpenOverlay, }) => {
    const [open, setOpen] = useState(false);
    const [iconPosition, setPosition] = useState(11);
    const getPosition = (evt) => {
        const target = evt.target;
        const clickedButton = target.closest('button');
        const wrapperDiv = document.querySelector('div[type="FOCUS_LARGE"][data-testid="one-layer-content-0"]');
        const parentRect = wrapperDiv.getBoundingClientRect();
        const btnRect = clickedButton.getBoundingClientRect();
        const wrapCenter = parentRect.left + parentRect.width / 2;
        const btnCenter = btnRect.left + btnRect.width / 2;
        if (btnCenter < wrapCenter) {
            if (btnCenter > wrapCenter / 2) {
                setPosition(btnCenter - wrapCenter / 2 + 53);
            }
            else {
                setPosition(53);
            }
        }
        else {
            setPosition(btnCenter - wrapCenter + 177 - 10);
        }
    };
    const clickHandler = (evt) => {
        evt.preventDefault();
        if (onOpenOverlay) {
            onOpenOverlay();
        }
        getPosition(evt);
        setOpen(true);
        const { target } = evt;
        const siblingDiv = target.closest('button').nextElementSibling;
        if (siblingDiv && siblingDiv.hasAttribute('open')) {
            siblingDiv.style.display = '';
        }
        if (evt.type === 'click') {
            setTimeout(() => setOpen(false), 2000);
        }
    };
    const handleMouseOut = () => {
        setOpen(false);
    };
    return (React.createElement(Container, null,
        React.createElement(PopupButton, { type: "button", onClick: clickHandler, onMouseOver: clickHandler, onMouseOut: handleMouseOut },
            React.createElement(Icon, { icon: iconSystemInfoSmall }),
            label && (React.createElement(Text, { variant: "copy1", as: "span" }, label))),
        React.createElement(PopupContent, { role: "tooltip", open: open, iconPosition: iconPosition, popupContentStyles: popupContentStyles, isLastInfoIcon: isLastInfoIcon }, children)));
};
const Container = styled.div `
  position: relative;
  margin-block-start: var(${(props) => props.theme.responsive.spacing.m});
`;
const PopupButton = styled.button `
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  color: var(${(props) => props.theme.colors.base.grey[100]});
  cursor: pointer;

  // &:hover,
  &:focus {
    & + * {
      display: block;
    }
  }

  svg {
    display: block;
    margin-right: var(${(props) => props.theme.responsive.spacing.xs});
  }

  path {
    fill: var(${(props) => props.theme.colors.base.grey[0]});
  }
`;
const PopupContent = styled.div `
  display: ${(props) => (props.open ? 'block' : 'none')};
  background-color: var(${(props) => props.theme.colors.base.grey[0]});
  position: absolute;
  z-index: 10;
  top: 32px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
  width: 600px;
  max-width: none;
  left: -${(props) => props.iconPosition - 42}px;
  padding: var(${(props) => props.theme.responsive.spacing.s});
  font-family: var(${(props) => props.theme.fontFamily});
  font-size: var(${(props) => props.theme.responsive.typography.copy1.fontSize});
  color: var(${(props) => props.theme.colors.base.grey[80]});
  font-weight: var(${(props) => props.theme.fontWeights.normal});
  font-stretch: var(${(props) => props.theme.responsive.typography.copy1.fontStretch});
  text-align: left;
  line-height: var(${(props) => props.theme.responsive.typography.copy1.lineHeight});
  -webkit-font-smoothing: antialiased;

  &::before {
    content: '';
    position: absolute;
    left: ${(props) => props.iconPosition - 42}px;
    top: -8px;
    width: 18px;
    height: 8px;
    background-color: var(${(props) => props.theme.colors.base.grey[0]});
    clip-path: polygon(0 100%, 50% 0, 100% 100%);
  }

  @media (max-width: 1440px) {
    width: 555px;
  }

  @media (max-width: 825px) {
    width: 450px;
  }

  @media (max-width: 720px) {
    width: 420px;
  }

  @media (max-width: 600px) {
    width: 80vw;
    max-width: 440px;
    left: ${(props) => `-${props.iconPosition + (props.iconPosition > window.innerWidth / 2 ? 50 : 4)}px`};

    &::before {
      left: ${(props) => `${props.iconPosition + (props.iconPosition > window.innerWidth / 2 ? 50 : 4)}px`};
    }
  }

  @media (max-width: 430px) {
    left: ${(props) => `-${props.iconPosition + (props.iconPosition > window.innerWidth / 2 ? -5 : 4)}px`};

    &::before {
      left: ${(props) => `${props.iconPosition + (props.iconPosition > window.innerWidth / 2 ? -5 : 4)}px`};
    }
  }

  @media (max-width: 320px) {
    left: ${(props) => `-${props.iconPosition + (props.iconPosition > window.innerWidth / 2 ? -40 : -4)}px`};

    &::before {
      left: ${(props) => `${props.iconPosition + (props.iconPosition > window.innerWidth / 2 ? -40 : -4)}px`};
    }
  }
`;

import { FootnoteReferencesActionTypes } from './actions';
const updateReferences = (state, refId, addend = 1) => {
    const { references } = state;
    const reference = references.find((referencesItem) => referencesItem.id === refId);
    if (reference) {
        reference.count += addend;
    }
    else {
        references.push({ id: refId, count: 1 });
    }
    return references.filter((referencesItem) => referencesItem.count > 0);
};
const updateReferenceServiceData = (footnoteReferenceService, references) => {
    footnoteReferenceService.removeFootnoteReferences();
    const footnotes = references.map((reference) => reference.id);
    footnoteReferenceService.addFootnoteReferences(footnotes);
};
const setReferenceNumbers = (existingRefs, numberedRefs) => {
    return existingRefs.map((reference) => {
        const numberedRef = numberedRefs.find((numberedReference) => numberedReference.id === reference.id);
        const number = (numberedRef === null || numberedRef === void 0 ? void 0 : numberedRef.number) || -1;
        const contextId = numberedRef === null || numberedRef === void 0 ? void 0 : numberedRef.contextID;
        return Object.assign(Object.assign({}, reference), { number,
            contextId });
    });
};
export const footnoteReferenceReducer = (state, action) => {
    switch (action.type) {
        case FootnoteReferencesActionTypes.AddFootnoteReference: {
            const newReferences = updateReferences(state, action.payload);
            updateReferenceServiceData(state.footnoteReferenceService, newReferences);
            return Object.assign(Object.assign({}, state), { references: newReferences });
        }
        case FootnoteReferencesActionTypes.RemoveFootnoteReference: {
            const newReferences = updateReferences(state, action.payload, -1);
            updateReferenceServiceData(state.footnoteReferenceService, newReferences);
            return Object.assign(Object.assign({}, state), { references: newReferences });
        }
        case FootnoteReferencesActionTypes.SetFootnoteReferencesNumbers: {
            const newReferences = setReferenceNumbers(state.references, action.payload);
            return Object.assign(Object.assign({}, state), { references: newReferences });
        }
        default: {
            return state;
        }
    }
};

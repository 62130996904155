import styled from 'styled-components';
export const CoreCiIconStyled = styled('div') `
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-repeat: no-repeat;
  background-position: center;
  height: ${({ height }) => height};
  margin: ${({ margin }) => margin};
  transform: rotate(${({ rotation }) => rotation}deg);
  width: ${({ width }) => width};
`;

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin-bottom: var(${(props): string => props.theme.responsive.spacing.l});
`;

export const StyledPagination = styled.div<{ isListView: boolean; pictureLength?: number }>`
  display: flex;
  justify-content: ${(props): string => (props.isListView ? 'center' : 'flex-end')};
  ${(props): string =>
    props.pictureLength === undefined || props.pictureLength > 1
      ? `margin-top: var(${props.theme.responsive.spacing.xxs});`
      : `margin-top: 30px;`}
`;

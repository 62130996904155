import { useEffect, useState } from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { messages } from '../../i18n/messages';
import { isAvailableSoonVehicle } from '../../utils';
import { useScopes } from '../useScopes';
import { useServicesContext } from '../../context';
export const useAvailableSoonLabels = (vehicle, showAvailabilityForValueNow = false) => {
    var _a, _b, _c, _d, _e, _f;
    const { getConfiguration } = useServicesContext();
    const hideConcreteAvailableFromDate = (_c = (_b = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes) === null || _b === void 0 ? void 0 : _b.hideConcreteAvailableSoonDate) !== null && _c !== void 0 ? _c : useScopes('STCK_HIDE_CONCRETE_AVAILABLE_SOON_DATE');
    const showAvailableFromDateIndependentlyFromData = (_f = (_e = (_d = getConfiguration()) === null || _d === void 0 ? void 0 : _d.scopes) === null || _e === void 0 ? void 0 : _e.showAvailableSoonDate) !== null && _f !== void 0 ? _f : useScopes('STCK_SHOW_AVAILABLE_SOON_DATE');
    const [availableSoonLabels, setAvailableSoonLabels] = useState({
        expectedLabel: '',
        expectedPriceLabel: '',
        expectedAbbreviationLabel: '',
        fromDateLabel: '',
        availableFromDateOrLabel: '',
        expectedMileage: '',
    });
    let expectedLabel = (vehicle === null || vehicle === void 0 ? void 0 : vehicle.availableFromCode) === 'soon'
        ? useI18n(messages.availabeSoon.availability)
        : useI18n(messages.availabeSoon.expected);
    const expectedPriceLabel = useI18n(messages.availabeSoon.expectedPriceLabel) || useI18n(messages.availabeSoon.expected);
    const expectedAbbreviationLabel = useI18n(messages.availabeSoon.expectedAbbreviation);
    const fromDateLabel = useI18n(messages.availabeSoon.fromDate);
    const availableFromDateFallbackLabel = useI18n(messages.availabeSoon.fromDateFallback);
    const locale = `${vehicle.langCode}-${vehicle.countryCode}`;
    const availableFromDateOrSoon = (vehicle === null || vehicle === void 0 ? void 0 : vehicle.availableFrom) && (vehicle === null || vehicle === void 0 ? void 0 : vehicle.availableFromCode) === 'soon'
        ? vehicle === null || vehicle === void 0 ? void 0 : vehicle.availableFrom
        : new Date(vehicle === null || vehicle === void 0 ? void 0 : vehicle.availableFrom).toLocaleString(locale).split(',')[0];
    let availableFromDateOrLabel = hideConcreteAvailableFromDate
        ? availableFromDateFallbackLabel
        : availableFromDateOrSoon;
    if ((vehicle === null || vehicle === void 0 ? void 0 : vehicle.availableFromCode) === 'now' && showAvailabilityForValueNow) {
        expectedLabel = useI18n({
            id: 'nemo.ui.sc.highlights.availablefromdate.available.now.label',
            defaultMessage: '',
        });
        availableFromDateOrLabel = useI18n({
            id: 'nemo.ui.sc.highlights.availablefromdate.available.now.description',
            defaultMessage: '',
        });
    }
    const expectedMileage = useI18n(messages.availabeSoon.expectedMileage);
    useEffect(() => {
        if (isAvailableSoonVehicle(vehicle, showAvailableFromDateIndependentlyFromData) ||
            showAvailabilityForValueNow) {
            setAvailableSoonLabels({
                expectedLabel,
                expectedAbbreviationLabel,
                fromDateLabel,
                availableFromDateOrLabel,
                expectedMileage,
                expectedPriceLabel,
            });
        }
    }, [
        availableFromDateOrLabel,
        expectedAbbreviationLabel,
        expectedLabel,
        expectedPriceLabel,
        fromDateLabel,
        vehicle,
        expectedMileage,
        showAvailableFromDateIndependentlyFromData,
    ]);
    return availableSoonLabels;
};

import { useLayoutEffect } from 'react';
export const useBodyScrollLock = () => {
    useLayoutEffect(() => {
        const { overflow: originalOverFlow } = window.getComputedStyle(document.body);
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = originalOverFlow;
        };
    }, []);
};

import { useI18n } from '@oneaudi/i18n-context';
export const useDealerLabels = (vehicle) => {
    var _a, _b;
    const dealerName = ((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.dealer) === null || _a === void 0 ? void 0 : _a.name) || null;
    const businessModelCode = ((_b = vehicle === null || vehicle === void 0 ? void 0 : vehicle.businessModel) === null || _b === void 0 ? void 0 : _b.code) || '';
    if (!businessModelCode) {
        return {
            dealerHeadline: useI18n({ id: 'nemo.ui.sc.details.dealer-label' }),
            dealerLabel: '',
            dealerSubLabel: '',
            dealerName,
        };
    }
    const isDealerVehicle = businessModelCode === 'dealer_stock';
    const dealerLabel = isDealerVehicle
        ? useI18n({
            id: `nemo.ui.sc.${businessModelCode}.label`,
            defaultMessage: '',
        })
        : '';
    const dealerSubLabel = isDealerVehicle
        ? useI18n({
            id: `nemo.ui.sc.${businessModelCode}.sublabel`,
            defaultMessage: '',
        })
        : '';
    const dealerHeadline = isDealerVehicle
        ? useI18n({
            id: `nemo.ui.sc.${businessModelCode}.headline`,
            defaultMessage: '',
        })
        : '';
    return {
        dealerLabel,
        dealerSubLabel,
        dealerHeadline,
        dealerName,
    };
};

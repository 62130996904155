import { useI18n } from '@oneaudi/i18n-context';
import { hasBevAgencyDealerLabel, isBevAgencyVehicle } from '../../utils';
export const useBevAgencyLabels = (vehicle) => {
    var _a, _b;
    const businessModelCode = ((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.businessModel) === null || _a === void 0 ? void 0 : _a.code) || '';
    const isBevAgency = isBevAgencyVehicle(vehicle);
    const bevAgencyLabel = isBevAgency
        ? useI18n({
            id: `nemo.ui.sc.${businessModelCode}.label`,
            defaultMessage: '',
        })
        : '';
    const bevAgencySubLabel = isBevAgency
        ? useI18n({
            id: `nemo.ui.sc.${businessModelCode}.sublabel`,
            defaultMessage: '',
        })
        : '';
    const bevAgencyHeadline = isBevAgency
        ? useI18n({
            id: `nemo.ui.sc.${businessModelCode}.headline`,
            defaultMessage: '',
        })
        : '';
    const bevAgencyDealerName = hasBevAgencyDealerLabel(vehicle) ? ((_b = vehicle === null || vehicle === void 0 ? void 0 : vehicle.dealer) === null || _b === void 0 ? void 0 : _b.name) || '' : null;
    return {
        bevAgencyLabel,
        bevAgencySubLabel,
        bevAgencyHeadline,
        bevAgencyDealerName,
    };
};

export const warrantyCodeLookup = {
    WarrantyTypeWarranty: 'used-car-warranty-type.warranty',
    WarrantyTypePlus: 'used-car-warranty-type.plus',
    WarrantyType5Years: 'used-car-warranty-type.5-years',
    WarrantyTypeAsgExtended: 'used-car-warranty-type.asg-extended',
    WarrantyTypeAsg: 'used-car-warranty-type.asg',
    WarrantyTypeGwb: 'used-car-warranty-type.gwplus5-years-extended',
    WarrantyType12Months: 'used-car-warranty-type.twelve-months',
    WarrantyTypeClp: 'used-car-warranty-type.clp',
    WarrantyTypeCpo: 'used-car-warranty-type.cpo',
    WarrantyTypeNap: 'used-car-warranty-type.NAP',
};

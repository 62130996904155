import React from 'react';
import TileCampaignElement from './TileCampaignElement';
import { Button } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { Campaign, VehicleBasic } from '@oneaudi/vtp-shared';

interface Props {
  detailsPageLink: string;
  activeCampaigns: Campaign[];
  vehicle: VehicleBasic;
}
const TileCampaigns: React.FC<Props> = ({ activeCampaigns, detailsPageLink, vehicle }) => {
  const linkLabel = useI18n({
    id: 'nemo.ui.sc.tiles.tabs.advantages.more',
    defaultMessage: 'More advantages',
  });
  return (
    <div data-testid="campaigns">
      {activeCampaigns.slice(0, 4).map((campaign: Campaign) => (
        <div key={campaign.campaignId}>
          <TileCampaignElement
            campaign={campaign}
            vehicle={vehicle}
            data-testid="campaignElement"
          />
        </div>
      ))}
      {activeCampaigns.length > 4 && (
        <Button
          variant="text"
          size="small"
          href={detailsPageLink}
          data-testid="detailsPageLink"
          spaceStackStart="m"
          spaceInlineStart="s"
        >
          {linkLabel}
        </Button>
      )}
    </div>
  );
};

export default TileCampaigns;

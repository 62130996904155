export const getEventAction = (ctaType, ctaLink, linkTarget) => {
    let eventAction = '';
    switch (ctaType) {
        case 'phone':
            eventAction = 'call';
            break;
        case 'ecom':
            eventAction = ctaLink && ctaLink.includes(getCurrentDomain()) ? 'internal_link' : 'exit_link';
            break;
        case 'liveDemo':
        case 'liteReservation':
        case 'reserve':
        case 'reservation':
            eventAction = 'exit_link';
            break;
        case 'contact':
            eventAction =
                ctaLink && (ctaLink.includes(getCurrentDomain()) || linkTarget === 'open-in-layer')
                    ? 'content'
                    : 'exit_link';
            break;
        case 'nws':
        case 'leasing':
        case 'dealer':
        case 'financeInfo':
        case 'whatsApp':
            eventAction = 'content';
            break;
        case 'financeOptions':
            eventAction = 'internal_link';
            break;
        default:
            if (ctaLink && ctaLink.includes(getCurrentDomain())) {
                eventAction = 'internal_link';
                break;
            }
            eventAction = 'exit_link';
    }
    return eventAction;
};
const getCurrentDomain = () => {
    return window ? window.location.origin : '';
};
const getTargetURL = (linkURL, vehicleID) => {
    if (linkURL) {
        if (linkURL.includes('{{sc_vehicle_id}}')) {
            linkURL = linkURL.replace('{{sc_vehicle_id}}', vehicleID);
        }
        return getCurrentDomain() && !linkURL.includes('http') && !linkURL.includes(getCurrentDomain())
            ? `${getCurrentDomain()}${linkURL}`
            : linkURL;
    }
    return '';
};
export const getEventName = (ctaType) => {
    let eventName = '';
    switch (ctaType) {
        case 'contact':
        case 'nws':
            eventName = 'vtp contact';
            break;
        case 'leasing':
            eventName = 'vtp click on leasing offer';
            break;
        case 'ecom':
            eventName = 'vtp click on oneaudi shop';
            break;
        case 'liveDemo':
            eventName = 'vtp click on live consulting';
            break;
        case 'liteReservation':
            eventName = 'vtp click on vehicle lite reservation';
            break;
        case 'reserve':
        case 'reservation':
            eventName = 'vtp click on vehicle reservation';
            break;
        case 'dealer':
            eventName = 'vtp carInfo - click on dealer details';
            break;
        case 'financeInfo':
            eventName = 'vtp click on additional finance infos';
            break;
        case 'financeOptions':
            eventName = 'vtp change rate - layer open';
            break;
        case 'phone':
            eventName = 'vtp call dealer';
            break;
        case 'whatsApp':
            eventName = 'vtp carInfo - contact via whats app';
            break;
        default:
            eventName = 'vtp click on cta';
    }
    return eventName;
};
export const getElementName = (ctaType, ctaVariant) => {
    let elementName = '';
    switch (ctaType) {
        case 'dealer':
            elementName = 'text link';
            break;
        case 'financeInfo':
            elementName = 'icon';
            break;
        case 'financeOptions':
            elementName = 'text link';
            break;
        case 'phone':
            elementName = ctaVariant ? 'button' : 'text link';
            break;
        case 'whatsApp':
            elementName = 'text link';
            break;
        default:
            elementName = 'button';
    }
    return elementName;
};
export const trackCTAClick = (trackingService, link, vehicleID) => {
    if (trackingService) {
        trackingService.track({
            event: {
                eventInfo: {
                    eventAction: getEventAction(link.type, link.url, link === null || link === void 0 ? void 0 : link.target),
                    eventName: (link === null || link === void 0 ? void 0 : link.target) === 'open-in-layer' ? 'vtp contact - layer open' : getEventName(link.type),
                },
                attributes: {
                    label: (link.url.includes('tel:') && link.url.replace('tel:', '')) || link.label,
                    componentName: 'vtp-carInfo',
                    elementName: getElementName(link.type, link.variant),
                    targetURL: (link.url.includes('tel:') && link.url.replace('tel:', '')) ||
                        getTargetURL(link.url, vehicleID) ||
                        '',
                    clickID: '',
                    value: link.variant === '' ? '' : `${link.variant} button`,
                    pos: '',
                },
            },
        });
    }
};
export const trackClick = (trackingService, link, variant, componentName, vehicleID) => {
    if (trackingService) {
        trackingService.track({
            event: {
                eventInfo: {
                    eventAction: getEventAction(link.type, link.url, link === null || link === void 0 ? void 0 : link.target),
                    eventName: (link === null || link === void 0 ? void 0 : link.target) === 'open-in-layer' ? 'vtp contact - layer open' : getEventName(link.type),
                },
                attributes: {
                    label: (link.url.includes('tel:') && link.url.replace('tel:', '')) || link.label,
                    componentName,
                    elementName: getElementName(link.type),
                    targetURL: (link.url.includes('tel:') && link.url.replace('tel:', '')) ||
                        getTargetURL(link.url, vehicleID) ||
                        '',
                    clickID: '',
                    value: `${variant} button`,
                    pos: '',
                },
            },
        });
    }
};
export const trackRecalculateClick = (trackingService, label) => {
    if (trackingService) {
        trackingService.track({
            event: {
                eventInfo: {
                    eventAction: 'calculate',
                    eventName: 'vtp finance calculation',
                },
                attributes: {
                    componentName: 'vtp-carInfo',
                    label,
                    targetURL: '',
                    clickID: '',
                    elementName: 'button',
                    value: '',
                    pos: '',
                },
            },
        });
    }
};
export const trackUpdateClick = (trackingService, label) => {
    if (trackingService) {
        trackingService.track({
            event: {
                eventInfo: {
                    eventAction: 'content',
                    eventName: 'vtp apply finance calculation',
                },
                attributes: {
                    componentName: 'vtp-carInfo',
                    label,
                    targetURL: '',
                    clickID: '',
                    elementName: 'button',
                    value: '',
                    pos: '',
                },
            },
            componentUpdate: {
                page: {
                    pageInfo: {
                        pageID: '',
                    },
                },
            },
        });
    }
};
export const trackLayerOpen = (trackingService, eventName, pageID) => {
    if (trackingService) {
        trackingService.track({
            event: {
                eventInfo: {
                    eventAction: 'view_change',
                    eventName,
                },
                attributes: {
                    componentName: 'vtp-carInfo',
                    label: '',
                    targetURL: '',
                    clickID: '',
                    elementName: '',
                    value: '',
                    pos: '',
                },
            },
            componentUpdate: {
                page: {
                    pageInfo: {
                        pageID,
                    },
                },
            },
        });
    }
};
export const trackLayerClose = (trackingService) => {
    if (trackingService) {
        trackingService.track({
            event: {
                eventInfo: {
                    eventAction: 'content',
                    eventName: 'vtp carInfo - close financing layer',
                },
                attributes: {
                    componentName: 'vtp-carInfo',
                    label: '',
                    targetURL: '',
                    clickID: '',
                    elementName: 'other',
                    value: '',
                    pos: '',
                },
            },
            componentUpdate: {
                page: {
                    pageInfo: {
                        pageID: '',
                    },
                },
            },
        });
    }
};

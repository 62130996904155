const STORAGE_KEY_PREFIX = 'VTP_CRS_DATA';
const STORAGE_TTL = 1000 * 60 * 15;
function resolveCacheKey(vehicleId, requestedData) {
    return `${STORAGE_KEY_PREFIX}-${vehicleId || 'no-vehicle-id'}-${requestedData}`;
}
export function getCachedValue(crsLogger, vehicleId, requestedData) {
    if (typeof window !== 'undefined') {
        crsLogger.debug('fetching data from cache');
        const cachedData = JSON.parse(sessionStorage.getItem(resolveCacheKey(vehicleId, requestedData)));
        if (cachedData) {
            if (cachedData.timestamp + STORAGE_TTL > Date.now()) {
                crsLogger.debug('found data in cache', cachedData.data);
                return cachedData.data;
            }
            crsLogger.debug('found outdated data in cache, ignoring...');
        }
    }
    return undefined;
}
export function updateCachedValue(crsLogger, vehicleId, requestedData, value) {
    if (typeof window !== 'undefined') {
        const cachedData = {
            timestamp: Date.now(),
            data: value,
        };
        crsLogger.debug('update data in cache', cachedData);
        sessionStorage.setItem(resolveCacheKey(vehicleId, requestedData), JSON.stringify(cachedData));
    }
}

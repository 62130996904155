import * as React from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { messages } from '../../i18n/messages';
import { useServicesContext } from '../../context';
import { Icon, iconSystemInfoSmall } from '../icon';
export const FinancingAllInPricingElement = ({ vehicle, }) => {
    var _a;
    const { getConfiguration } = useServicesContext();
    const scopesFromConfig = (_a = getConfiguration()) === null || _a === void 0 ? void 0 : _a.scopes;
    const hideAllInPricing = scopesFromConfig === null || scopesFromConfig === void 0 ? void 0 : scopesFromConfig.hidePrices;
    const PRICE_TYPES = [
        'CUSTOM_PRICE_1',
        'CUSTOM_PRICE_2',
        'CUSTOM_PRICE_3',
        'CUSTOM_PRICE_4',
        'CUSTOM_TAXATION',
        'CUSTOM_TAXATION_2',
    ];
    const layerHeadline = useI18n(messages.financeElement.dataLayerHeadline);
    const showPricingDetailButton = !hideAllInPricing &&
        (vehicle === null || vehicle === void 0 ? void 0 : vehicle.typedPrices) &&
        (vehicle === null || vehicle === void 0 ? void 0 : vehicle.typedPrices.filter((price) => PRICE_TYPES.includes(price.type)).length);
    if (!showPricingDetailButton) {
        return null;
    }
    return (React.createElement("div", null,
        React.createElement("a", { className: "js-modal-layer-link sc-financing-allinpricing-info-icon-link", "data-layer-type": "ModalAllInPricingElement", "data-vehicle-id": vehicle.id, title: "All In Pricing", "data-layer-headline": layerHeadline, "x-cq-linkchecker": "skip" },
            React.createElement(Icon, { icon: iconSystemInfoSmall }))));
};

import * as React from 'react';
import styled from 'styled-components';
export const Tile = ({ children }) => {
    return React.createElement(React.Fragment, null, children);
};
export const TileElement = styled.div `
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  flex: 1 0 99%;
  padding-right: ${(props) => props.layout === 'renderImages'
    ? `var(${props.theme.responsive.spacing.xxs})`
    : `var(${props.theme.responsive.spacing.xs})`};
  margin-left:
  &:last-child {
    padding-right: 0;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.m - 1}px) {
    width: 99%;
    scroll-snap-align: center;
  }
  @media screen and (min-width: ${(props) => props.theme.breakpoints.m}px) and (max-width: 815px) {
    flex: 0 0
      calc(
        45% -
          ${(props) => props.layout === 'renderImages'
    ? `var(${props.theme.responsive.spacing.xxs})`
    : `var(${props.theme.responsive.spacing.m})`}
      );
    scroll-snap-align: start;
    margin-right: 24px;
    width:45%
  }

  @media screen and (min-width: 816px) and (max-width: ${(props) => props.theme.breakpoints.l - 1}px) {
  flex: 0 0
    calc(
      41% +
        ${(props) => props.layout === 'renderImages'
    ? `var(${props.theme.responsive.spacing.xxs})`
    : `var(${props.theme.responsive.spacing.m}) + var(${props.theme.responsive.spacing.m})`}
    );
  scroll-snap-align: start;
  margin-right: 24px;
  width:41%
}

  @media screen and (min-width: ${(props) => props.theme.breakpoints.l}px) {
    width: 31%;
    flex: 0 0
      calc(
        33.33333333% -
          ${(props) => props.layout === 'renderImages'
    ? `var(${props.theme.responsive.spacing.xs})`
    : `var(${props.theme.responsive.spacing.m})`}
      );
    padding-right: ${(props) => props.layout === 'renderImages'
    ? `var(${props.theme.responsive.spacing.xs})`
    : `var(${props.theme.responsive.spacing.l})`};

    &:nth-child(3n) {
      margin-right: 0.00000001%;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  scroll-snap-align: start;
`;
